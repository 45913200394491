import { Chip } from '@material-ui/core';
import { ForumOutlined } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { BaseMeetingProps, BaseSeriesProps, SeriesHistoriesProps, SpinachSeriesProps } from '@spinach-shared/models';
import { ClientEventType, SpinachUpdateTypeYTB, UUID } from '@spinach-shared/types';
import { StoredSpinachSeries, formatSummaryHTML, getAbbreviatedDateElements } from '@spinach-shared/utils';

import { ElementId } from '../../../../../constants';
import { useExperienceTracking, useGlobalAiDashboard, useGlobalAuthedUser } from '../../../../../hooks';
import { BodyRegular, responsiveness, withSelectionPrevention } from '../../../../../styles';
import { ListItemValue, SetValue } from '../../../../../types';
import { ClientLogger, copyTextToClipboard } from '../../../../../utils';
import { Column, Row, Spacing, StandupAppDropDown } from '../../../../common';
import { StandupAppOpenedSummary } from './StandupAppOpenedSummary';
import { StandupAppSendOptions } from './StandupAppSendOptions';
import { StandupAppSummaryFilterDropdown } from './StandupAppSummaryFilterDropdown';
import { StandupAppSummaryFilterView } from './StandupAppSummaryFilterView';

function useResetToLatestSummary(
    openedSummary: number | null,
    seriesHistories: BaseMeetingProps[],
    setOpenedSummary: SetValue<number | null>
) {
    useEffect(() => {
        if (openedSummary === null && seriesHistories.length) {
            setOpenedSummary(0);
        }
    }, [seriesHistories.length]);
}

const ResponsiveRow = styled(Row)`
    @media ${responsiveness.thinnerThanMD} {
        flex-direction: column;

        > * {
            margin-bottom: 1rem;
        }
    }
`;

const ResponsiveColumn = styled(Column)`
    margin-left: 1rem;

    @media ${responsiveness.thinnerThanMD} {
        margin-left: 0;
    }
`;

const SummariesContainer = styled(Column)`
    ${withSelectionPrevention()};
    height: 100%;
`;

export function StandupAppSummaries({
    series,
    seriesHistories,
    openedSummary,
    setOpenedSummary,
}: {
    series: StoredSpinachSeries;
    seriesHistories: BaseMeetingProps[];
    openedSummary: number | null;
    setOpenedSummary: SetValue<number | null>;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const [participantFilter, setParticipantFilter] = useState<UUID[]>([]);
    const [categoryFilter, setCategoryFilter] = useState<SpinachUpdateTypeYTB[]>([]);
    const { setToastText } = useGlobalAiDashboard();
    const hasOpenedSummary = openedSummary !== null;
    const hasFilters = !!(participantFilter.length || categoryFilter.length);

    useResetToLatestSummary(openedSummary, seriesHistories, setOpenedSummary);

    useEffect(() => {
        setOpenedSummary(seriesHistories.length ? openedSummary ?? 0 : null);
    }, []);

    function getPostContent(meeting: BaseMeetingProps): JSX.Element {
        return meeting.isAsyncMeeting ? (
            <Chip
                icon={<ForumOutlined style={{ fontSize: 'medium' }} />}
                label="Async"
                color="secondary"
                variant="outlined"
            />
        ) : (
            <></>
        );
    }
    let dateWithMissingYear: string | undefined = undefined;
    let dropDownValues: ListItemValue<number>[] = seriesHistories
        .map((meeting, i) => {
            // should always evaluate to true
            if (meeting.endedAt || meeting.startedAt) {
                const datetime = (
                    meeting.isAsyncMeeting ? meeting.endedAt ?? meeting.startedAt : meeting.startedAt ?? meeting.endedAt
                )!;
                const { weekday, dayOfMonth, year = '' } = getAbbreviatedDateElements(datetime);

                if (!year && !dateWithMissingYear) {
                    dateWithMissingYear = datetime;
                }

                const listItem: ListItemValue<number> = {
                    code: i + 1,
                    label: `${weekday}, ${dayOfMonth} ${year}`,
                    postContent: getPostContent(meeting),
                };
                return listItem;
            }
        })
        .filter((m) => !!m) as ListItemValue<number>[];

    if (dateWithMissingYear) {
        ClientLogger.error('Meeting date parsing did not yield year', {
            seriesId: series.id,
            userId: user.spinachUserId,
            date: dateWithMissingYear,
        });
    }

    if (participantFilter.length && dropDownValues[0].code !== -1) {
        dropDownValues.unshift({
            code: -1,
            label: 'All',
        });
    }

    if (!participantFilter.length) {
        dropDownValues = dropDownValues.filter(({ code }) => code !== -1);
    }

    const onCopyFilteredSummary = async () => {
        let richSummary = '';

        if (!openedSummary) {
            return;
        }

        if (openedSummary === -1) {
            seriesHistories.forEach((meeting, index) => {
                const filteredMeeting = SeriesHistoriesProps.applyFiltersToMeeting(meeting, participantFilter);

                richSummary += formatSummaryHTML(
                    filteredMeeting,
                    new BaseSeriesProps({ ...series.toJSON(), featureFlags: series.featureToggles }),
                    {
                        withFooter: index === seriesHistories.length - 1,
                    }
                );
            });
        } else {
            const selectedMeeting = seriesHistories[openedSummary];
            const filteredMeeting = SeriesHistoriesProps.applyFiltersToMeeting(selectedMeeting, participantFilter);

            richSummary = formatSummaryHTML(
                filteredMeeting,
                new BaseSeriesProps({ ...series.toJSON(), featureFlags: series.featureToggles })
            );
        }

        copyTextToClipboard(richSummary);

        const participantsInSeries = SeriesHistoriesProps.findAllParticipantsInSeries(seriesHistories);

        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Copy filtered summary',
            SelectedParticipants: participantFilter.map((id) => participantsInSeries[id].displayName).join(', '),
            SelectedDate: openedSummary === -1 ? 'All' : dropDownValues[openedSummary + 1].label,
        });
        setToastText('Summary copied to clipboard');
    };

    const onCopySummary = async (meeting: BaseMeetingProps) => {
        if (typeof openedSummary !== 'number') {
            return;
        }

        const richSummary = formatSummaryHTML(
            meeting,
            new BaseSeriesProps({ ...series.toJSON(), featureFlags: series.featureToggles })
        );
        copyTextToClipboard(richSummary);

        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Copy summary',
            SelectedDate: openedSummary === -1 ? 'All' : dropDownValues[openedSummary + 1].label,
        });

        setToastText('Summary copied to clipboard');
    };

    const getSelectedDropdownLabel = () => {
        if (!openedSummary || openedSummary === -1) {
            return dropDownValues[0].label;
        }

        if (hasFilters) {
            return dropDownValues[openedSummary + 1]?.label;
        }

        return dropDownValues[openedSummary]?.label;
    };

    return (
        <SummariesContainer>
            <ResponsiveRow>
                <ResponsiveColumn>
                    {dropDownValues[0] ? (
                        <StandupAppDropDown
                            title={dropDownValues[0].label}
                            values={dropDownValues}
                            handleSelection={(code: string | number) => {
                                const dropDownValueWithCode = dropDownValues.findIndex((value) => value.code === code);
                                setOpenedSummary(code === -1 ? -1 : dropDownValueWithCode);
                                track(ClientEventType.AIDashboardClick, {
                                    SelectedDate: dropDownValues[dropDownValueWithCode]?.label,
                                    ClickedOn: 'Summary Selection Dropdown',
                                });
                            }}
                            defaultValue={dropDownValues[0].label}
                            selected={getSelectedDropdownLabel()}
                        />
                    ) : (
                        <BodyRegular>
                            No summaries yet! Check back after you've finished your first{' '}
                            {user.meetingWord.toLowerCase()}.
                        </BodyRegular>
                    )}
                </ResponsiveColumn>

                <ResponsiveColumn>
                    <StandupAppSummaryFilterDropdown
                        setParticipantFilter={setParticipantFilter}
                        participantFilter={participantFilter}
                        setCategoryFilter={setCategoryFilter}
                        categoryFilter={categoryFilter}
                        setOpenedSummary={setOpenedSummary}
                        selectedDate={getSelectedDropdownLabel()}
                        user={user}
                        histories={seriesHistories}
                    />
                </ResponsiveColumn>

                {openedSummary !== null && !hasFilters ? (
                    <ResponsiveColumn>
                        <StandupAppSendOptions
                            parentId={ElementId.StandupAppSummaryOptions}
                            style={{ width: 'fit-content', borderRadius: '4px' }}
                            onCopyClick={() => onCopySummary(seriesHistories[openedSummary])}
                        />
                    </ResponsiveColumn>
                ) : null}
            </ResponsiveRow>
            {hasOpenedSummary && hasFilters && (
                <StandupAppSummaryFilterView
                    spinachSeries={series}
                    participantFilter={participantFilter}
                    categoryFilter={categoryFilter}
                    openedSummary={openedSummary}
                    onCopyClick={onCopyFilteredSummary}
                    summaries={seriesHistories}
                />
            )}
            <Spacing factor={1 / 2} />

            {hasOpenedSummary && !hasFilters && (
                <StandupAppOpenedSummary
                    series={
                        new SpinachSeriesProps(
                            {
                                ...series.toJSON(),
                                featureFlags: series.featureToggles,
                                currentMeeting: {
                                    ...seriesHistories[openedSummary].toJSON(),
                                    /** @NOTE added to satisfy TS checks, not used in summary */
                                    participantsOnline: 0,
                                    participantsReady: 0,
                                },
                            },
                            user.spinachUserId
                        )
                    }
                    currentMeeting={seriesHistories[openedSummary]}
                />
            )}
        </SummariesContainer>
    );
}
