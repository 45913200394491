import { Box, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import styled from 'styled-components';

import { DashboardSection, INTERCOM_SUPPORT_URL } from '@spinach-shared/constants';
import { ClientEventType, FeatureDiscoveryKey, WebUrlQuery } from '@spinach-shared/types';
import { getUniques } from '@spinach-shared/utils';

import { patchUser } from '../../../../apis';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalRouting } from '../../../../hooks';
import { ButtonSize, HeaderThree, lightTheme, responsiveness } from '../../../../styles';
import { URLUtil } from '../../../../utils';
import { Anchor, PromoContainer, Row, Spacing, VideoToolTip } from '../../../common';
import { PrimaryButton } from '../../../stand-up';
import './MeetingSection.css';

export const StepItem = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;

    @media ${responsiveness.thinnerThanMD} {
        flex-direction: column;
    }
`;

export const StepTitle = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 16px;

    @media ${responsiveness.thinnerThanMD} {
    }
`;

export const StepAction = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;

    @media ${responsiveness.thinnerThanMD} {
        margin-top: 5px;
        justify-content: flex-start;
    }
`;

export const CustomTooltip = styled(Tooltip)(() => ({
    [`& .MuiTooltip-tooltip`]: {
        maxWidth: '400px',
        backgroundColor: 'black',
    },
    [`& .MuiTooltip-arrow::before`]: {
        color: 'black',
        backgroundColor: 'black',
    },
}));

export function OnboardingSetupPromo(): JSX.Element {
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();
    const [pendingDismiss, setPendingDismiss] = React.useState(false);
    const [isDismissedLocal, setDissmissedLocal] = React.useState(false);
    const isDismissed = user.dismissedHints.includes(FeatureDiscoveryKey.OnboardingStepPromo) || isDismissedLocal;

    const video1 = React.useRef<VideoTooltipButtonHandle>(null);
    const video2 = React.useRef<VideoTooltipButtonHandle>(null);
    const video3 = React.useRef<VideoTooltipButtonHandle>(null);
    const video4 = React.useRef<VideoTooltipButtonHandle>(null);

    const videos = [video1, video2, video3, video4];
    const onOpen = (index: number) => () => {
        videos.forEach((video, i) => {
            if (index !== i) {
                video.current?.close();
            }
        });
    };

    if (isDismissed) {
        return <></>;
    }

    return (
        <Box marginRight="20px" maxWidth="900px" marginTop="10px" position="relative">
            <Spacing factor={1} />

            <PromoContainer>
                <Row>
                    <HeaderThree style={{ marginRight: '130px' }}>Welcome to Spinach! Tips to get started:</HeaderThree>
                </Row>
                <Box display="flex" flexDirection="column" paddingRight="100px">
                    {user.isAuthedForAnyCalendar ? (
                        <VideoTooltipButton
                            handleRef={video1}
                            videoUrl={'https://media.spinach.io/howtos/Howto-Invite-Spinach.m4v'}
                            actionName="Add Spinach to a meeting"
                            onOpen={onOpen(0)}
                        />
                    ) : (
                        <VideoTooltipButton
                            handleRef={video1}
                            videoUrl={'https://media.spinach.io/howtos/Howto-Email-Setup.m4v'}
                            actionName="Calander Integration"
                            onOpen={onOpen(0)}
                        />
                    )}
                    <VideoTooltipButton
                        handleRef={video2}
                        videoUrl={'https://media.spinach.io/howtos/Howto-Agenda.m4v'}
                        actionName="Use an Agenda"
                        onOpen={onOpen(1)}
                    />
                    <VideoTooltipButton
                        handleRef={video3}
                        videoUrl={'https://media.spinach.io/howtos/Howto-Slack-settings.m4v'}
                        actionName="Connect meeting summaries to Slack"
                        onOpen={onOpen(2)}
                    />
                    <VideoTooltipButton
                        handleRef={video4}
                        videoUrl={'https://media.spinach.io/howtos/Howto-Jira.m4v'}
                        actionName="Connect meeting summaries to Jira"
                        onOpen={onOpen(3)}
                    />
                </Box>
            </PromoContainer>
            <Box
                position="absolute"
                right="6px"
                top="6px"
                width="25px"
                height="25px"
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: `Onboarding Banner X clicked`,
                    });
                    setPendingDismiss(true);
                    videos.forEach((video) => {
                        video.current?.close();
                    });
                }}
                style={{
                    backgroundColor: lightTheme.primary.orangeLight,
                    borderRadius: '50%',
                    display: 'flex', // Add this
                    alignItems: 'center', // Add this
                    justifyContent: 'center', // Add this
                    zIndex: 2,
                    cursor: 'pointer',
                }}
            >
                <CloseIcon fill="white" style={{ color: lightTheme.neutrals.white, width: '15px' }} />
            </Box>
            {pendingDismiss ? (
                <Box
                    padding="20px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    zIndex={3}
                    position="absolute"
                    borderRadius="6px"
                    style={{
                        background: lightTheme.contentOverlay.background,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        top: 0,
                        color: lightTheme.contentOverlay.textColor,
                    }}
                >
                    <Box>
                        {'Help articles are available any time in the '}
                        <Anchor
                            style={{ color: lightTheme.secondary.greenLight, textDecoration: 'none' }}
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, { ClickedOn: 'Help Center Link' });
                                URLUtil.openURL(INTERCOM_SUPPORT_URL);
                            }}
                        >
                            {'Help Center'}
                        </Anchor>
                    </Box>
                    <PrimaryButton
                        title="Dismiss"
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Onboarding Banner Dismissed`,
                            });
                            setDissmissedLocal(true);
                            patchUser({
                                metadata: {
                                    dismissedHints: getUniques([
                                        ...user.dismissedHints,
                                        FeatureDiscoveryKey.OnboardingStepPromo,
                                    ]),
                                },
                            });
                        }}
                        style={{ minWidth: '85px', display: 'flex', justifyContent: 'center', marginTop: '20px' }}
                        size={ButtonSize.Mini}
                        labelStyles={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    />
                    <Box
                        style={{
                            marginTop: '10px',
                            cursor: 'pointer',
                            color: lightTheme.secondary.greenLight,
                        }}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Onboarding Banner Dismissed Cancelled`,
                            });
                            setPendingDismiss(false);
                        }}
                    >
                        {'Cancel'}
                    </Box>
                </Box>
            ) : (
                <></>
            )}
        </Box>
    );
}

export type VideoTooltipButtonHandle = {
    close: () => void;
};

type VideoTooltipButtonProps = {
    videoUrl: string;
    actionName: string;
    onOpen: () => void;
    handleRef: React.Ref<VideoTooltipButtonHandle>;
};

const VideoTooltipButton = ({ videoUrl, actionName, onOpen, handleRef }: VideoTooltipButtonProps) => {
    const track = useExperienceTracking();
    const [videoShown, setVideoShown] = React.useState(false);
    const [didReportStart, setDidReportStart] = React.useState(false);
    const [didReportTenSec, setDidReportTenSec] = React.useState(false);
    const [didReportFinish, setDidReportFinish] = React.useState(false);

    React.useImperativeHandle(
        handleRef,
        (): VideoTooltipButtonHandle => {
            return {
                close() {
                    setVideoShown(false);
                },
            };
        },
        []
    );

    return (
        <StepItem>
            <StepTitle>{actionName}</StepTitle>
            <StepAction>
                <VideoToolTip
                    arrow
                    open={videoShown}
                    interactive
                    title={
                        <Box p="5px" minHeight="120px" style={{ width: 'min(400px, 70vw)' }}>
                            <video
                                onTimeUpdate={(e) => {
                                    if (!didReportStart && e.currentTarget.currentTime > 1) {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: `Tooltip video started playing ${actionName}`,
                                        });
                                        setDidReportStart(true);
                                    }
                                    if (!didReportTenSec && e.currentTarget.currentTime > 10) {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: `Tooltip video 10 seconds ${actionName}`,
                                        });
                                        setDidReportTenSec(true);
                                    }
                                }}
                                onEnded={() => {
                                    if (!didReportFinish) {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: `Tooltip video ended ${actionName}`,
                                        });
                                        setDidReportFinish(true);
                                    }
                                    setVideoShown(false);
                                }}
                                autoPlay
                                controlsList="nodownload"
                                controls
                                onContextMenu={(e) => e.preventDefault()}
                                style={{ width: '100%', height: 'auto' }}
                                src={videoUrl}
                            />
                        </Box>
                    }
                    placement="top"
                    style={{
                        flexDirection: 'column',
                        maxWidth: '400px',
                    }}
                >
                    <PrimaryButton
                        title={videoShown ? 'Close Demo' : 'See How'}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Onboarding Banner Launch Demo ${actionName}`,
                            });
                            setVideoShown((prev) => {
                                if (!prev && onOpen) {
                                    onOpen();
                                }
                                return !prev;
                            });
                        }}
                        style={{ minWidth: '85px', display: 'flex', justifyContent: 'center' }}
                        size={ButtonSize.Mini}
                        labelStyles={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    />
                </VideoToolTip>
            </StepAction>
        </StepItem>
    );
};

export function AiNoteTakingPromo(): JSX.Element {
    const track = useExperienceTracking();
    const { routeToAIDashboard } = useGlobalRouting();

    return (
        <Box marginRight="20px" maxWidth="900px" marginTop="10px">
            <Spacing factor={1} />
            <PromoContainer>
                <Row>
                    <HeaderThree>Discover Spinach AI</HeaderThree>
                </Row>
                <Box marginTop="10px" marginBottom="20px">
                    Drive better outcomes with instant summaries, action items, and key insights captured from every
                    conversation. You stay in control with our privacy-first approach - your data remains secure and
                    private.
                </Box>
                <PrimaryButton
                    title="Try Spinach AI"
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'AI Meeting Assistant Promo CTA',
                        });
                        routeToAIDashboard(
                            undefined,
                            new URLSearchParams({
                                [WebUrlQuery.Section]: DashboardSection.Summaries,
                            })
                        );
                    }}
                    style={{ maxWidth: '200px' }}
                    size={ButtonSize.Normal}
                />
            </PromoContainer>
        </Box>
    );
}
