import { Paper } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { ClientEventType } from '@spinach-shared/types';
import { isDemoSeries, isProductionStage } from '@spinach-shared/utils';

import { GlobalModal } from '../../../../atoms';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalModal, useWindowSize } from '../../../../hooks';
import { useGlobalAiMeetingSelection } from '../../../../hooks/useGlobalAiMeetingSelection';
import { useGlobalStoredSeriesList } from '../../../../hooks/useGlobalStoredSeriesList';
import { BodyBigOnboard, HeaderThreeOnboard, lightTheme } from '../../../../styles';
import { Row, Spacing } from '../../../common';
import { ScrollArea } from '../../../stand-up';
import { AiNoteTakingPromo } from '../meetings';
import { StandupDetails } from './StandupDetails';

function CreateStandupRow(): JSX.Element {
    const { isDesktopView } = useWindowSize();
    const track = useExperienceTracking();
    const [, setGlobalModal] = useGlobalModal();
    const [user] = useGlobalAuthedUser();
    return (
        <Row
            style={{
                marginBottom: '20px',
                marginLeft: '10px',
            }}
        >
            <Paper
                elevation={4}
                style={{ cursor: 'pointer', width: isDesktopView ? '80%' : '95%', display: 'flex' }}
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Create Standup',
                    });
                    setGlobalModal(GlobalModal.CreateSeries);
                }}
            >
                <Row style={{ padding: '20px' }} vCenter>
                    <Add htmlColor={lightTheme.primary.greenLight} />
                    <BodyBigOnboard
                        style={{ marginRight: '15px', marginLeft: '15px', color: lightTheme.primary.greenLight }}
                    >
                        <b>Create new {user.meetingWord}</b>
                    </BodyBigOnboard>
                </Row>
            </Paper>
        </Row>
    );
}

export function StandupSection(): JSX.Element {
    const track = useExperienceTracking();
    const { isDesktopView } = useWindowSize();
    const [user] = useGlobalAuthedUser();
    const {
        openedMeetingsState: { openMeetingDetails },
        openMeetingBySeriesId,
        closeMeetingBySeriesId,
    } = useGlobalAiMeetingSelection();

    const {
        storedSeriesListState: { storedSeriesList },
    } = useGlobalStoredSeriesList();

    return (
        <>
            <Spacing factor={isDesktopView ? 1 / 2 : 1 / 2} />
            <Row vCenter>
                <HeaderThreeOnboard style={{ textAlign: 'left' }}>Your {user.meetingWord}s</HeaderThreeOnboard>
            </Row>

            <Spacing />
            <ScrollArea sidePadding={0} style={{ background: 'transparent' }}>
                {user.seriesMetadataList
                    .filter((series) => (!isProductionStage() ? true : !isDemoSeries(series.id)))
                    .filter((series) => !!storedSeriesList.find((s) => s.id === series.id))
                    .filter((series) => !storedSeriesList.find((s) => s.id === series.id)?.scribeMetadata)
                    .map((series) => {
                        const storedSeriesForCard = storedSeriesList.find((s) => s.id === series.id);

                        if (!storedSeriesForCard) {
                            return null;
                        }

                        return (
                            <StandupDetails
                                key={`${series.id}`}
                                isOpen={openMeetingDetails.includes(series.id)}
                                setIsOpen={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Series Row',
                                    });
                                    if (openMeetingDetails.includes(series.id)) {
                                        closeMeetingBySeriesId(series.id);
                                    } else {
                                        openMeetingBySeriesId(series.id);
                                    }
                                }}
                                storedSeries={storedSeriesForCard}
                            />
                        );
                    })}

                <CreateStandupRow />

                {user.isHypercontextLegacyUser && !user.isHidingAiDashboard ? <AiNoteTakingPromo /> : null}
                <Spacing />
            </ScrollArea>
        </>
    );
}
