import { ReactNode } from 'react';

import { ClientEventType, MeetingSettingsSubview, WebUrlQuery } from '@spinach-shared/types';

import { ReactComponent as JiraLogo } from '../../../assets/jira-logo.svg';
import {
    useExperienceTracking,
    useFreeTierLimitations,
    useGlobalAuthedUser,
    useIntegrationDetection,
} from '../../../hooks';
import { useGlobalMeetingSettings } from '../../../hooks/useGlobalMeetingSettings';
import { URLUtil } from '../../../utils';
import { getClientConfigValue } from '../../../utils/getClientConfigValue';
import { FreeTierLimitationMessage, LimitationIntent } from '../FreeTierMessages';
import { AppIntegrationRow, IntegrationButtonText } from './AppIntegrationRow';
import { UseIntegrationRow } from './common';

function useJiraIntegrationRow(message: NonNullable<ReactNode>, isAi: boolean): UseIntegrationRow {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const { setSubview } = useGlobalMeetingSettings();
    const isFreeTierLimited = useFreeTierLimitations();

    const startDetection = useIntegrationDetection();

    const isConnectFlow = !user.isAuthedForJira;

    async function onClick() {
        if (isFreeTierLimited) {
            return;
        }

        if (isConnectFlow) {
            track(ClientEventType.UserAuthorizeJiraClick);
            startDetection();
            URLUtil.openURL(
                `${getClientConfigValue('REACT_APP_AUTH_URL')}/jira/auth-url/?${WebUrlQuery.Suid}=${
                    user.spinachUserId
                }&ai=${isAi}`,
                { redirectThroughBackendWhenOnZoom: true }
            );
        } else {
            setSubview(MeetingSettingsSubview.JiraSettings);
            track(ClientEventType.OpenJiraSettingsSubviewClick);
        }
    }

    let subtext: ReactNode = message;
    if (isFreeTierLimited) {
        subtext = <FreeTierLimitationMessage intent={LimitationIntent.Jira} />;
    }

    return {
        subtext,
        onClick,
        isConnected: user.isAuthedForJira,
        buttonText: user.isAuthedForJira ? IntegrationButtonText.Configure : IntegrationButtonText.Connect,
        isButtonDisabled: isFreeTierLimited,
    };
}

export function JiraIntegrationRow({
    message = 'Add Jira tickets to your Check-In',
    isAi = false,
    isConnectViewOnly,
}: {
    message?: ReactNode;
    isAi?: boolean;
    isConnectViewOnly?: boolean;
}): JSX.Element {
    const { onClick, subtext, isConnected, buttonText, isButtonDisabled } = useJiraIntegrationRow(message!, isAi!);
    return (
        <AppIntegrationRow
            onCTAClick={onClick}
            subtext={subtext}
            label={'Jira'}
            icon={<JiraLogo style={{ width: '24px', height: '24px' }} />}
            isConnected={isConnected}
            buttonText={buttonText}
            isButtonDisabled={isButtonDisabled}
            isConnectViewOnly={isConnectViewOnly}
        />
    );
}
