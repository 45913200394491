import { useEffect } from 'react';

import { isWebPlatform } from '../utils';
import { getClientConfigValue } from '../utils/getClientConfigValue';
import { useCelloJWT } from './useCelloJWT';
import { useGlobalAuthedUser } from './useGlobalUser';

export function useCelloInitalization(): void {
    const [user] = useGlobalAuthedUser();
    const [celloJWT] = useCelloJWT();
    useEffect(() => {
        window.cello = window.cello || { cmd: [] };

        if (user?.isCelloReferralProgramEnabled && celloJWT && isWebPlatform()) {
            window.cello.cmd.push(function (cello: Cello) {
                cello.boot({
                    productId: getClientConfigValue('REACT_APP_CELLO_PRODUCT_ID'),
                    token: celloJWT,
                    language: 'en',
                    productUserDetails: {
                        firstName: user.firstName,
                        lastName: user.lastName,
                        fullName: `${user.firstName} ${user.lastName}`,
                        email: user.email,
                    },
                    onTokenExpiring: () => {
                        //handle token refresh
                    },
                });
            });
        }
    }, [user, celloJWT]);
}
