import { endOfMonth, getDay, getWeekOfMonth, getWeeksInMonth, startOfMonth } from 'date-fns';
import moment from 'moment-timezone';
import { z } from 'zod';

import { MILLIS_IN_DAY } from '@spinach-shared/constants';
import { Day, HHMMAMPMTimeString, HHMMTimeString, ISOString, ORDERED_DAYS } from '@spinach-shared/types';

export const formatTime = (durationInSeconds: number | null): string => {
    if (durationInSeconds === 0) {
        return '00:00';
    }
    if (!durationInSeconds) {
        return '';
    }

    const minutes = Math.floor(durationInSeconds / 60);
    const minutesText = minutes > 0 ? (minutes < 10 ? `0${minutes}:` : `${minutes}:`) : '00:';

    const seconds = Math.floor(durationInSeconds % 60);
    const paddedSeconds = seconds.toString().length === 1 ? `0${seconds}` : seconds;
    const secondsText = seconds ? `${paddedSeconds}` : '00';

    return `${minutesText}${secondsText}`;
};

export const isDatePriorToNow = (dateToCompare: Date): boolean =>
    new Date(new Date().setHours(dateToCompare.getHours(), dateToCompare.getMinutes(), 0, 0)) <= new Date();

export const isDateTomorrow = (dateToCompare: Date): boolean => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    return tomorrow.toDateString() === dateToCompare.toDateString();
};

export const getDateTimeHoursAndMinutesOnly = (dateTime: Date) =>
    new Date(new Date(dateTime).setHours(dateTime.getHours(), dateTime.getMinutes(), 0, 0));

export const getDateTimeTomorrowHoursAndMinutesOnly = (dateTime: Date) =>
    new Date(new Date(new Date().getTime() + MILLIS_IN_DAY).setHours(dateTime.getHours(), dateTime.getMinutes(), 0, 0));

export const getDateTimeTodayHoursAndMinutesOnly = (dateTime: Date) =>
    new Date(new Date().setHours(dateTime.getHours(), dateTime.getMinutes(), 0, 0));

// Only using time values, cleans the date aspect value
export const setDateToTomorrowIfPriorToNow = (dateToCompare: Date): Date => {
    const date = getDateTimeTodayHoursAndMinutesOnly(dateToCompare);
    if (isDatePriorToNow(date)) {
        const now = new Date();
        const epochTomorrow = now.getTime() + MILLIS_IN_DAY;
        return new Date(new Date(epochTomorrow).setHours(date.getHours(), date.getMinutes(), 0, 0));
    }
    return getDateTimeHoursAndMinutesOnly(dateToCompare);
};

// If an asyncEndsAt time exists, validate and clean it, setting it to tomorrow if it's prior to now, using the cleaned date otherwise
export const generateDefaultAsyncEndsAtDate = (dateString?: string) =>
    dateString
        ? setDateToTomorrowIfPriorToNow(new Date(dateString))
        : setDateToTomorrowIfPriorToNow(new Date(new Date().setHours(9, 0, 0, 0)));

const MONTHS = {
    JAN: 1,
    FEB: 2,
    MAR: 3,
    APR: 4,
    MAY: 5,
    JUN: 6,
    JUL: 7,
    AUG: 8,
    SEP: 9,
    OCT: 10,
    NOV: 11,
    DEC: 12,
} as const;

const WEEKDAYS = {
    SUN: 0,
    MON: 1,
    TUE: 2,
    WED: 3,
    THU: 4,
    FRI: 5,
    SAT: 6,
} as const;

type MonthName = keyof typeof MONTHS;
type WeekdayName = keyof typeof WEEKDAYS;

type DateSpecifier =
    | { type: 'exact'; month: MonthName; day: number }
    | { type: 'weekday'; month: MonthName; week: number; weekday: WeekdayName }
    | { type: 'lastWeekday'; month: MonthName; weekday: WeekdayName };

export const defaultHolidays: DateRange[] = [
    {
        from: {
            type: 'weekday',
            month: 'NOV',
            week: 3,
            weekday: 'SUN',
        },
        to: {
            type: 'weekday',
            month: 'NOV',
            week: 4,
            weekday: 'SAT',
        },
        description: 'Thanksgiving Week',
    },
    {
        from: {
            type: 'exact',
            month: 'DEC',
            day: 15,
        },
        to: {
            type: 'exact',
            month: 'JAN',
            day: 7,
        },
        description: 'Winter Break',
    },
];

export interface DateRange {
    from: DateSpecifier;
    to: DateSpecifier;
    description?: string;
}

export const dateSpecifierSchema = z.discriminatedUnion('type', [
    z.object({
        type: z.literal('exact'),
        month: z.enum(['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']),
        day: z.number().min(1).max(31),
    }),
    z.object({
        type: z.literal('weekday'),
        month: z.enum(['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']),
        week: z.number().min(1).max(5),
        weekday: z.enum(['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']),
    }),
    z.object({
        type: z.literal('lastWeekday'),
        month: z.enum(['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']),
        weekday: z.enum(['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']),
    }),
]);

export const dateRangeSchema = z.object({
    from: dateSpecifierSchema,
    to: dateSpecifierSchema,
    description: z.string().optional(),
});

export function getDateFromSpecifier(specifier: DateSpecifier, year: number): Date {
    switch (specifier.type) {
        case 'exact':
            return new Date(year, MONTHS[specifier.month] - 1, specifier.day);

        case 'weekday': {
            const monthStart = new Date(year, MONTHS[specifier.month] - 1, 1);
            let currentDate = monthStart;
            let currentWeek = 1;

            while (currentDate.getMonth() === monthStart.getMonth()) {
                if (getDay(currentDate) === WEEKDAYS[specifier.weekday]) {
                    if (currentWeek === specifier.week) {
                        return currentDate;
                    }
                    currentWeek++;
                }
                currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
            }
            return currentDate; // Return last possible date if week number is too high
        }

        case 'lastWeekday': {
            const monthEnd = endOfMonth(new Date(year, MONTHS[specifier.month] - 1));
            let currentDate = monthEnd;

            while (currentDate.getMonth() === monthEnd.getMonth()) {
                if (getDay(currentDate) === WEEKDAYS[specifier.weekday]) {
                    return currentDate;
                }
                currentDate = new Date(currentDate.setDate(currentDate.getDate() - 1));
            }
            return monthEnd; // Fallback
        }
    }
}

export const isDateInRange = (date: Date, range: DateRange): boolean => {
    const startDate = getDateFromSpecifier(range.from, date.getFullYear());
    const endDate = getDateFromSpecifier(range.to, date.getFullYear());
    return date >= startDate && date <= endDate;
};

export class TimeUtils {
    // I believe this will only work properly on the front-end
    static getTimezoneRegion(locales?: string | string[], dateTimeOptions: Intl.DateTimeFormatOptions = {}): string {
        return Intl.DateTimeFormat(locales, dateTimeOptions).resolvedOptions().timeZone;
    }

    static getTimeZone(locales?: string | string[], dateTimeOptions: Intl.DateTimeFormatOptions = {}): string {
        const localDateString = new Date().toLocaleDateString(locales, dateTimeOptions);
        return localDateString.slice(localDateString.length - 3);
    }

    static getFormattedDateTimeFromDayOffset(dayOffset: number, dateFormat: string): string {
        return moment(new Date()).tz(TimeUtils.getTimezoneRegion()).add(dayOffset, 'days').format(dateFormat);
    }

    static getFormattedDateTimeFromDate(date: Date | ISOString, dateFormat: string): string {
        return moment(date).tz(TimeUtils.getTimezoneRegion()).format(dateFormat);
    }

    static getHoursMinutesFromHHMM(hhmm: HHMMTimeString): { hours: string; minutes: string } {
        const [hours, minutes] = hhmm.split(':');
        return { hours, minutes };
    }

    static getDaysSinceDate(sinceDate: Date): number {
        return moment(new Date()).diff(moment(sinceDate), 'days') + 1;
    }

    static getMinutesFromHHMM(hhmm: HHMMTimeString): number {
        const timeHoursMinutes = this.getHoursMinutesFromHHMM(hhmm);
        const numerOfMinutes = Number(timeHoursMinutes.hours) * 60 + Number(timeHoursMinutes.minutes);
        return numerOfMinutes;
    }

    static isStartTimeInvalid(startTime: HHMMTimeString, endTime: HHMMTimeString): boolean {
        const startTimeInMinutes = this.getMinutesFromHHMM(startTime);
        const endTimeInMinutes = this.getMinutesFromHHMM(endTime);

        return startTimeInMinutes >= endTimeInMinutes;
    }

    static getHHMMFromHoursMinutes(hours: string, minutes: string): HHMMTimeString {
        return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    }

    static getHHMMFromDate(date: Date): HHMMTimeString {
        const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
        const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
        return `${hours}:${minutes}`;
    }

    static getNextDateFromHHMMDayTimezone(hhmm: HHMMTimeString, day: Day, timezone: string): Date {
        const date = this.getDateFromHHMMDayTimezone(hhmm, day, timezone);
        // Numeric day should be a value from 0 - 6.
        // 0 indicating Sunday, 6 indicating Saturday
        const numericDay = ORDERED_DAYS.findIndex((dayVal) => dayVal === day);

        // if today is the same day as the numeric date, and the current time is
        // less than we dateTime, we use that date time
        if (numericDay === date.getDay() && moment().tz(timezone).isBefore(date)) {
            return date;
        }

        // if the numeric day is less than the date's day (i.e. Monday vs Wednesday)
        // get the difference between the days compared to the week (i.e. Wednesday, Thursday, Friday, ... Modaay === 5)
        // multiply by MILLIS and add to date
        // Otherwise, if numeric day is greater than the date's day (i.e. Wednesday vs Monday)
        // get the difference between the days compared to one another (Monday, Tuesday, Wednesday === 2)
        // multiply by MILLIS and add to date
        const momentTimeFromEpoch = moment()
            .tz(timezone)
            .hours(date.getHours())
            .minutes(date.getMinutes())
            .seconds(0)
            .milliseconds(0)
            .day(moment().tz(timezone).day() < numericDay ? numericDay : numericDay + 7)
            .toISOString();
        return new Date(momentTimeFromEpoch);
    }

    static formatDateToHHMMAMPM(date: Date | ISOString): HHMMAMPMTimeString {
        return moment(date).format('hh:mm a') as HHMMAMPMTimeString;
    }

    static formatHHMMToAMPM(hhmm: HHMMTimeString): HHMMAMPMTimeString {
        const { hours, minutes } = TimeUtils.getHoursMinutesFromHHMM(hhmm);
        const numericHours = Number(hours);
        const numericMinutes = Number(minutes);

        const ampm = numericHours >= 12 ? 'pm' : 'am';
        // the hour '0' should be '12'
        const formattedHours = numericHours % 12 === 0 ? 12 : numericHours % 12;
        const formattedMinutes = numericMinutes < 10 ? '0' + numericMinutes : numericMinutes;
        return (formattedHours + ':' + formattedMinutes + ' ' + ampm) as HHMMAMPMTimeString;
    }

    static getDateFromHHMMAMPM(hhmm: HHMMAMPMTimeString): Date {
        return TimeUtils.getDateFromHHMM(hhmm.split(' ')[0] as HHMMTimeString);
    }

    static getDateFromHHMMDayTimezone(hhmm: HHMMTimeString, day: Day, timezone: string): Date {
        const { hours, minutes } = this.getHoursMinutesFromHHMM(hhmm);

        const [numericalHours, numericalMinutes] = [hours, minutes].map((element) => {
            if (element.charAt(0) === '0') {
                return Number(element.charAt(1));
            } else {
                return Number(element);
            }
        });

        return new Date(
            moment()
                .tz(timezone)
                .hours(numericalHours)
                .minutes(numericalMinutes)
                .seconds(0)
                .milliseconds(0)
                .day(day)
                .toISOString()
        );
    }

    static getDateFromHHMM(hhmm: HHMMTimeString): Date {
        const timeAsDate = new Date();
        const { hours, minutes } = this.getHoursMinutesFromHHMM(hhmm);

        const [numericalHours, numericalMinutes] = [hours, minutes].map((element) => {
            if (element.charAt(0) === '0') {
                return Number(element.charAt(1));
            } else {
                return Number(element);
            }
        });

        timeAsDate.setHours(numericalHours, numericalMinutes, 0, 0);
        return timeAsDate;
    }

    static isDateToday(date: Date): boolean {
        return date.getDay() === new Date().getDay();
    }

    static getRelativeTimeString(date: Date): string {
        const now = moment();
        const momentDate = moment(date);
        const diffSeconds = now.diff(momentDate, 'seconds');

        if (diffSeconds < 30) {
            return 'just now';
        }
        if (diffSeconds < 60) {
            return `${diffSeconds} seconds ago`;
        }

        const diffMinutes = now.diff(momentDate, 'minutes');
        if (diffMinutes < 60) {
            return `${diffMinutes} ${diffMinutes === 1 ? 'minute' : 'minutes'} ago`;
        }

        const diffHours = now.diff(momentDate, 'hours');
        if (diffHours < 24) {
            return `${diffHours} ${diffHours === 1 ? 'hour' : 'hours'} ago`;
        }

        const diffDays = now.diff(momentDate, 'days');
        if (diffDays === 1) {
            return 'yesterday';
        }
        if (diffDays < 7) {
            return `${diffDays} days ago`;
        }
        if (diffDays < 14) {
            return 'last week';
        }

        return momentDate.format('ll');
    }
}

export function getTrialDaysLeft(trialStart: Date | undefined, trialLengthInDays: number): number {
    let daysLeft = 0;

    if (!!trialStart && !!trialLengthInDays) {
        const trialEnd = moment(trialStart).add(trialLengthInDays, 'days').valueOf();
        const now = moment().valueOf();
        const timeLeftInMs = trialEnd - now;

        // show at least 1 day left
        if (timeLeftInMs > 0) {
            daysLeft = Math.ceil(timeLeftInMs / MILLIS_IN_DAY);
            // ensure ceil does not exceed the actual trial length
            if (daysLeft > trialLengthInDays) {
                daysLeft = trialLengthInDays;
            }
        } else {
            return 0;
        }
    }

    return daysLeft;
}
