import { ClientPlatform } from '@spinach-shared/types';

function getClientPlatformString() {
    let clientPlatformFromProcessEnv = process.env.REACT_APP_PLATFORM;
    if (!clientPlatformFromProcessEnv) {
        try {
            // This is where the desktop client sets the stage
            // @ts-ignore
            clientPlatformFromProcessEnv = window?.processEnv?.REACT_APP_PLATFORM;
        } catch (e) {}
    }
    return clientPlatformFromProcessEnv;
}

export function isWebPlatform(): boolean {
    return getClientPlatformString() === ClientPlatform.Web;
}

export function isChromeExtensionPlatform(): boolean {
    return getClientPlatformString() === ClientPlatform.Chrome;
}

export function isZoomPlatform(): boolean {
    return getClientPlatformString() === ClientPlatform.Zoom;
}

export function isDesktopPlatform(): boolean {
    return getClientPlatformString() === ClientPlatform.Desktop;
}

export function getClientPlatform(): ClientPlatform {
    return getClientPlatformString() as ClientPlatform;
}
