import { ClientEventType, MeetingSettingsSubview, SpinachAPIPath, WebUrlQuery } from '@spinach-shared/types';

import { ReactComponent as ClickUpLogo } from '../../../assets/clickup-icon.svg';
import { useExperienceTracking, useGlobalAuthedUser, useIntegrationDetection } from '../../../hooks';
import { useGlobalMeetingSettings } from '../../../hooks/useGlobalMeetingSettings';
import { URLUtil } from '../../../utils';
import { getClientConfigValue } from '../../../utils/getClientConfigValue';
import { AppIntegrationRow, IntegrationButtonText } from './AppIntegrationRow';
import { IntegrationRowProps, UseIntegrationRow } from './common';

function useClickUpIntegrationRow(): UseIntegrationRow {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const { setSubview } = useGlobalMeetingSettings();

    const startDetection = useIntegrationDetection();

    const isConnectFlow = !user.isAuthedForClickUp;

    async function onClick() {
        if (isConnectFlow) {
            track(ClientEventType.UserAuthorizeClickUpClick);
            startDetection();
            URLUtil.openURL(
                `${getClientConfigValue('REACT_APP_AUTH_URL')}${SpinachAPIPath.ClickUpAuth}/?${WebUrlQuery.Suid}=${
                    user.spinachUserId
                }`,
                { redirectThroughBackendWhenOnZoom: true }
            );
        } else {
            setSubview(MeetingSettingsSubview.ClickUpSettings);
            track(ClientEventType.OpenClickUpSettingsSubviewClick);
        }
    }

    return {
        subtext: 'Link ClickUp tasks in your summaries.',
        onClick,
        isConnected: user.isAuthedForClickUp,
        buttonText: user.isAuthedForClickUp ? IntegrationButtonText.Configure : IntegrationButtonText.Connect,
        isButtonDisabled: false,
    };
}

export function ClickUpIntegrationRow({ isConnectViewOnly }: IntegrationRowProps): JSX.Element {
    const { onClick, subtext, isConnected, buttonText, isButtonDisabled } = useClickUpIntegrationRow();

    return (
        <AppIntegrationRow
            onCTAClick={onClick}
            subtext={subtext}
            label={'ClickUp'}
            icon={<ClickUpLogo style={{ width: '40px', height: '40px', marginLeft: '-7px', marginTop: '-5px' }} />}
            isConnected={isConnected}
            buttonText={buttonText}
            isButtonDisabled={isButtonDisabled}
            isConnectViewOnly={isConnectViewOnly}
        />
    );
}
