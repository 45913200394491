// keep the values short as they are persisted on the user's metadata
export enum ShowcaseGuideSlideId {
    AddSpinachToYourMeeting = 'asm',
    LetUsInFromTheWaitingRoom = 'lwt',
    ReviewYourMeeting = 'rym',
}

export enum ShowcaseGuideCtaTarget {
    Meetings = 'meetings',
    Settings = 'settings',
}

export interface ShowcaseSlide {
    id: ShowcaseGuideSlideId;
    title: string;
    description: string;
    ctaLabel: string;
    ctaTarget?: ShowcaseGuideCtaTarget;
}

export interface ShowcasePacket {
    title: string;
    slides: ShowcaseSlide[];
}

export interface ShowcaseGuideProps {
    packet: ShowcasePacket;
    className?: string;
}
