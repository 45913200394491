import { SlackChannelMetadata, SlackTeamType } from './Slack';

export enum OutputTargetKind {
    Email = 'email',
    Slack = 'slack',
}

export enum SpinachWorkflowActionKind {
    Prompt = 'prompt',
    MorningBriefing = 'morning-briefing',
}
export type SpinachWorkflowPromptAction = {
    kind: SpinachWorkflowActionKind.Prompt;
    promptId: string;
};

export type SpinachWorkflowMorningBriefingAction = {
    kind: SpinachWorkflowActionKind.MorningBriefing;
    timezone: string;
};

export type SpinachWorkflowAction = SpinachWorkflowPromptAction | SpinachWorkflowMorningBriefingAction;

export type SpinachWorkflowSlackOutputTarget = {
    kind: OutputTargetKind.Slack;
    config: {
        channel: SlackChannelMetadata;
        teamId: string;
        teamType: SlackTeamType;
    };
};

export type SpinachWorkflowEmailOutputTarget = {
    kind: OutputTargetKind.Email;
    config: {
        to: string[];
    };
};

export type SpinachWorkflowOutputTarget = SpinachWorkflowEmailOutputTarget | SpinachWorkflowSlackOutputTarget; // | other output targets

export type SpinachWorkflowInputJSON = {
    userId: string;
    outputTargets: SpinachWorkflowOutputTarget[];
    actions: SpinachWorkflowAction[];
    isActive: boolean;
};

export type SpinachWorkflowClientJSON = Pick<SpinachWorkflowInputJSON, 'userId' | 'isActive' | 'actions'> & {
    id: string;
};
