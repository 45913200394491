import { ShowcaseGuideCtaTarget, ShowcaseGuideSlideId, ShowcasePacket, ShowcaseSlide } from './types';

export const showcaseGuideSlidesById: Record<ShowcaseGuideSlideId, ShowcaseSlide> = {
    [ShowcaseGuideSlideId.AddSpinachToYourMeeting]: {
        id: ShowcaseGuideSlideId.AddSpinachToYourMeeting,
        title: 'Add Spinach to your meeting',
        description: 'Spinach attends your meetings, takes notes, and delivers them to you afterwards',
        ctaLabel: 'See your meetings',
        ctaTarget: ShowcaseGuideCtaTarget.Meetings,
    },
    [ShowcaseGuideSlideId.LetUsInFromTheWaitingRoom]: {
        id: ShowcaseGuideSlideId.LetUsInFromTheWaitingRoom,
        title: 'Let us in from the waiting room',
        description: 'We might be trying to join - please check the waiting room to let us in!',
        ctaLabel: 'Got it',
    },
    [ShowcaseGuideSlideId.ReviewYourMeeting]: {
        id: ShowcaseGuideSlideId.ReviewYourMeeting,
        title: 'Customize your experience',
        description: "Edit your meeting summary, control summary access, change your meeting's language and more!",
        ctaLabel: 'View your settings',
        ctaTarget: ShowcaseGuideCtaTarget.Settings,
    },
};

export const starterGuidePacket: ShowcasePacket = {
    title: 'Starter Guide',
    slides: [
        showcaseGuideSlidesById[ShowcaseGuideSlideId.AddSpinachToYourMeeting],
        showcaseGuideSlidesById[ShowcaseGuideSlideId.LetUsInFromTheWaitingRoom],
        showcaseGuideSlidesById[ShowcaseGuideSlideId.ReviewYourMeeting],
    ],
};
