import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';

import { BodySubtitle, lightTheme } from '../../styles';
import { withAccessibleSubmitProps } from '../../utils';
import { Column } from '../common';
import { CreateCard } from './common';
import { useGlobalAuthedUser } from '../../hooks';

const AddSeriesCardColumn = styled(Column)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export function CreateSeriesCard({ onOpenCreateSeriesModal }: { onOpenCreateSeriesModal: () => void }): JSX.Element {
    const [user] = useGlobalAuthedUser();
    return (
        <CreateCard
            style={{ borderStyle: 'dotted', borderColor: lightTheme.neutrals.gray }}
            {...withAccessibleSubmitProps(onOpenCreateSeriesModal)}
        >
            <AddSeriesCardColumn>
                <AddIcon htmlColor={lightTheme.primary.greenLight} />
                <BodySubtitle style={{ fontSize: '14px', color: lightTheme.primary.greenLight }}>
                    Create a new {user.meetingWord.toLowerCase()}
                </BodySubtitle>
            </AddSeriesCardColumn>
        </CreateCard>
    );
}
