import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useEffect, useState } from 'react';
import Sound from 'react-sound';
import { Socket } from 'socket.io-client';

import {
    challengesUpdateSectionTypeProps,
    icebreakerUpdateSectionTypeProps,
    todaysUpdateSectionTypeProps,
    yesterdaysHighlightsUpdateSectionTypeProps,
} from '@spinach-shared/constants';
import { ActiveAgendaProps, BaseParticipantProps, YTBAgendaItemProps } from '@spinach-shared/models';
import {
    AgendaItemBaseRequest,
    ClientSocketEvent,
    SpinachUpdateType,
    UUID,
    YTBUpdateUpdatedRequest,
} from '@spinach-shared/types';
import { getNewTypedUpdate } from '@spinach-shared/utils';

import { ElementId } from '../../constants';
import {
    UseButtonCooldownsProps,
    useGlobalAuthedUser,
    useGlobalDemoState,
    useGlobalLiveSeries,
    useGlobalNavDrawer,
    useGlobalNullableLiveSeries,
    useIssueBasedEnablement,
    useJiraBoardEnablement,
    usePersonaDemo,
    useSeriesReality,
    useTeamTopics2Enablement,
} from '../../hooks';
import { BodyLarge, BodyRegular, lightTheme } from '../../styles';
import { ActiveAgendaViewProps } from '../../types/StandUp';
import { createWebsocketPayload } from '../../utils/analytics';
import { withContentMasking } from '../../utils/withContentMasking';
import { BootstrapTooltip, OverlayHint, PulsingHint, Row, Spacing } from '../common';
import { Notification } from '../stand-up';
import { CustomUpdates } from './CustomUpdates';
import { DiscussionReaction, DiscussionReactionButtons, useDiscussionReactions } from './DiscussionReactions';
import { JiraBoardHeaderContainer } from './JiraBoardHeaderContainer';
import { LiveReservedUpdateSection } from './LiveReservedUpdateSection';
import { LongPressPrimaryButton } from './LongPressPrimaryButton';
import { PrimaryButton } from './PrimaryButton';
import { ScrollArea } from './ScrollArea';
import SecondaryButton from './SecondaryButton';
import StandUpFooter from './StandUpFooter';
import { StandUpHeader } from './StandUpHeader';

// disable sound playing logs
(window as any).soundManager?.setup({ debugMode: false });

type StandUpUpdateViewProps = ActiveAgendaViewProps & {
    currentItem: YTBAgendaItemProps;
    agendaOwner?: BaseParticipantProps;
};

enum NextButtonTextOptions {
    Done = "I'm done!",
    Finish = 'Finish!',
    Next = 'Next person',
    DiscussParkingLot = 'Discuss',
}

function useNextButtonText(agenda: ActiveAgendaProps, spinachUserId: UUID, topicsLabel: string): string {
    const isTeamTopics2Enabled = useTeamTopics2Enablement();
    const meditationExists = !!agenda.meditationItem;
    let lastIndex = agenda.lastItemIndex;
    let participantItemsLength = agenda.participantItems.length - 1;

    if (isTeamTopics2Enabled) {
        lastIndex -= 1;
    }

    if (meditationExists) {
        // the branching logic below is not aware of
        // backend participantItem filters for meditation
        participantItemsLength += 1;
    }

    if (isTeamTopics2Enabled && agenda.topicItems.length && agenda.currentIndex === participantItemsLength) {
        return `${NextButtonTextOptions.DiscussParkingLot} ${topicsLabel}`;
    } else if (
        agenda.currentItem instanceof YTBAgendaItemProps &&
        agenda.currentItem.standUpUpdate.spinachUserId === spinachUserId
    ) {
        return NextButtonTextOptions.Done;
    } else if (agenda.currentIndex === lastIndex) {
        return NextButtonTextOptions.Finish;
    } else if (!isTeamTopics2Enabled && agenda.currentIndex === agenda.lastItemIndex - 1) {
        return NextButtonTextOptions.DiscussParkingLot;
    } else {
        return NextButtonTextOptions.Next;
    }
}

function Footer({
    currentItem,
    agenda,
    cooldownProps,
    socket,
    seriesSlug,
}: {
    currentItem: YTBAgendaItemProps;
    agenda: ActiveAgendaProps;
    cooldownProps: UseButtonCooldownsProps;
    socket: Socket;
    seriesSlug: string;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [liveSeries] = useGlobalLiveSeries();
    const { focusedFeature, setFocusedFeature } = useGlobalDemoState();
    const isPersonaDemo = usePersonaDemo();
    const { isDemoSeries } = useSeriesReality();
    const nextButtonText = useNextButtonText(agenda, user.spinachUserId, liveSeries.topicsLabel);
    const { cooldown, isNavigationDisabled, bypassCooldown } = cooldownProps;

    const agendaNavigationRequest = createWebsocketPayload<AgendaItemBaseRequest>({
        spinachUserId: user.spinachUserId,
        meetingId: liveSeries?.currentMeeting.id,
        seriesSlug,
        itemId: currentItem.id,
    });

    const isYourTurn =
        agenda.currentItem instanceof YTBAgendaItemProps &&
        agenda.currentItem.standUpUpdate.spinachUserId === user.spinachUserId;

    const isNextButtonHighlightedForDemo = focusedFeature === ElementId.NextPresenter && isDemoSeries;

    let PrimaryNavButton = <></>;

    if (isDemoSeries || isYourTurn) {
        PrimaryNavButton = (
            <>
                <PulsingHint
                    pulseStyles={{
                        marginTop: '35px',
                    }}
                    isVisible={isDemoSeries && !isPersonaDemo}
                />
                {isNextButtonHighlightedForDemo ? (
                    <OverlayHint
                        style={{ marginTop: '-7px', borderRadius: '50px' }}
                        extraWidth={15}
                        extraHeight={15}
                        idOfElement={ElementId.NextPresenter}
                    />
                ) : null}
                <PrimaryButton
                    id={ElementId.NextPresenter}
                    title={nextButtonText}
                    disabled={isNavigationDisabled}
                    fadeInDuration={cooldown}
                    onClick={() => {
                        if (isDemoSeries) {
                            setFocusedFeature(null);
                        }
                        bypassCooldown();
                        socket.emit(ClientSocketEvent.AgendaProgressing, agendaNavigationRequest);
                    }}
                />
            </>
        );
    } else {
        PrimaryNavButton = (
            <LongPressPrimaryButton
                title={nextButtonText}
                pressTitle={'Going to next'}
                fixedWidth={NextButtonTextOptions.DiscussParkingLot ? 200 : 150}
                pressTime={450}
                disabled={isNavigationDisabled}
                fadeInDuration={cooldown}
                onClick={() => {
                    bypassCooldown();
                    socket.emit(ClientSocketEvent.AgendaProgressing, agendaNavigationRequest);
                }}
            />
        );
    }

    return (
        <StandUpFooter key={currentItem.id}>
            {PrimaryNavButton}

            <Spacing factor={0.83} />

            <SecondaryButton
                title={'Go back'}
                disabled={isNavigationDisabled}
                fadeInDuration={cooldown}
                onClick={() => {
                    bypassCooldown();
                    socket.emit(ClientSocketEvent.AgendaReversing, agendaNavigationRequest);
                }}
            />
        </StandUpFooter>
    );
}

function StandUpUpdateView({
    seriesSlug,
    spinachParticipant,
    currentItem,
    agendaOwner,
    agenda,
    socket,
    cooldownProps,
}: StandUpUpdateViewProps): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const { drawer } = useGlobalNavDrawer();
    const { spinachUserId } = spinachParticipant;
    const isUsersTurn = currentItem.standUpUpdate.spinachUserId === spinachUserId;

    const isJiraBoardEnabled = useJiraBoardEnablement();
    const isPersonaDemo = usePersonaDemo();

    const { isRealSeries } = useSeriesReality();

    const { isDiscussionReactionVisible, reaction, triggeringSpinachUserId } = useDiscussionReactions(socket);

    const [soundStatus, setSoundStatus] = useState<'PLAYING' | 'STOPPED' | 'PAUSED'>('PLAYING');

    const [liveSeries] = useGlobalLiveSeries();
    const { isIcebreakerLockedIn } = liveSeries.currentMeeting;

    const isIssueBasedEnabled = useIssueBasedEnablement();

    const [demoParkingLotNoti, setDemoParkingLotNoti] = useState('');

    useEffect(() => {
        const personUpdates = agenda.YTBItems.filter((item) => item.isParticipantAgendaItem);
        const currentPersonIndex = personUpdates.findIndex((item) => item.id === currentItem.id);
        const item = personUpdates[currentPersonIndex];
        if (currentPersonIndex === 0) {
            window.parent.postMessage({ type: 'first-person', name: currentItem.title }, 'https://meet.google.com');
        } else if (currentPersonIndex === personUpdates.length - 1) {
            window.parent.postMessage({ type: 'last-person', name: currentItem.title }, 'https://meet.google.com');
        } else {
            window.parent.postMessage({ type: 'next-person', name: currentItem.title }, 'https://meet.google.com');
        }
        // needed to avoid retrigger on extra person joining the agenda
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agenda.currentIndex]);

    const shouldNotHandlePersonaParkingLotItem = !isPersonaDemo || !isUsersTurn || agenda.topicItems.length;
    useEffect(() => {
        if (shouldNotHandlePersonaParkingLotItem) {
            return;
        }

        /** @NOTE avoid race condition from other emissions  */
        const CREATE_TOPIC_WITH_SLIGHT_DELAY_MS = 20;
        setTimeout(() => {
            const botSpinachUserId = agenda.YTBItems.find((item) => item.id !== spinachUserId)?.id;
            if (!botSpinachUserId) {
                return;
            }
            const payload = createWebsocketPayload<YTBUpdateUpdatedRequest>({
                ...user.toUserIdentity(),
                seriesSlug,
                spinachUserId: botSpinachUserId,
                participantId: botSpinachUserId,
                meetingId: liveSeries.currentMeeting.id,
                update: getNewTypedUpdate({
                    creatorId: botSpinachUserId,
                    text: 'Need more details on new feature to start work',
                    updateType: SpinachUpdateType.Topic,
                }),
            });
            socket?.emit(ClientSocketEvent.YTBUpdateUpdating, payload);
        }, CREATE_TOPIC_WITH_SLIGHT_DELAY_MS);

        const POP_TOAST_AFTER_MS = 2000;
        setTimeout(() => {
            setDemoParkingLotNoti('Harry added a parking lot item');
        }, POP_TOAST_AFTER_MS);
    }, [isUsersTurn, agenda.topicItems.length, isPersonaDemo]);

    return (
        <>
            {isRealSeries && !!agendaOwner && !agendaOwner.connectionIds?.length && (
                <BootstrapTooltip
                    title={`${agendaOwner?.displayName} filled out their update but is not in the meeting`}
                    placement={'bottom'}
                    arrow
                >
                    <Row
                        style={{
                            borderRadius: '4px',
                            justifyContent: 'center',
                            backgroundColor: lightTheme.secondary.greenLight,
                        }}
                    >
                        <BodyRegular
                            style={{
                                fontSize: '12px',
                                padding: '2px',
                                fontWeight: 'bold',
                                color: lightTheme.primary.midnight,
                            }}
                        >
                            Async Update
                        </BodyRegular>
                    </Row>
                </BootstrapTooltip>
            )}
            <StandUpHeader
                title={user.isDemoing && isUsersTurn ? `${currentItem.title} (You)` : currentItem.title}
                {...withContentMasking()}
                id="presenting-title"
            />

            <DiscussionReaction
                isDiscussionReactionVisible={isDiscussionReactionVisible && !drawer}
                triggeringSpinachUserId={triggeringSpinachUserId}
                reaction={reaction}
            />

            <ScrollArea useGrayShading={true} stretch={true}>
                {isJiraBoardEnabled ? <JiraBoardHeaderContainer currentItem={currentItem} /> : null}

                {isIssueBasedEnabled ? (
                    <>
                        {currentItem.standUpUpdate.hasNoIssueBasedUpdates ? (
                            <BodyLarge
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '10px',
                                    textAlign: 'left',
                                    display: 'flex',
                                    justifyContent: 'row',
                                }}
                            >
                                Nothing to share
                            </BodyLarge>
                        ) : null}
                    </>
                ) : currentItem.standUpUpdate.hasNoUpdates ? (
                    <BodyLarge
                        style={{
                            fontWeight: 'bold',
                            fontSize: '10px',
                            textAlign: 'left',
                            display: 'flex',
                            justifyContent: 'row',
                        }}
                    >
                        No Updates Today
                    </BodyLarge>
                ) : (
                    <>
                        <LiveReservedUpdateSection
                            agendaItem={currentItem}
                            socket={socket}
                            isInNavDrawer={false}
                            typeProps={yesterdaysHighlightsUpdateSectionTypeProps}
                            isPresentingSection={true}
                        />

                        <LiveReservedUpdateSection
                            agendaItem={currentItem}
                            socket={socket}
                            isInNavDrawer={false}
                            typeProps={todaysUpdateSectionTypeProps}
                            isPresentingSection={true}
                        />

                        <LiveReservedUpdateSection
                            agendaItem={currentItem}
                            socket={socket}
                            isInNavDrawer={false}
                            typeProps={challengesUpdateSectionTypeProps}
                            isPresentingSection={true}
                        />
                    </>
                )}

                <CustomUpdates socket={socket} agendaItem={currentItem} isPresentingSection={true} />

                {isIcebreakerLockedIn ? (
                    <LiveReservedUpdateSection
                        agendaItem={currentItem}
                        socket={socket}
                        typeProps={icebreakerUpdateSectionTypeProps}
                        isPresentingSection={true}
                    />
                ) : null}
            </ScrollArea>

            {isUsersTurn ? (
                <Sound
                    url={require('../../assets/ding.mp3')}
                    playStatus={soundStatus}
                    autoLoad
                    volume={15}
                    onFinishedPlaying={() => setSoundStatus('STOPPED')}
                    onError={() => {
                        // swallow error?
                    }}
                />
            ) : undefined}

            <Footer
                agenda={agenda}
                cooldownProps={cooldownProps}
                currentItem={currentItem}
                seriesSlug={seriesSlug}
                socket={socket}
            />

            <Notification
                isOpen={!!demoParkingLotNoti}
                containerStyle={{ bottom: '85px' }}
                duration={4000}
                onClose={() => {
                    setDemoParkingLotNoti('');
                }}
                message={demoParkingLotNoti}
                icon={
                    <CheckCircleIcon
                        style={{ color: lightTheme.neutrals.white }}
                        htmlColor={lightTheme.neutrals.white}
                    />
                }
            />

            {!isUsersTurn ? (
                <DiscussionReactionButtons
                    isDiscussionReactionVisible={isDiscussionReactionVisible}
                    seriesSlug={seriesSlug}
                    socket={socket}
                />
            ) : null}
        </>
    );
}

export default StandUpUpdateView;
