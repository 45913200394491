import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ClientUser } from '@spinach-shared/models';
import { ClientEventType, IClientUser, PlatformSource, WebUrlQuery } from '@spinach-shared/types';
import { getLowercaseDomainFromEmail } from '@spinach-shared/utils';

import { patchUser } from '../../apis';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalRouting } from '../../hooks';
import { useLocationalSeriesId } from '../../hooks/useGlobalSearchParams';
import { SetValue } from '../../types';
import { minimumLoadingDuration } from '../../utils';
import { FYI, FYIState } from '../common';
import { PersonalAndCompany } from './PersonalAndCompany';

export enum OnboardingStep {
    Personal,
}

type UseExistingOnboardingProgressProps = {
    onboardingStep: OnboardingStep;
    loadingMessage: string;
    setOnboardingStep: SetValue<OnboardingStep>;
    setLoadingMessage: SetValue<string>;
};

function useExistingOnboardingProgress(user: ClientUser): UseExistingOnboardingProgressProps {
    const [onboardingStep, setOnboardingStep] = useState(OnboardingStep.Personal);
    const [loadingMessage, setLoadingMessage] = useState('');

    useEffect(() => {
        if (!user.firstName || !user.lastName || !user.companyName || !user.howDidYouHear) {
            setOnboardingStep(OnboardingStep.Personal);
        }
    }, []);

    return {
        onboardingStep,
        setOnboardingStep,
        loadingMessage,
        setLoadingMessage,
    };
}

export function OnboardingRouter(): JSX.Element {
    const { routeToDirectExperience } = useGlobalRouting();
    const [user, setUser] = useGlobalAuthedUser();
    const deepLinkedSeriesId = useLocationalSeriesId();
    const [params] = useSearchParams();

    const track = useExperienceTracking();

    const { onboardingStep, loadingMessage, setLoadingMessage } = useExistingOnboardingProgress(user);

    const [firstName, setFirstName] = useState(user.shouldAuthBeforeDemo ? '' : user.firstName);
    const [lastName, setLastName] = useState(user.shouldAuthBeforeDemo ? '' : user.lastName);
    const [companyName, setCompanyName] = useState(user.companyName);
    const [howDidYouHear, setHowDidYouHear] = useState(user.howDidYouHear);
    const [howDidYouHearOther, setHowDidYouHearOther] = useState(user.howDidYouHearOther ?? '');

    const onPersonalViewSubmit = async () => {
        setLoadingMessage(`Great to meet you, ${firstName}!`);

        const isJoiningSeriesUponOnboarding = !!deepLinkedSeriesId || !!user.realSeries.length;
        const platformSource = (params.get(WebUrlQuery.PlatformSource) || undefined) as PlatformSource | undefined;
        const updatedUserMetadata: Partial<IClientUser> = {
            metadata: {
                firstName,
                lastName,
                preferredName: `${firstName} ${lastName}`,
                companyName,
                howDidYouHear,
                howDidYouHearOther,
                isJoiningSeriesUponOnboarding,
                platformSource,
                isOnboarded: true,
            },
        };

        const [updatedUserResponse] = await Promise.all([patchUser(updatedUserMetadata), minimumLoadingDuration()]);

        if (updatedUserResponse.user) {
            const updatedUser = new ClientUser(updatedUserResponse.user);

            if (!isJoiningSeriesUponOnboarding && user.metadata.isFirstUserInACompanyAssociation) {
                track(ClientEventType.NewCompanyAssociation, {
                    Domain: getLowercaseDomainFromEmail(user.email ?? ''),
                    CompanyName: updatedUser.metadata.companyName,
                });
            }

            track(ClientEventType.PersonalPageSubmitted, {
                ...updatedUser.toUserIdentityPayload(),
                PlatformSource: platformSource,
            });

            track(ClientEventType.OnboardingCompleted, {
                ...updatedUser.toUserIdentityPayload(),
                IsJoiningSeries: isJoiningSeriesUponOnboarding,
            });

            setUser(updatedUserResponse.user);
            routeToDirectExperience({ replace: true });
        } else {
            // send back to web auth flow
            setUser(null as unknown as IClientUser);
        }

        setLoadingMessage('');
    };

    if (loadingMessage) {
        return <FYI state={FYIState.Loading} header={loadingMessage} />;
    }

    switch (onboardingStep) {
        case OnboardingStep.Personal:
            return (
                <PersonalAndCompany
                    user={user}
                    firstName={firstName}
                    lastName={lastName}
                    companyName={companyName}
                    howDidYouHear={howDidYouHear}
                    howDidYouHearOther={howDidYouHearOther}
                    setFirstName={setFirstName}
                    setLastName={setLastName}
                    setCompanyName={setCompanyName}
                    setHowDidYouHear={setHowDidYouHear}
                    setHowDidYouHearOther={setHowDidYouHearOther}
                    onSubmit={onPersonalViewSubmit}
                />
            );
    }
}
