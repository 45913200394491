import { Error } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { DEFAULT_MICROSOFT_OAUTH_SCOPES, FRONT_END_MICROSOFT_AUTH_REDIRECT_PATH } from '@spinach-shared/constants';
import { getWebAppHost } from '@spinach-shared/utils';

import { ReactComponent as SignInWithMicrosoftAsset } from '../../assets/integrations/microsoft.svg';
import { ReactComponent as WhiteMicrosoftLogo } from '../../assets/white-ms-logo.svg';
import { lightTheme } from '../../styles';
import { getClientConfigValue } from '../../utils/getClientConfigValue';
import { Notification } from '../stand-up';
import { LoginButton } from './LoginButton';

const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const AUTH_URL = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${getClientConfigValue(
    'REACT_APP_MICROSOFT_APP_ID'
)}&scope=${DEFAULT_MICROSOFT_OAUTH_SCOPES.join(
    ' '
)}&redirect_uri=${getWebAppHost()}${FRONT_END_MICROSOFT_AUTH_REDIRECT_PATH}&response_mode=query&response_type=code`;

export function LoginWithMicrosoft({ label, variant }: { label?: string; variant?: 'orange' | 'default' }) {
    const [notification, setNotification] = useState('');
    useEffect(() => {
        if (notification) {
            setTimeout(() => {
                setNotification('');
            }, 5000);
        }
    }, [notification]);

    return (
        <Container>
            <a href={AUTH_URL} style={{ textDecoration: 'none' }}>
                <LoginButton
                    onClick={() => {}}
                    icon={
                        variant === 'orange' ? (
                            <WhiteMicrosoftLogo style={{ width: '18px', height: '18px' }} />
                        ) : (
                            <SignInWithMicrosoftAsset />
                        )
                    }
                    text={label || 'Sign in with Microsoft'}
                    variant={variant}
                />
            </a>
            <div style={{ position: 'relative', width: '100%' }}>
                <Notification
                    containerStyle={{ position: 'absolute', bottom: 'unset', top: '15px' }}
                    isOpen={!!notification}
                    onClose={() => null}
                    message={notification}
                    icon={<Error style={{ color: lightTheme.neutrals.white }} htmlColor={lightTheme.neutrals.white} />}
                />
            </div>
        </Container>
    );
}
