import { CallMade, InfoOutlined } from '@material-ui/icons';
import React from 'react';

import { ClientEventType, SpinachAPIPath, SpinachIntegration } from '@spinach-shared/types';

import ZoomSquare from '../../../../assets/demo/zoomSquare.svg';
import { ReactComponent as SlackLogo } from '../../../../assets/slack-logo.svg';
import SlackHuddeExample from '../../../../assets/slack/huddle-example.png';
import { GlobalModal } from '../../../../atoms';
import { ElementId } from '../../../../constants';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalModal,
    useIntegrationDetection,
    useIsZoomIntegration,
} from '../../../../hooks';
import { useGlobalSlack } from '../../../../hooks/useSlack';
import { BodyRegularOnboard, ButtonSize, HeaderThree, lightTheme } from '../../../../styles';
import { URLUtil, isZoomPlatform } from '../../../../utils';
import { initFullstory } from '../../../../utils/fullstory';
import { getClientConfigValue } from '../../../../utils/getClientConfigValue';
import { Badge, BootstrapTooltip, Column, Row, Spacing } from '../../../common';
import { IntegrationButtonText } from '../../../common/settings/AppIntegrationRow';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import SecondaryButton from '../../../stand-up/SecondaryButton';
import { IntegrationSettingsModalKind, UseHoverRowProps } from '../types';
import { ConnectedRowContents } from './ConnectedRowContents';
import { Label } from './common';
import { SpinachAppGoogleMeetIntegrationSection } from './standup-app';

export function ZoomAuthIntegrationSection({
    hoverRowProps,
    setModalKind,
}: {
    hoverRowProps: UseHoverRowProps;
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
}) {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const { getHoverableRowProps, hoveredRow } = hoverRowProps;
    const isZoomAuthed = !!user.recallZoomAuthCredentialId;

    const startDetection = useIntegrationDetection(
        undefined,
        (original, newUser) => original.recallZoomAuthCredentialId !== newUser.recallZoomAuthCredentialId
    );
    return (
        <Row>
            <Column>
                <Row
                    vCenter
                    {...getHoverableRowProps(SpinachIntegration.Zoom)}
                    style={{ ...getHoverableRowProps(SpinachIntegration.Zoom).style, alignItems: 'start' }}
                >
                    <span style={{ width: '24px', height: '24px', marginRight: '15px' }} />
                    <Label />
                    <Column style={{ alignItems: 'start' }}>
                        {isZoomAuthed ? (
                            isZoomPlatform() ? (
                                <Row>
                                    <ConnectedRowContents
                                        label="View"
                                        isSettingsVisible={hoveredRow === SpinachIntegration.Zoom}
                                        onSettingsClick={async () => {
                                            setModalKind(IntegrationSettingsModalKind.SettingsZoom);
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: `Open Remove Zoom Modal`,
                                            });
                                        }}
                                    />
                                </Row>
                            ) : (
                                <Row>
                                    <ConnectedRowContents label="Connected" isSettingsVisible={false} />
                                </Row>
                            )
                        ) : (
                            <>
                                <OutlinedButton
                                    title={'Connect'}
                                    onClick={() => {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: `Zoom OAuth Connect`,
                                        });
                                        const overrideZoomAppClientId =
                                            new URLSearchParams(window.location.search).get(
                                                'overrideZoomAppClientId'
                                            ) ?? undefined;
                                        const urlSuffix = overrideZoomAppClientId
                                            ? `?overrideZoomAppClientId=${overrideZoomAppClientId}`
                                            : '';
                                        URLUtil.openURL(
                                            `${getClientConfigValue('REACT_APP_AUTH_URL')}${
                                                SpinachAPIPath.RecallZoomAppAuth
                                            }${urlSuffix}`
                                        );
                                        startDetection();
                                    }}
                                    size={ButtonSize.Mini}
                                    endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                                    labelStyles={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                />
                            </>
                        )}
                    </Column>
                </Row>
                <Row>
                    <BodyRegularOnboard style={{ fontSize: '14px' }}>
                        Automatically allow recording permissions in Zoom
                    </BodyRegularOnboard>
                </Row>
            </Column>
        </Row>
    );
}

export function AddZoomAppSection() {
    const track = useExperienceTracking();
    return (
        <>
            <Row style={{ width: 'fit-content' }}>
                <OutlinedButton
                    title={IntegrationButtonText.Add}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Add Zoom Integration`,
                        });
                        track(ClientEventType.ZoomAppMarketplaceIntegrationsClick);
                        URLUtil.openURL('https://marketplace.zoom.us/apps/414jwrO_SsOksk6SAsMXgg', {
                            redirectThroughBackendWhenOnZoom: true,
                        });
                    }}
                    size={ButtonSize.Mini}
                    endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                    labelStyles={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                />
            </Row>
            <Spacing factor={1 / 4} />
        </>
    );
}

export function AddZoomAppIntegrationSection({ hoverRowProps }: { hoverRowProps: UseHoverRowProps }): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const { getHoverableRowProps } = hoverRowProps;

    return (
        <Row>
            <Column>
                <Row
                    vCenter
                    {...getHoverableRowProps(SpinachIntegration.Zoom)}
                    style={{ ...getHoverableRowProps(SpinachIntegration.Zoom).style, alignItems: 'start' }}
                >
                    <img
                        src={ZoomSquare}
                        style={{ marginTop: '3px', width: '24px', height: '24px', marginRight: '15px' }}
                    />
                    <Label>Zoom</Label>
                    <Column style={{ alignItems: 'start' }}>
                        {user.isEnabledForCombinedSummaries ? (
                            isZoomPlatform() ? (
                                <Row>
                                    <ConnectedRowContents label="Connected" isSettingsVisible={false} />
                                </Row>
                            ) : (
                                <>
                                    <AddZoomAppSection />
                                </>
                            )
                        ) : (
                            <></>
                        )}
                    </Column>
                </Row>
                <Row>
                    <BodyRegularOnboard style={{ fontSize: '14px' }}>View Spinach directly in Zoom</BodyRegularOnboard>
                </Row>
            </Column>
        </Row>
    );
}

export function ZoomIntegrationSection({
    hoverRowProps,
    setModalKind,
}: {
    hoverRowProps: UseHoverRowProps;
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();

    if (!user.isEnabledForCombinedSummaries) {
        return <></>;
    }

    return (
        <>
            <AddZoomAppIntegrationSection hoverRowProps={hoverRowProps} />
            <Spacing factor={1 / 3} />
            <ZoomAuthIntegrationSection hoverRowProps={hoverRowProps} setModalKind={setModalKind} />
        </>
    );
}

export function SlackHuddleConnectionSection() {
    const [, setGlobalModal] = useGlobalModal();
    return (
        <Row style={{ marginTop: '5px' }}>
            <Column>
                <Row vCenter>
                    <Badge containerStyle={{ marginLeft: 'unset' }} title={'New'} />
                    <Spacing horizontal factor={1 / 3} />
                    <BodyRegularOnboard>Slack Huddles: </BodyRegularOnboard>
                    <Spacing horizontal factor={1 / 3} />
                    <OutlinedButton
                        id={ElementId.AISettingsSlackHuddleConfigureButton}
                        tabIndex={-1}
                        title={'Configure'}
                        size={ButtonSize.Mini}
                        onClick={() => {
                            initFullstory();
                            setGlobalModal(GlobalModal.ConfigureSlackHuddles);
                        }}
                    />
                </Row>

                <Row>
                    <BodyRegularOnboard style={{ display: 'flex', alignItems: 'center', paddingRight: '5px' }}>
                        Want us to send summaries to your Slack channel?
                    </BodyRegularOnboard>
                    <SecondaryButton
                        title="Enable here"
                        onClick={() => {
                            const slackChannelSelectionDropdown = document.getElementById(
                                ElementId.SlackChannelSelection
                            );
                            if (slackChannelSelectionDropdown) {
                                slackChannelSelectionDropdown.scrollIntoView({ behavior: 'smooth' });
                                slackChannelSelectionDropdown.focus();
                            }
                        }}
                    />
                </Row>
            </Column>
        </Row>
    );
}

export function SlackIntegrationsSection({
    setModalKind,
    hoverRowProps,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    hoverRowProps: UseHoverRowProps;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const startDetection = useIntegrationDetection();
    const { slackState } = useGlobalSlack();

    const { getHoverableRowProps, hoveredRow } = hoverRowProps;

    return (
        <Row>
            <Column>
                <Row vCenter {...getHoverableRowProps(SpinachIntegration.Slack)}>
                    <SlackLogo style={{ minWidth: '24px', width: '24px', height: '24px', marginRight: '15px' }} />
                    <Label style={{ display: 'flex', alignItems: 'center' }}>
                        Slack
                        {user.isAuthedForSlack ? (
                            <ConnectedRowContents
                                label={''}
                                isSettingsVisible={hoveredRow === SpinachIntegration.Slack}
                                onSettingsClick={() => {
                                    setModalKind(IntegrationSettingsModalKind.SettingsSlack);
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: `Open Remove Slack Modal`,
                                    });
                                }}
                            />
                        ) : (
                            <>
                                <BootstrapTooltip
                                    interactive
                                    placement="top"
                                    PopperProps={{
                                        style: {
                                            border: `1px ${lightTheme.neutrals.grayDark} solid`,
                                            width: '50vh',
                                        },
                                    }}
                                    arrowStyle={{
                                        border: `1px ${lightTheme.neutrals.grayDark} solid`,
                                        backgroundColor: lightTheme.neutrals.white,
                                        color: lightTheme.neutrals.white,
                                    }}
                                    tooltipStyle={{
                                        maxWidth: 'unset',
                                        padding: '25px',
                                        margin: 'unset',
                                        backgroundColor: lightTheme.neutrals.white,
                                        width: 'calc(100%-10px)',
                                    }}
                                    title={
                                        <>
                                            <Row style={{ width: 'unset' }} centered>
                                                <img
                                                    src={SlackHuddeExample}
                                                    style={{
                                                        maxWidth: '350px',
                                                        maxHeight: '350px',
                                                        flexShrink: 1,
                                                    }}
                                                />
                                            </Row>
                                            <Spacing factor={1 / 2} />
                                            <BodyRegularOnboard style={{ textAlign: 'center' }}>
                                                Remember the conversation. Spinach can join your Huddles and take notes
                                                automatically.
                                            </BodyRegularOnboard>
                                        </>
                                    }
                                >
                                    <InfoOutlined
                                        style={{ marginLeft: '5px', color: lightTheme.primary.greenLight }}
                                        htmlColor={lightTheme.primary.greenLight}
                                    />
                                </BootstrapTooltip>
                                <Badge title="New" />
                            </>
                        )}
                    </Label>

                    {!user.isAuthedForSlack ? (
                        <Column style={{ alignItems: 'start' }}>
                            <OutlinedButton
                                title={'Connect'}
                                onClick={() => {
                                    startDetection();
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: `Slack Connect`,
                                        From: 'Meeting Platform Integration Section',
                                    });
                                    URLUtil.openURL(slackState.installUrl);
                                }}
                                size={ButtonSize.Mini}
                                endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                                labelStyles={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            />
                        </Column>
                    ) : (
                        <Column style={{ alignItems: 'start' }}>
                            <SlackHuddleConnectionSection />
                        </Column>
                    )}
                </Row>
            </Column>
        </Row>
    );
}

export function MeetingPlatformIntegrationSection({
    setModalKind,
    hoverRowProps,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    hoverRowProps: UseHoverRowProps;
}): JSX.Element {
    const isZoomIntgrationEnabled = useIsZoomIntegration();
    const [user] = useGlobalAuthedUser();

    if (!isZoomIntgrationEnabled && !user.isEnabledForCombinedSummaries && !user.isSlackHuddleInAppConnectionEnabled) {
        return <></>; // TODO remove if we ever add more things to this section
    }

    return (
        <>
            <Row>
                <HeaderThree>Meeting Platforms</HeaderThree>
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>Improved integration with meeting platform</BodyRegularOnboard>
            </Row>

            <Spacing factor={1 / 2} />
            {user.isSlackHuddleInAppConnectionEnabled ? (
                <>
                    <SlackIntegrationsSection hoverRowProps={hoverRowProps} setModalKind={setModalKind} />
                    <Spacing />
                </>
            ) : (
                <></>
            )}

            <ZoomIntegrationSection hoverRowProps={hoverRowProps} setModalKind={setModalKind} />

            <Spacing />
            {user.isEnabledForCombinedSummaries ? <SpinachAppGoogleMeetIntegrationSection /> : <></>}
        </>
    );
}
