import { SpinachAPIPath, SpinachWorkflowClientJSON } from '@spinach-shared/types';

import { postSpinachAPI } from '../postSpinachAPI';

export async function toggleMorningBriefWorkflow(opts: {
    timezone?: string;
    isActive: boolean;
    includeDefaultSlackChannel?: boolean;
    cronExpression?: string;
}): Promise<SpinachWorkflowClientJSON | null> {
    const response = await postSpinachAPI<{ workflow: SpinachWorkflowClientJSON }>(
        SpinachAPIPath.ToggleMorningBrief,
        opts
    );

    return response?.workflow ?? null;
}
