import zoomSdk from '@zoom/appssdk';

import { SpinachAPIPath } from '@spinach-shared/types';

import { getClientConfigValue } from '../utils/getClientConfigValue';
import { isZoomPlatform } from './platform';

type OpenURLOptions = {
    redirectThroughBackendWhenOnZoom?: boolean;
};

export class URLUtil {
    static openURL(url: string, options?: OpenURLOptions): Window | null {
        if (isZoomPlatform()) {
            if (url) {
                if (options?.redirectThroughBackendWhenOnZoom) {
                    zoomSdk.openUrl({
                        url: `${getClientConfigValue('REACT_APP_AUTH_URL')!}${
                            SpinachAPIPath.Redirect
                        }?redirect_url=${url}`,
                    });
                } else {
                    zoomSdk.openUrl({ url });
                }
                return null;
            } else {
                return null;
            }
        } else {
            return window.open(url, '_blank');
        }
    }

    static openURLPopUp(url: string): Window | null {
        if (isZoomPlatform()) {
            throw new Error('unable to open pop up in zoom');
        }

        return window.open(
            url,
            undefined,
            'right=0,toolbar=off,menubar=off,location=off,resizable=1,width=550,height=1000'
        );
    }

    static isUrl(urlString: string): boolean {
        let url: URL;
        try {
            url = new URL(urlString);
        } catch (e) {
            return false;
        }

        return url.protocol === 'http:' || url.protocol === 'https:';
    }
}
