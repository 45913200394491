export const supportedUploadFileTypes = [
    'video/mp4',
    'video/quicktime',
    'video/mpeg',
    'video/webm',
    'video/x-flv',
    'video/x-msvideo',
    'video/x-matroska',
    'video/mxf',
    'video/x-m4v',
    'audio/3gpp',
    'audio/x-8svx',
    'audio/aac',
    'audio/ac3',
    'audio/x-aiff',
    'audio/x-alac',
    'audio/amr',
    'audio/ape',
    'audio/basic',
    'audio/dss',
    'audio/flac',
    'audio/mp3',
    'audio/mpeg',
    'audio/mp4',
    'audio/x-m4a',
    'audio/x-aac',
    'audio/x-caf',
    'audio/x-wav',
    'audio/webm',
    'audio/ogg',
    'audio/opus',
    'audio/qcp',
    'audio/x-tta',
    'audio/voc',
    'audio/wav',
    'audio/x-wav',
    'audio/x-wma',
    'audio/x-wv',
] as const;
