import axios from 'axios';

import { SpinachAPIPath } from '@spinach-shared/types';
import { getDefaultRequestHeaders } from '@spinach-shared/utils';

import { SpinachAPIOptions } from '../types';
import { isChromeExtensionPlatform, isWebPlatform } from '../utils';
import { getHeaderOpts } from '../utils/api';
import { getClientConfigValue } from '../utils/getClientConfigValue';

export async function getSpinachAPI<T, U extends Record<string, any> = Record<string, any>>(
    path: SpinachAPIPath | string,
    opts: SpinachAPIOptions<U> = {}
): Promise<T | undefined> {
    try {
        const { params, headers = {} } = opts;

        const response = await axios.get<T>(`${getClientConfigValue('REACT_APP_AUTH_URL')}${path}`, {
            headers: {
                ...headers,
                ...getDefaultRequestHeaders(getHeaderOpts()),
                'Cache-Control': 'no-store',
            },
            withCredentials: isWebPlatform() || isChromeExtensionPlatform(),
            params,
        });

        return response.data ?? undefined;
    } catch (error) {
        if (opts.throwOnError) {
            throw error;
        }
        return undefined;
    }
}
