import { Divider } from '@material-ui/core';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { MEETING_TYPE_FEATURE_INTENT_MAP } from '@spinach-shared/constants';
import {
    ClientEventType,
    FeatureIntent,
    SummaryFeatureIntents,
    SummaryJson,
    UserNameWithEmailConfidenceMap,
    isCustomizableSummaryFeatureIntent,
} from '@spinach-shared/types';

import { getAiSummaryJson } from '../../../../../apis';
import { postEditedSummary } from '../../../../../apis/postEditedSummary';
import { GlobalModal, GlobalModalMetadataType } from '../../../../../atoms';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalModal, useLandingAnalytic } from '../../../../../hooks';
import { useGlobalAiDashboard } from '../../../../../hooks/useGlobalAiDashboard';
import { BodyRegularOnboard, HeaderThree, HeaderThreeOnboard } from '../../../../../styles';
import { ClientLogger } from '../../../../../utils';
import { LoadingSquares, Row, Spacing, ViewContainer } from '../../../../common';
import { PrimaryButton } from '../../../../stand-up';
import { useNavigateToUpgrade } from '../../../hooks/useNavigateToUpgrade';
import { OutlineButton } from '../../../onboarding/step-tracker-onboarding/shared';
import { ActionItemSectionEditor } from './section-editors/ActionItemSectionEditor';
import { AgendaItemSectionEditor } from './section-editors/AgendaItemSectionEditor';
import { BlockerSectionEditor } from './section-editors/BlockerSectionEditor';
import { CelebrationSectionEditor } from './section-editors/CelebrationSectionEditor';
import { CompanyAllHandsSectionEditor } from './section-editors/CompanyAllHandsSectionEditor';
import { CustomerSuccessCheckInSectionEditor } from './section-editors/CustomerSuccessCheckInSectionEditor';
import { GeneralSalesCallSectionEditor } from './section-editors/GeneralSalesCallSectionEditor';
import { KeyDecisionSectionEditor } from './section-editors/KeyDecisionSectionEditor';
import { PersonalUpdatesSectionEditor } from './section-editors/PersonalUpdatesSectionEditor';
import { WorkstreamsSectionEditor } from './section-editors/WorkstreamsSectionEditor';
import { SectionComponentProps } from './shared';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 32px;
`;

type SectionEditComponent = React.ComponentType<SectionComponentProps>;

function mapIntentToEditorSection(intent: SummaryFeatureIntents): SectionEditComponent | null {
    switch (intent) {
        case FeatureIntent.MeetingAgendaItems:
            return AgendaItemSectionEditor;
        case FeatureIntent.MeetingActionItems:
            return ActionItemSectionEditor;
        case FeatureIntent.MeetingKeyDecisions:
            return KeyDecisionSectionEditor;
        case FeatureIntent.MeetingBlockers:
            return BlockerSectionEditor;
        case FeatureIntent.MeetingCelebrations:
            return CelebrationSectionEditor;
        case FeatureIntent.PerPersonUpdates:
            return PersonalUpdatesSectionEditor;
        case FeatureIntent.MeetingCustomerSuccessCheckIn:
            return CustomerSuccessCheckInSectionEditor;
        case FeatureIntent.MeetingGeneralSalesCall:
            return GeneralSalesCallSectionEditor;
        case FeatureIntent.MeetingWorkstreams:
            return WorkstreamsSectionEditor;
        case FeatureIntent.MeetingCompanyAllHandsSummary:
            return CompanyAllHandsSectionEditor;
        default:
            return null;
    }
}

export type DraftEditorProps = {
    botId: string;
    onDraftSent: () => void;
    onErrorLoadingSummary: () => void;
};

export function DraftEditor({ botId, onDraftSent, onErrorLoadingSummary }: DraftEditorProps): JSX.Element {
    const track = useExperienceTracking();
    const [summaryJSON, setSummaryJSON] = useState<SummaryJson | null>();
    const [meetingTime, setMeetingTime] = useState('');
    const [meetingTitle, setMeetingTitle] = useState('');
    const [usernameEmailMapping, setUsernameEmailMapping] = useState<UserNameWithEmailConfidenceMap | undefined>(
        undefined
    );
    const [summaryIntents, setSummaryIntents] = useState<SummaryFeatureIntents[]>([]);
    const [attendees, setAttendees] = useState<{ email: string; rootDomain: string }[]>([]);
    const [emailsWithAccess, setEmailsWithAccess] = useState<string[]>([]);
    const [hostUserEmail, setHostUserEmail] = useState<string | undefined>();
    const navigateToUpgrade = useNavigateToUpgrade();

    const [user] = useGlobalAuthedUser();
    const { setToastText } = useGlobalAiDashboard();
    useLandingAnalytic(ClientEventType.AIDashboardActivity, {
        Activity: 'Opened draft editor',
        BotId: botId,
    });

    useEffect(() => {
        async function exec() {
            try {
                const response = await getAiSummaryJson(botId, true);

                if (!response) {
                    throw new Error('Failed to load summary');
                } else if ('summaryJson' in response) {
                    setSummaryJSON(response.summaryJson);
                    setMeetingTime(response.meetingTime);
                    setMeetingTitle(response.meetingTitle);
                    setUsernameEmailMapping(response.usernameEmailMapping);
                    setAttendees(response.attendees ?? []);
                    setEmailsWithAccess(response.emailsWithAccess);
                    setHostUserEmail(response.hostUserEmail);

                    const defaultIntents = MEETING_TYPE_FEATURE_INTENT_MAP[response.meetingType];
                    // the meeting's intents are those that are in the default list and are not customizable or are customizable and enabled
                    const enabledIntents = defaultIntents.filter(
                        (intent) =>
                            !isCustomizableSummaryFeatureIntent(intent) ||
                            !response.enabledSummarySections?.length ||
                            response.enabledSummarySections?.includes(intent)
                    );
                    setSummaryIntents(enabledIntents);
                }
            } catch (e) {
                ClientLogger.error('Error loading summary for draft edit', e);
                onErrorLoadingSummary();
                setToastText(
                    'Oops, something went wrong, please try again later. If the problem persists, please contact support'
                );
            }
        }
        exec();
    }, [botId]);

    const [isLoading, setIsLoading] = useState(false);

    const [, setGlobalModal] = useGlobalModal();

    if (!summaryJSON) {
        return (
            <ViewContainer>
                <LoadingSquares />
            </ViewContainer>
        );
    }

    return (
        <Container>
            <Row style={{ justifyContent: 'space-between', zIndex: 1000, marginBlock: '10px' }}>
                <HeaderThreeOnboard>
                    {meetingTitle} - {meetingTime}
                </HeaderThreeOnboard>
                {user.hasProFeatures ? (
                    <>
                        <div style={{ flex: 1 }} />
                        <PrimaryButton
                            title="Send"
                            isLoading={isLoading}
                            loadingText={''}
                            disabled={!botId}
                            style={{ height: '40px', marginRight: '10px', flex: 0 }}
                            onClick={async () => {
                                if (!botId) {
                                    return;
                                }

                                const iEnabledForManageDraftSummaryRecipients =
                                    user.isEnabledForManageDraftSummaryRecipients;

                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: 'Send Draft Summary',
                                    IsEnabledForManageDraftSummaryRecipients: iEnabledForManageDraftSummaryRecipients,
                                });

                                setIsLoading(true);
                                const response = await postEditedSummary({
                                    summaryJSON,
                                    botId,
                                });

                                if (!response.success) {
                                    setToastText(
                                        "We couldn't send your summary at this time. Try again in a bit or contact us."
                                    );
                                } else {
                                    setToastText('Your summary has been sent!');
                                    onDraftSent();
                                }
                                setIsLoading(false);
                            }}
                        />
                        {user.isEnabledForManageDraftSummaryRecipients ? (
                            <OutlineButton
                                style={{
                                    marginRight: '16px',
                                    flex: 0,
                                    height: '28px',
                                    width: '16px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '4px 6px',
                                    fontSize: '24px',
                                }}
                                isSelected={false}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Edit Draft Summary Recipients',
                                    });
                                    setGlobalModal(GlobalModal.ManageDraftSummaryRecipients, {
                                        metadata: {
                                            botId,
                                            summaryJSON,
                                            metadataType: GlobalModalMetadataType.ManageDraftSummaryRecipients,
                                            defaultList: emailsWithAccess,
                                            attendees: attendees,
                                            onSaved: (emails) => {
                                                setEmailsWithAccess(emails);
                                            },
                                            hostUserEmail: hostUserEmail ?? '',
                                        },
                                    });
                                }}
                            >
                                •••
                            </OutlineButton>
                        ) : null}
                    </>
                ) : (
                    <PrimaryButton
                        title="Upgrade to Pro"
                        style={{ height: '40px' }}
                        onClick={() => {
                            navigateToUpgrade('Draft Editor');
                        }}
                    />
                )}
            </Row>

            <Row>
                <BodyRegularOnboard>
                    {user.hasProFeatures
                        ? 'Review and edit the summary below before sending.'
                        : 'Note: This summary has not been sent to attendees. Upgrade to Pro to edit.'}
                </BodyRegularOnboard>
            </Row>

            <Spacing />
            <Row>
                <HeaderThree>Attendees</HeaderThree>
            </Row>
            <Row>
                <BodyRegularOnboard>
                    {summaryJSON.attendees
                        .filter((attendee) => attendee.toLowerCase() !== 'spinach.io')
                        .filter((attendee) => attendee.toLowerCase() !== 'spinach ai')
                        .join(', ')}
                </BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 2} />
            <Divider />
            {summaryIntents.map((intent, i) => {
                const EditorSection = mapIntentToEditorSection(intent);
                if (!EditorSection) {
                    return null;
                }

                return (
                    <div key={intent}>
                        <EditorSection
                            setSummaryJSON={setSummaryJSON}
                            summaryJSON={summaryJSON}
                            usernameEmailMapping={usernameEmailMapping}
                        />
                        {i < summaryIntents.length - 1 ? (
                            <>
                                <Spacing factor={1 / 2} />
                                <Divider />
                            </>
                        ) : null}
                    </div>
                );
            })}
            {/* padding the bottom */}
            <Row style={{ height: '16px' }} />
        </Container>
    );
}
