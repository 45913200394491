import { useEffect } from 'react';

import { isLocalStage } from '@spinach-shared/utils';

import { ClientLogger, clarity } from '../utils';
import { getClientConfigValue } from '../utils/getClientConfigValue';

export const useClarity = () => {
    const SCREEN_HEIGHT_OF_LINKEDIN_BOT = 960;
    const shouldNotRecord = window.innerHeight === SCREEN_HEIGHT_OF_LINKEDIN_BOT || isLocalStage();
    useEffect(() => {
        if (shouldNotRecord) {
            return;
        }
        if (!getClientConfigValue('REACT_APP_CLARITY_PROJECT_ID')) {
            ClientLogger.error('REACT_APP_CLARITY_PROJECT_ID is not defined');
            return;
        }
        try {
            clarity.init(getClientConfigValue('REACT_APP_CLARITY_PROJECT_ID'));
        } catch (e) {
            ClientLogger.error('Failed to initialize clarity', e);
        }
    }, []);
};
