import { ReactNode } from 'react';

import { ClientUser } from '@spinach-shared/models';
import {
    ClientEventType,
    MeetingSettingsSubview,
    OAuthProvider,
    SpinachAPIPath,
    WebUrlQuery,
} from '@spinach-shared/types';

import { IntegrationButtonText } from '../components/common/settings/AppIntegrationRow';
import { UseIntegrationRow } from '../components/common/settings/common';
import { URLUtil } from '../utils';
import { getClientConfigValue } from '../utils/getClientConfigValue';
import { useExperienceTracking } from './useExperienceTracking';
import { useGlobalMeetingSettings } from './useGlobalMeetingSettings';
import { useGlobalAuthedUser } from './useGlobalUser';
import { useIntegrationDetection } from './useIntegrationDetection';

export function useIntegrationRow(
    provider: OAuthProvider,
    subview: MeetingSettingsSubview,
    isAuthedForProvider: boolean,
    subtext: NonNullable<ReactNode>,
    onIntegrationSuccess?: (updatedUser: ClientUser) => void
): UseIntegrationRow {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const { setSubview } = useGlobalMeetingSettings();

    const startDetection = useIntegrationDetection(onIntegrationSuccess);

    const isConnectFlow = !isAuthedForProvider;

    async function onClick() {
        if (isConnectFlow) {
            track(ClientEventType.UserAuthorizeProviderClick, {
                OAuthProvider: provider,
                IsAuthedForProvider: isAuthedForProvider,
            });
            startDetection();
            URLUtil.openURL(
                `${getClientConfigValue('REACT_APP_AUTH_URL')}${SpinachAPIPath.IntegrationAuth.replace(
                    ':oauthProvider',
                    provider
                )}?${WebUrlQuery.Suid}=${user.spinachUserId}`,
                { redirectThroughBackendWhenOnZoom: true }
            );
        } else {
            setSubview(subview);
            track(ClientEventType.OpenSettingsSubviewClick, {
                OAuthProvider: provider,
                IsAuthedForProvider: isAuthedForProvider,
            });
        }
    }

    return {
        subtext,
        onClick,
        isConnected: isAuthedForProvider,
        buttonText: isAuthedForProvider ? IntegrationButtonText.Configure : IntegrationButtonText.Connect,
    };
}
