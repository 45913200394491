import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { fetchSlackChannels } from '../apis/fetchSlackChannels';
import { GlobalSlackState, atomSlack } from '../atoms';
import { SetValue } from '../types';
import { ClientLogger } from '../utils';
import { getClientConfigValue } from '../utils/getClientConfigValue';
import { useGlobalAuthedUser } from './useGlobalUser';

export type GlobalSlackStateHook = {
    setInstallURL: (url: string) => void;
    slackState: GlobalSlackState;
    fetchSlackChannels: (noCache?: boolean) => Promise<void>;
    isFetchingChannels: boolean;
    setSlackState: SetValue<GlobalSlackState>;
    setShouldSendSummaryOnChannelSelect: (val: boolean) => void;
    setSlackChannelOptions: (options: { code: string; label: string }[]) => void;
};

export function useGlobalSlack(): GlobalSlackStateHook {
    const [slackState, setSlackState] = useRecoilState(atomSlack);
    const [user] = useGlobalAuthedUser();
    const [isFetchingChannels, setIsFetchingChannels] = useState(false);

    function setInstallURL(installUrl: string) {
        setSlackState({
            ...slackState,
            installUrl,
        });
    }

    function setShouldSendSummaryOnChannelSelect(val: boolean) {
        setSlackState({
            ...slackState,
            shouldSendSummaryOnChannelConnect: val,
        });
    }

    function setSlackChannelOptions(slackChannels: { code: string; label: string }[]) {
        setSlackState({
            ...slackState,
            slackChannels,
        });
    }

    async function fetchChannels(noCache?: boolean) {
        // HACK: wait for installUrl to populate before fetching channels to avoid overriting it in global state
        if (!user.isAuthedForSlack || !slackState.installUrl) {
            return;
        }

        setIsFetchingChannels(true);
        const teamId = user.slackSettings?.teamId;
        const teamType = user.slackSettings?.teamType;

        const slackChannels = await fetchSlackChannels(teamId, teamType, user.spinachUserId, noCache);
        setSlackChannelOptions(
            slackChannels.map((c) => ({
                code: c.id,
                label: c.isPrivate ? `🔒${c.name}` : `#${c.name}`,
                isPrivate: c.isPrivate,
            }))
        );
        setIsFetchingChannels(false);
    }

    return {
        setInstallURL,
        slackState,
        fetchSlackChannels: fetchChannels,
        isFetchingChannels,
        setSlackState,
        setShouldSendSummaryOnChannelSelect,
        setSlackChannelOptions,
    };
}

export function useSlackInstallLink(): string {
    const [user] = useGlobalAuthedUser();
    const { setInstallURL, slackState } = useGlobalSlack();

    useEffect(() => {
        fetch(
            `${getClientConfigValue('REACT_APP_SLACK_SERVER_URL')}/install?href=true&sid=${
                user.spinachUserId
            }&version=${getClientConfigValue('REACT_APP_APP_VERSION')}`
        )
            .then((response) =>
                response.text().then((url) => {
                    // if slack is down, do not set the error response as the url
                    if (url?.includes('http')) {
                        setInstallURL(url);
                    }
                })
            )
            .catch((error) => {
                ClientLogger.error('Failed to load slack install link', error);
            });
    }, [user.spinachUserId]);

    return slackState.installUrl;
}

export function useSlackChannels(): {
    isLoading: boolean;
    slackChannels: { code: string; label: string }[];
} {
    const [user] = useGlobalAuthedUser();
    const [isLoading, setIsLoading] = useState(false);
    const { setSlackChannelOptions, slackState } = useGlobalSlack();

    useEffect(() => {
        async function fetchChannels() {
            // HACK: wait for installUrl to populate before fetching channels to avoid overriting it in global state
            if (!user.isAuthedForSlack || !slackState.installUrl) {
                return;
            }

            const teamId = user.slackSettings?.teamId;
            const teamType = user.slackSettings?.teamType;

            setIsLoading(true);

            const slackChannels = await fetchSlackChannels(teamId, teamType, user.spinachUserId);
            setSlackChannelOptions(
                slackChannels.map((c) => ({
                    code: c.id,
                    label: c.isPrivate ? `🔒${c.name}` : `#${c.name}`,
                    isPrivate: c.isPrivate,
                }))
            );
            setIsLoading(false);
        }

        fetchChannels();
    }, [user.isAuthedForSlack, slackState.installUrl]);

    return {
        isLoading,
        slackChannels: slackState.slackChannels,
    };
}
