import { CallMade, EmailOutlined, FreeBreakfast, InfoOutlined } from '@material-ui/icons';
import { useState } from 'react';

import { DashboardSection } from '@spinach-shared/constants';
import { ClientEventType, SpinachIntegration, SpinachWorkflowActionKind } from '@spinach-shared/types';
import { TimeUtils } from '@spinach-shared/utils';

import { deleteSlackUserDefaultChannel, getUser, patchAllSeriesSettings, patchUser } from '../../../../apis';
import { postSlackDefaultUserChannel } from '../../../../apis/postSlackDefaultUserChannel';
import { toggleMorningBriefWorkflow } from '../../../../apis/workflows';
import { ReactComponent as SlackLogo } from '../../../../assets/slack-logo.svg';
import SlackSummaryExample from '../../../../assets/slack/slack-summary-example.png';
import { ElementId } from '../../../../constants';
import {
    useEmailSummaryToInviterOnly,
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useIntegrationDetection,
    useProFeatures,
} from '../../../../hooks';
import { useStoredSeriesListFetcher } from '../../../../hooks/useGlobalStoredSeriesList';
import { useGlobalSlack } from '../../../../hooks/useSlack';
import { useGlobalSpinachWorkflows } from '../../../../hooks/workflows/useSpinachWorkflows';
import { BodyRegularOnboard, BodySmallOnboard, ButtonSize, HeaderThree, lightTheme } from '../../../../styles';
import { ClientLogger, URLUtil } from '../../../../utils';
import { Anchor, BootstrapTooltip, Column, Row, Spacing } from '../../../common';
import { SpinachSwitch } from '../../../common/SpinachSwitch';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import SecondaryButton from '../../../stand-up/SecondaryButton';
import { useNavigateToUpgrade } from '../../hooks/useNavigateToUpgrade';
import { EmailOptions } from '../EmailOptions';
import { IntegrationSettingsModalKind, UseHoverRowProps } from '../types';
import { ConnectedRowContents } from './ConnectedRowContents';
import { SlackChannelSelection } from './SlackChannelSelection';
import { Label } from './common';

export function SlackDefaultChannelSection(): JSX.Element {
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();
    const { setToastText } = useGlobalAiDashboard();
    const [selectedChannel, setSelectedChannel] = useState<{ code: string; label: string; isPrivate?: boolean } | null>(
        user.defaultChannelSelection
    );
    return (
        <Row>
            <Column>
                <Row vCenter>
                    <BodyRegularOnboard>Default channel: </BodyRegularOnboard>
                    <SlackChannelSelection
                        from="CommunicationsIntegrationSection"
                        selectedChannel={selectedChannel}
                        setSelectedChannel={setSelectedChannel}
                        updateChannel={async (channel, slackUserSettings) => {
                            try {
                                if (channel) {
                                    await postSlackDefaultUserChannel(
                                        slackUserSettings.teamId,
                                        slackUserSettings.teamType,
                                        channel.code,
                                        channel.label,
                                        channel.isPrivate
                                    );
                                } else {
                                    await deleteSlackUserDefaultChannel();
                                }
                                track(ClientEventType.UserSelectedSlackDefaultChannel, {
                                    Action: !!channel ? 'setting-channel' : 'removing-channel',
                                    IsPrivate: channel?.isPrivate,
                                });
                            } catch (e) {
                                ClientLogger.error('failed to update default slack channel', {
                                    spinachUserId: user.spinachUserId,
                                });
                                setToastText('Try again later');
                            }
                        }}
                    />
                </Row>
                {user.isSlackHuddleInAppConnectionEnabled ? (
                    <Row>
                        <BodyRegularOnboard style={{ display: 'flex', alignItems: 'center', paddingRight: '5px' }}>
                            Want us to summarize your huddles?
                        </BodyRegularOnboard>
                        <SecondaryButton
                            title="Enable here"
                            onClick={() => {
                                const slackHuddleConfigureButton = document.getElementById(
                                    ElementId.AISettingsSlackHuddleConfigureButton
                                );
                                if (slackHuddleConfigureButton) {
                                    slackHuddleConfigureButton.scrollIntoView({ behavior: 'smooth' });
                                    slackHuddleConfigureButton.focus();
                                }
                            }}
                        />
                    </Row>
                ) : null}
            </Column>
        </Row>
    );
}

export function SlackConnectedSection({ showDefaultChannelSelection }: { showDefaultChannelSelection: boolean }) {
    return (
        <>
            <Column>{showDefaultChannelSelection ? <SlackDefaultChannelSection /> : <></>}</Column>
        </>
    );
}

export function SlackIntegrationsSection({
    setModalKind,
    hoverRowProps,
    showDefaultChannelSelection = true,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    hoverRowProps: UseHoverRowProps;
    showDefaultChannelSelection?: boolean;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const startDetection = useIntegrationDetection();
    const { slackState } = useGlobalSlack();

    const { getHoverableRowProps, hoveredRow } = hoverRowProps;

    return (
        <Row>
            <Column>
                <Row vCenter {...getHoverableRowProps(SpinachIntegration.Slack)}>
                    <Row vCenter style={{ alignSelf: 'start', width: 'unset' }}>
                        <SlackLogo style={{ minWidth: '24px', width: '24px', height: '24px', marginRight: '15px' }} />
                        <Label style={{ display: 'flex', alignItems: 'center' }}>
                            Slack
                            {user.isAuthedForSlack ? (
                                <ConnectedRowContents
                                    label={''}
                                    isSettingsVisible={hoveredRow === SpinachIntegration.Slack}
                                    onSettingsClick={() => {
                                        setModalKind(IntegrationSettingsModalKind.SettingsSlack);
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: `Open Remove Slack Modal`,
                                        });
                                    }}
                                />
                            ) : (
                                <>
                                    <BootstrapTooltip
                                        interactive
                                        placement="top"
                                        PopperProps={{
                                            style: {
                                                border: `1px ${lightTheme.neutrals.grayDark} solid`,
                                                width: '50vh',
                                            },
                                        }}
                                        arrowStyle={{
                                            border: `1px ${lightTheme.neutrals.grayDark} solid`,
                                            backgroundColor: lightTheme.neutrals.white,
                                            color: lightTheme.neutrals.white,
                                        }}
                                        tooltipStyle={{
                                            maxWidth: 'unset',
                                            padding: '25px',
                                            margin: 'unset',
                                            backgroundColor: lightTheme.neutrals.white,
                                            width: 'calc(100%-10px)',
                                        }}
                                        title={
                                            <>
                                                <Row style={{ width: 'unset' }} centered>
                                                    <img
                                                        src={SlackSummaryExample}
                                                        style={{
                                                            maxWidth: '350px',
                                                            maxHeight: '350px',
                                                            flexGrow: 1,
                                                        }}
                                                    />
                                                </Row>
                                                <Spacing factor={1 / 2} />

                                                <BodyRegularOnboard style={{ textAlign: 'center' }}>
                                                    Keep everyone in the loop. Automatically get summaries delivered to
                                                    your selected channel.
                                                </BodyRegularOnboard>
                                            </>
                                        }
                                    >
                                        <InfoOutlined
                                            style={{ marginLeft: '5px', color: lightTheme.primary.greenLight }}
                                            htmlColor={lightTheme.primary.greenLight}
                                        />
                                    </BootstrapTooltip>
                                </>
                            )}
                        </Label>
                    </Row>
                    {!user.isAuthedForSlack ? (
                        <Column style={{ alignItems: 'start' }}>
                            <OutlinedButton
                                title={'Connect'}
                                onClick={() => {
                                    startDetection();
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: `Slack Connect`,
                                    });
                                    URLUtil.openURL(slackState.installUrl);
                                }}
                                size={ButtonSize.Mini}
                                endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                                labelStyles={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            />
                        </Column>
                    ) : (
                        <SlackConnectedSection showDefaultChannelSelection={showDefaultChannelSelection} />
                    )}
                </Row>
            </Column>
        </Row>
    );
}

export function EmailRecipientSection() {
    const track = useExperienceTracking();
    const [user, setUser] = useGlobalAuthedUser();
    const { setActiveSection, setToastText } = useGlobalAiDashboard();
    const { fetch } = useStoredSeriesListFetcher({ recurringOnly: false });

    const isEmailInviterSummaryInviterOnly = useEmailSummaryToInviterOnly();
    const hasProFeatures = useProFeatures();
    const navigateToUpgrade = useNavigateToUpgrade();
    return (
        <Column style={{}}>
            <Row vCenter>
                <EmailOutlined style={{ fontSize: '25px', marginRight: '15px' }} />
                <Label style={{ width: 'auto' }}>Summary access</Label>
            </Row>
            <BodySmallOnboard>
                {hasProFeatures || isEmailInviterSummaryInviterOnly ? (
                    <Row>
                        <span style={{ width: '45px' }} />
                        <Column>
                            <Row vCenter>
                                <BodyRegularOnboard>Dashboard and Emails:</BodyRegularOnboard>
                                <EmailOptions
                                    isEmailingIcpOnlySetting={user.metadata.isEmailingIcpOnly}
                                    onSelect={async (isEmailingIcpOnly, emailDistributionConfig) => {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: `Selected Email Recipients Option (Integration Section)`,
                                        });

                                        setToastText(
                                            'Your email recipients settings have been applied to all your existing Spinach meetings'
                                        );

                                        await new Promise((resolve) => setTimeout(resolve, 1000));
                                        try {
                                            await patchUser({
                                                metadata: {
                                                    isEmailingIcpOnly,
                                                    emailDistributionConfig,
                                                },
                                            });

                                            await patchAllSeriesSettings({
                                                isEmailingIcpOnly,
                                                emailDistributionConfig,
                                            });

                                            const [updatedUser] = await Promise.all([getUser(), fetch()]);

                                            if (updatedUser.user) {
                                                setUser(updatedUser.user);
                                            }
                                        } catch (error: any) {
                                            ClientLogger.error('Failed to change email recipient settings', {
                                                spinachUserId: user.spinachUserId,
                                                isEmailingIcpOnly,
                                                errorMessage: error.message,
                                            });
                                            setToastText('Something went wrong when updating your email settings.');
                                        }
                                    }}
                                />
                            </Row>
                            <BodyRegularOnboard>
                                <i>
                                    You can change this for individual series on the{' '}
                                    <SecondaryButton
                                        title="Meetings"
                                        labelStyles={{ fontStyle: 'italic', fontWeight: 400 }}
                                        color={lightTheme.primary.midnight}
                                        onClick={() => {
                                            setActiveSection(DashboardSection.Meetings);
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: 'Go to Change Series Email Settings',
                                            });
                                        }}
                                        containerStyles={{ display: 'inline-flex' }}
                                    />{' '}
                                    page
                                </i>
                            </BodyRegularOnboard>
                        </Column>
                    </Row>
                ) : (
                    <Row>
                        <span style={{ width: '45px' }} />
                        <Column>
                            <BodyRegularOnboard>Everyone on the invite receives the summary.</BodyRegularOnboard>
                            <BodyRegularOnboard>
                                {' '}
                                <Anchor
                                    onClick={() => {
                                        navigateToUpgrade('Click Upgrade Email Options (Integrations Section)');
                                    }}
                                >
                                    Upgrade to Pro
                                </Anchor>{' '}
                                to change the recipients.
                            </BodyRegularOnboard>
                        </Column>
                    </Row>
                )}
            </BodySmallOnboard>
        </Column>
    );
}

/** @NOTE just enabled for morning briefing for now */
function MorningBriefIntegrationSection() {
    const [user] = useGlobalAuthedUser();
    const { setToastText } = useGlobalAiDashboard();
    const [globalSpinachWorkflows, setGlobalSpinachWorkflows] = useGlobalSpinachWorkflows();

    if (!user.isEnabledForMorningBriefing) {
        return <></>;
    }

    const morningBriefWorkflow = globalSpinachWorkflows?.find((workflow) =>
        workflow.actions.some((action) => action.kind === SpinachWorkflowActionKind.MorningBriefing)
    );

    return (
        <Column style={{}}>
            <Row vCenter>
                <FreeBreakfast style={{ fontSize: '25px', marginRight: '15px' }} />
                <Label style={{ width: 'auto' }}>Morning Brief</Label>
            </Row>
            <BodySmallOnboard>
                <Row>
                    <span style={{ width: '45px' }} />
                    <BodyRegularOnboard>
                        <i>Receive a morning brief with your calendar events for the day</i>
                    </BodyRegularOnboard>
                </Row>
            </BodySmallOnboard>
            <Spacing factor={1 / 3} />
            <Row vCenter>
                <SpinachSwitch
                    checked={!!morningBriefWorkflow?.isActive}
                    onChange={async () => {
                        const updatedWorkflow = await toggleMorningBriefWorkflow({
                            isActive: !morningBriefWorkflow?.isActive,
                            timezone: TimeUtils.getTimezoneRegion(),
                        });
                        if (updatedWorkflow) {
                            setToastText(
                                `Your morning brief has been ${updatedWorkflow.isActive ? 'enabled' : 'disabled'}`
                            );
                        } else {
                            setToastText('Something went wrong when updating your morning brief');
                        }

                        if (updatedWorkflow && !morningBriefWorkflow) {
                            setGlobalSpinachWorkflows([...globalSpinachWorkflows, updatedWorkflow]);
                        } else if (updatedWorkflow) {
                            setGlobalSpinachWorkflows(
                                globalSpinachWorkflows.map((workflow) => {
                                    if (workflow.id === updatedWorkflow?.id) {
                                        return updatedWorkflow;
                                    }
                                    return workflow;
                                })
                            );
                        }
                    }}
                />
                <Spacing factor={1 / 3} horizontal />
                <BodyRegularOnboard>Send morning brief every morning</BodyRegularOnboard>
            </Row>
        </Column>
    );
}

export function CommunicationsIntegrationSection({
    setModalKind,
    hoverRowProps,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    hoverRowProps: UseHoverRowProps;
}): JSX.Element {
    return (
        <>
            <Row>
                <HeaderThree>Communications</HeaderThree>
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>How do we communicate with you?</BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 2} />
            <EmailRecipientSection />
            <Spacing factor={1 / 2} />
            <SlackIntegrationsSection setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
            <Spacing factor={1 / 2} />
            <MorningBriefIntegrationSection />
        </>
    );
}
