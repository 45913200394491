import { Box, CircularProgress, Divider, TextField } from '@material-ui/core';
import { InfoOutlined, InfoRounded, Lock } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import DOMPurify from 'dompurify';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { DashboardSection } from '@spinach-shared/constants';
import { demoBotId, getDemoData } from '@spinach-shared/constants';
import {
    AiHistoryUserAccessKind,
    ClientAiHistory,
    ClientEventType,
    ClientUsersWithAiHistoryAccess,
    FeatureToggle,
    SummaryJson,
    TranscriptData,
    WebUrlQuery,
} from '@spinach-shared/types';
import { formatTime, getDemoSelection } from '@spinach-shared/utils';

import {
    getAiSummaryJson,
    getAskSpinachRealtimeConfig,
    getUserEmailsWithHistoryAccess,
    patchUser,
} from '../../../../apis';
import { getAiHistories, getAiSummaryHtml, getAiTranscript } from '../../../../apis/getAiHistories';
import { getMedia } from '../../../../apis/getMedia';
import { GlobalModal } from '../../../../atoms';
import {
    useAskSpinachEnabledQueries,
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useGlobalModal,
    useIsTemporaryLinkToAsyncVideo,
    usePrevious,
    useProFeatures,
    useWindowSize,
} from '../../../../hooks';
import { BodyRegular, BodyRegularOnboard, lightTheme } from '../../../../styles';
import { ButtonProps, SetValue } from '../../../../types';
import { URLUtil, copyTextToClipboard, withContentMasking } from '../../../../utils';
import { Anchor, Badge, Column, Hairline, Row, Spacing } from '../../../common';
import { ConfirmDeleteMeetingModal } from '../../../common/modals';
import { ScrollArea } from '../../../stand-up';
import SecondaryButton from '../../../stand-up/SecondaryButton';
import { useNavigateToUpgrade } from '../../hooks/useNavigateToUpgrade';
import { GlobalSearch } from '../globalSearch/GlobalSearch';
import { MeetingSelection } from '../types';
import { ActionsSectionContainer } from './ActionsSection';
import { AskSpinach, AskSpinachFrom } from './AskSpinach';
import { Media, MeetingMedia } from './MediaComponent';
import { MeetingChapters } from './MeetingChapters';
import { ShareSummaryRow } from './ShareSummaryRow';

const SELECTION_CODE_DELIMITER = '::';

const CustomScrollArea = styled(ScrollArea)`
    padding: unset;
    scrollbar-width: none;
`;

export const getBlurbId = (index: number) => `blurb-${index}`;

export function getHistorySelectionCode(seriesId: string, botId: string): string {
    return `${seriesId}${SELECTION_CODE_DELIMITER}${botId}`;
}

function getHistorySelectionCodeComponents(historySelectionCode: string): { seriesId: string; botId: string } {
    const [seriesId, botId] = historySelectionCode.split(SELECTION_CODE_DELIMITER);
    return { seriesId, botId };
}

const useFetchUsersWithHistoryAccess = ({
    botId,
}: {
    botId?: string;
}): [ClientUsersWithAiHistoryAccess[] | null, SetValue<ClientUsersWithAiHistoryAccess[] | null>] => {
    const [usersWithHistoryAccess, setUsersWithHistoryAccess] = useState<ClientUsersWithAiHistoryAccess[] | null>(null);
    const [globalModal] = useGlobalModal();
    const prevGlobalModal = usePrevious(globalModal);
    const prevBotId = usePrevious(botId);
    const [user] = useGlobalAuthedUser();
    useEffect(() => {
        const fetch = async (botId: string) => {
            // the demo meeting is hardcoded so there is nothing to fetch
            if (botId === demoBotId) {
                setUsersWithHistoryAccess([
                    {
                        email: user.email,
                        kind: AiHistoryUserAccessKind.Shared,
                    },
                ]);
                return;
            }
            const usersWithHistoryAccessResponse = await getUserEmailsWithHistoryAccess({ botId });
            if (usersWithHistoryAccessResponse?.usersWithHistoryAccess) {
                setUsersWithHistoryAccess(usersWithHistoryAccessResponse.usersWithHistoryAccess);
            } else {
                setUsersWithHistoryAccess(null);
            }
        };
        if (botId && (botId !== prevBotId || (prevGlobalModal === GlobalModal.ShareAiHistory && !globalModal))) {
            fetch(botId);
        }
    }, [globalModal, botId]);
    return [usersWithHistoryAccess, setUsersWithHistoryAccess];
};

export function TranscriptsSection(): JSX.Element {
    const [user, setUser] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const [searchParams, setSearchParams] = useSearchParams();
    const { width } = useWindowSize();
    const { activeSection, setActiveSection, setToastText, setIsOnDemoNotes } = useGlobalAiDashboard();

    const [results, setResults] = useState<TranscriptData | null>(null);
    const [minimalTranscriptResponse, setMinimalTranscriptResponse] = useState<string[] | null>(null);
    const [selection, setSelection] = useState<MeetingSelection | null>(null);
    const [options, setOptions] = useState<MeetingSelection[]>([]);
    const [media, setMedia] = useState<Media>();

    const [summaryHtml, setSummaryHtml] = useState('');
    const cleanSummaryHtml = React.useMemo(
        () => DOMPurify.sanitize(summaryHtml, { USE_PROFILES: { html: true }, ADD_ATTR: ['target'] }),
        [summaryHtml]
    );
    const [summaryJson, setSummaryJson] = useState<SummaryJson | null>(null);

    const [isLoadingTranscript, setIsLoadingTranscript] = useState(false);
    const [isLoadingHistories, setIsLoadingHistories] = useState(false);
    const [isLoadingSummaryJson, setIsLoadingSummaryJson] = useState(false);

    const shouldShowTicketSuggestions = user.isActionItemTicketCreationEnabled;
    const isMeetingChaptersVisibleOnDashboard = user.isMeetingChaptersVisibleOnDashboard;

    const isLoading =
        isLoadingHistories || isLoadingTranscript || (isLoadingSummaryJson && shouldShowTicketSuggestions);

    const hasProFeatures = useProFeatures();
    const freeOptionsCodes = options.slice(0, 3).map((option) => option.code);
    const unlimitedMeetingHistoryForFreeUsers = user.isEnabledForUnlimitedMeetingHistoryForFreeUsers;
    const isSelectedTranscriptLimited =
        !hasProFeatures &&
        selection &&
        !freeOptionsCodes.includes(selection.code) &&
        !unlimitedMeetingHistoryForFreeUsers;
    const navigateToUpgrade = useNavigateToUpgrade();
    const [botId, setBotId] = useState<string | undefined>(undefined);
    const [seriesId, setSeriesId] = useState<string | undefined>(undefined);

    const [usersWithHistoryAccess] = useFetchUsersWithHistoryAccess({ botId });
    const showVideoSummayLink = useIsTemporaryLinkToAsyncVideo();

    async function fetchSelectedResults(selection: { code: string; label: string }) {
        const { seriesId, botId } = getHistorySelectionCodeComponents(selection.code);
        await fetchSelectedResultsByIds(seriesId, botId);
    }

    async function fetchSelectedResultsByIds(seriesId: string, botId: string) {
        setIsLoadingTranscript(true);
        setIsLoadingSummaryJson(true);
        setMedia({ loading: true });
        setSummaryJson(null);
        setResults(null);
        setSummaryHtml('');
        setMinimalTranscriptResponse(null);
        setSecond(0);
        const isDemo = botId === demoBotId;
        setIsOnDemoNotes(isDemo);
        const [transcriptResponse, askSpinachConfig, summaryHtmlResponse, summaryJsonResponse, media] = isDemo
            ? getDemoData({ username: user.firstName })
            : await Promise.all([
                  getAiTranscript({ seriesId, botId }),
                  getAskSpinachRealtimeConfig({ seriesId, botId }),
                  getAiSummaryHtml({ seriesId, botId }),
                  shouldShowTicketSuggestions || isMeetingChaptersVisibleOnDashboard
                      ? getAiSummaryJson(botId, false)
                      : undefined,
                  getMedia({ botId }),
              ]);
        setIsLoadingTranscript(false);
        setIsLoadingSummaryJson(false);
        setBotId(botId);
        setSeriesId(seriesId);
        if (summaryJsonResponse && 'summaryJson' in summaryJsonResponse) {
            setSummaryJson(summaryJsonResponse.summaryJson);
        } else {
            setSummaryJson(null);
        }

        if (askSpinachConfig?.minimalTranscript) {
            setMinimalTranscriptResponse(askSpinachConfig.minimalTranscript);
        } else {
            setMinimalTranscriptResponse(null);
        }

        if (transcriptResponse) {
            setResults(transcriptResponse);
        } else {
            setResults(null);
        }

        if (summaryHtmlResponse) {
            setSummaryHtml(summaryHtmlResponse);
        } else {
            setSummaryHtml('');
        }

        if (media) {
            setMedia(media);
        } else {
            setMedia({ error: true });
        }
    }

    function onDownloadVideo() {
        if (!hasProFeatures) {
            return;
        }
        if (!media || !('recordingUrl' in media)) {
            return;
        }
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Download Video',
        });
        URLUtil.openURL(media.recordingUrl!);
    }

    function onCopyTranscript() {
        if (!selection) {
            return;
        }

        if (!results) {
            return;
        }
        if (results.isTranscriptDisabled) {
            return;
        }

        const { seriesId, botId } = getHistorySelectionCodeComponents(selection.code);

        copyTextToClipboard(
            results.transcriptLineData
                .map((t) => `${formatTime(t.timestamp)} ${t.speaker}: ${t.statement}`)
                .join('\n\n')
        );
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Copy Transcript Button',
            BotId: botId,
            SeriesId: seriesId,
        });

        setToastText('Transcript copied to clipboard');
    }

    function onCopySummary() {
        if (!selection) {
            return;
        }

        if (!summaryHtml) {
            return;
        }

        const { seriesId, botId } = getHistorySelectionCodeComponents(selection.code);

        copyTextToClipboard(summaryHtml);
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Copy Summary Button',
            BotId: botId,
            SeriesId: seriesId,
        });

        setToastText('Summary copied to clipboard');
    }

    const readingSummaryLengthThresholdChars = 100;
    const readingSummaryMinimumTimeMs = 5000;
    useEffect(() => {
        if (
            activeSection === DashboardSection.Summaries &&
            results &&
            !results.isTranscriptDisabled &&
            results.transcriptLineData.length &&
            summaryHtml &&
            summaryHtml.length > readingSummaryLengthThresholdChars &&
            !user.metadata.hasReadAsummary
        ) {
            const timeout = setTimeout(async () => {
                const { user: updatedUser } = await patchUser({
                    metadata: { hasReadAsummary: true },
                });
                if (updatedUser) {
                    setUser(updatedUser);
                }
            }, readingSummaryMinimumTimeMs);
            return () => clearTimeout(timeout);
        }
    }, [activeSection, results, setUser, summaryHtml, user.metadata.hasReadAsummary]);

    useEffect(() => {
        if (selection?.isPartialCappedSummary && !user.hasProFeatures && activeSection !== DashboardSection.Summaries) {
            setActiveSection(DashboardSection.Summaries);
        }
    }, [activeSection, selection?.isPartialCappedSummary, setActiveSection, user.hasProFeatures]);

    useEffect(() => {
        async function exec() {
            let updateContentUponFetch = true;
            const deepSeriesId = searchParams.get(WebUrlQuery.SeriesId);
            const deepBotId = searchParams.get(WebUrlQuery.BotId);

            setIsLoadingHistories(true);

            const mapHistoryToSelection = (h: ClientAiHistory): MeetingSelection => ({
                botId: h.botId,
                seriesId: h.seriesId,
                code: getHistorySelectionCode(h.seriesId, h.botId),
                label: `${h.meetingTitle}`,
                verbalDate: moment(h.createdAt).format('dddd, MMM Do YYYY'),
                title: h.meetingTitle,
                createdAt: new Date(h.createdAt),
                isPartialCappedSummary: h.isPartialCappedSummary,
                icpId: h.icpId,
                ticket: h.ticket,
                isEdited: h.isEdited,
                isEnabledForEditSummary: h.isEnabledForEditSummary,
            });

            if (deepSeriesId && deepBotId) {
                const initialHistory = await getAiHistories({ botId: deepBotId });
                if (initialHistory && initialHistory.length > 0) {
                    const initialMapped = mapHistoryToSelection(initialHistory[0]);
                    setSelection(initialMapped);
                    await fetchSelectedResults(initialMapped);
                    updateContentUponFetch = false;
                }
            } else if (!deepSeriesId && !deepBotId && user.isEnabledForPersistentDemoNotes) {
                // If no deep link and persistent demo notes enabled, load demo notes immediately
                const demoSelection = getDemoSelection({ icpId: user._id });
                setSelection(demoSelection);
                await fetchSelectedResults(demoSelection);
                updateContentUponFetch = false;
            } else if (!deepSeriesId && !deepBotId) {
                const initialHistory = await getAiHistories({ limit: 1 });
                if (initialHistory && initialHistory.length > 0) {
                    const initialMapped = mapHistoryToSelection(initialHistory[0]);
                    setSelection(initialMapped);
                    await fetchSelectedResults(initialMapped);
                    updateContentUponFetch = false;
                }
            }

            const histories = await getAiHistories();

            setIsLoadingHistories(false);

            if (!histories) {
                return;
            }

            const hasLimitedHistory = histories.length >= 1 && histories.length < 5;
            const forceDemo = searchParams.get('demo') === '1' || histories.length === 0;
            const mapped: MeetingSelection[] = histories.map(mapHistoryToSelection);

            // Add demo notes at the top if feature flag enabled or other conditions met
            if (user.isEnabledForPersistentDemoNotes || forceDemo || deepBotId === demoBotId || hasLimitedHistory) {
                if (hasLimitedHistory) {
                    mapped.push(getDemoSelection({ icpId: user._id }));
                } else {
                    mapped.unshift(getDemoSelection({ icpId: user._id }));
                }
            }

            setOptions(mapped);

            if (forceDemo) {
                setSelection(getDemoSelection({ icpId: user._id }));
                await fetchSelectedResults(getDemoSelection({ icpId: user._id }));
                return;
            }

            track(ClientEventType.AIDashboardActivity, {
                Activity: 'Transcripts Loaded',
                TranscriptCount: mapped.length,
            });

            const deepSelection =
                !!deepBotId && !!deepSeriesId
                    ? mapped.find((option) => option.code === getHistorySelectionCode(deepSeriesId, deepBotId))
                    : null;

            const isUnpaidTranscript =
                deepSelection?.code &&
                !hasProFeatures &&
                !mapped
                    .slice(0, 3)
                    .map((option) => option.code)
                    .includes(deepSelection.code);

            if (isUnpaidTranscript && !user.isEnabledForUnlimitedMeetingHistoryForFreeUsers) {
                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'Loaded Deep-Linked Limited Transcript',
                });
                setSelection(deepSelection);
                setResults(null);
            } else if (
                deepSelection &&
                deepSeriesId &&
                (!!user.getSeriesById(deepSeriesId) || user.hasAdminUserContentAccess)
            ) {
                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'Loaded Deep-Linked Transcript',
                });

                if (updateContentUponFetch) {
                    setSelection(deepSelection);
                    await fetchSelectedResults(deepSelection);
                }
            } else if (mapped[0]) {
                if (updateContentUponFetch) {
                    setSelection(mapped[0]);
                    await fetchSelectedResults(mapped[0]);
                }
            }
        }
        exec();
    }, []);

    // Clear the navigation state once used.
    useEffect(() => {
        if (selection && searchParams.get(WebUrlQuery.SeriesId)) {
            setSearchParams((searchParams) => {
                searchParams.delete(WebUrlQuery.SeriesId);
                searchParams.delete(WebUrlQuery.BotId);
                searchParams.delete(WebUrlQuery.EditSuccess);
                return searchParams;
            });
        }
    }, [searchParams, setSearchParams, selection]);

    const [currentSecond, setSecond] = useState(0);
    const videoRef = useRef<HTMLVideoElement>(null);

    const stackVideoLayout =
        width < 1200 || !('recordingUrl' in (media || {})) || 'isVideoRecordingDisabled' in (media || {});

    useEffect(() => {
        if (!stackVideoLayout && activeSection === DashboardSection.AskSpinach) {
            setActiveSection(DashboardSection.Summaries);
        }
    }, [stackVideoLayout, activeSection, setActiveSection]);

    const askSpinachConfig = useAskSpinachEnabledQueries();
    const askSpinachEnabled = Object.keys(askSpinachConfig).length > 0;
    const [searchVisible, setSearchVisible] = useState(false);

    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const tooSmallForSearch = width < 1000;
    const isGlobalSearchEnabled =
        !tooSmallForSearch && user.featureToggles[FeatureToggle.GlobalSearch] && botId !== demoBotId;
    useEffect(() => {
        if (isGlobalSearchEnabled) {
            const handleKeyDown = (event: KeyboardEvent) => {
                if (
                    event.key === 'k' &&
                    (isMac ? event.metaKey : event.ctrlKey) &&
                    !searchVisible &&
                    isGlobalSearchEnabled
                ) {
                    event.preventDefault();
                    setSearchVisible(true);
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Search Shortcut',
                    });
                }
            };
            document.addEventListener('keydown', handleKeyDown);

            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [isMac, searchVisible, isGlobalSearchEnabled, track]);

    const lockedTabButtonProps: Partial<ButtonProps> = user.hasProFeatures
        ? {}
        : {
              preIcon: selection?.isPartialCappedSummary ? (
                  <Lock fontSize={'small'} htmlColor={lightTheme.tertiary.midnight} style={{ marginBottom: '-3px' }} />
              ) : undefined,
              disabled: !!selection?.isPartialCappedSummary,
          };

    const isShareAiHistoryEnabled = user.isShareAiHistoryEnabled;

    const isUnpublishedDraft = (option: MeetingSelection | null): boolean => {
        return !!option && !!option.isEnabledForEditSummary && !option.isEdited;
    };

    const getOptionLabelAndStatus = (option: MeetingSelection) => {
        const isLimited =
            !hasProFeatures && !freeOptionsCodes.includes(option.code) && !unlimitedMeetingHistoryForFreeUsers;

        return {
            label: isLimited ? `${option.label} (Spinach Pro Only)` : option.label,
            isLimited,
            isUnpublishedDraft: isUnpublishedDraft(option),
        };
    };

    return (
        <Box height="100%" display="flex" flexDirection="column">
            <Spacing factor={width > 800 ? 1 : 2} />
            <Row>
                {isGlobalSearchEnabled ? (
                    <Box
                        style={{
                            padding: 5,
                            cursor: 'pointer',
                            marginBottom: '20px',
                            marginRight: '40px',
                            borderBottom: '1px solid #B0B0B0',
                            minWidth: '60%',
                            width: '100%',
                            maxWidth: '600px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        onClick={() => {
                            setSearchVisible(!searchVisible);
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Search Icon',
                            });
                        }}
                    >
                        <SearchIcon style={{ color: '#A0A0A0' }} />

                        <span style={{ color: 'gray', marginLeft: 5 }}>
                            {`Search by keyword `}
                            {isMac ? `(⌘ + K)` : `(Ctrl + K)`}
                        </span>
                    </Box>
                ) : null}
            </Row>
            <Row vCenter>
                <Autocomplete
                    id="summaries-and-transcripts-id-selection"
                    options={options}
                    groupBy={(option) => moment(option.createdAt).format('YYYY/MM/DD')}
                    renderGroup={(params) => {
                        return (
                            <Column style={{ marginTop: '10px' }}>
                                <BodyRegular
                                    style={{
                                        fontWeight: 700,
                                        fontSize: '12px',
                                        marginLeft: '15px',
                                    }}
                                >
                                    {moment(params.group).format('dddd, MMMM D').toUpperCase()}
                                </BodyRegular>
                                {params.children}
                            </Column>
                        );
                    }}
                    renderOption={(option) => {
                        const { label, isUnpublishedDraft } = getOptionLabelAndStatus(option);
                        return (
                            <Row vCenter style={{ width: 'calc(100% - 30px)' }}>
                                <BodyRegularOnboard style={{ marginLeft: '10px' }}>{label}</BodyRegularOnboard>
                                {isUnpublishedDraft ? (
                                    <span style={{ marginLeft: '10px' }}>
                                        <Badge title="Draft" />
                                    </span>
                                ) : null}
                                {option.botId === demoBotId ? (
                                    <span style={{ marginLeft: '10px' }}>
                                        <Badge title="Sample" />
                                    </span>
                                ) : null}
                            </Row>
                        );
                    }}
                    getOptionLabel={(option: MeetingSelection) => getOptionLabelAndStatus(option).label}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Choose a meeting"
                            inputProps={{
                                ...params.inputProps,
                                style: {
                                    fontWeight: 500,
                                },
                            }}
                            InputProps={{
                                ...params.InputProps,
                                style: {
                                    fontWeight: 500,
                                    paddingTop: '10px',
                                    paddingBottom: '10px',
                                },
                                startAdornment: (
                                    <>
                                        {params.InputProps.startAdornment}
                                        {isUnpublishedDraft(selection) ? (
                                            <span style={{ marginLeft: '10px', marginRight: '10px' }}>
                                                <Badge title="Draft" />
                                            </span>
                                        ) : null}
                                        {selection?.botId === demoBotId ? (
                                            <span style={{ marginLeft: '10px', marginRight: '10px' }}>
                                                <Badge title="Sample" />
                                            </span>
                                        ) : null}
                                    </>
                                ),
                            }}
                        />
                    )}
                    value={selection}
                    size="small"
                    style={{
                        minWidth: '60%',
                        marginBottom: '10px',
                        width: '100%',
                        maxWidth: '600px',
                    }}
                    autoComplete
                    onChange={async (event, selection) => {
                        setSelection(selection);

                        if (!selection) {
                            setSelection(null);
                            setResults(null);
                            setSummaryHtml('');
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Cleared Transcript Selection',
                            });
                            return;
                        }

                        if (
                            !hasProFeatures &&
                            !freeOptionsCodes.includes(selection.code) &&
                            !unlimitedMeetingHistoryForFreeUsers
                        ) {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Selected Limited Transcript to Open',
                            });
                            setResults(null);
                            setSummaryHtml('');
                            return;
                        }

                        await fetchSelectedResults(selection);

                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Selected Transcript to Open',
                        });
                    }}
                    {...withContentMasking()}
                />
                {isLoading ? (
                    <CircularProgress
                        size={'30px'}
                        style={{ marginLeft: '10px', marginBottom: '15px', color: lightTheme.primary.orangeDark }}
                    />
                ) : (
                    <span style={{ width: '40px' }} />
                )}
            </Row>
            {selection?.botId === demoBotId ? (
                <Box
                    style={{
                        backgroundColor: lightTheme.tertiary.greenLight,
                        padding: '12px',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: '10px',
                        marginRight: '30px',
                    }}
                    my={1}
                >
                    <InfoRounded style={{ color: lightTheme.primary.green, marginRight: '10px' }} />
                    <BodyRegular style={{ color: lightTheme.primary.green, fontWeight: 'bold', fontSize: '16px' }}>
                        This is an example meeting
                    </BodyRegular>
                </Box>
            ) : null}
            {isUnpublishedDraft(selection) ? (
                <Row
                    style={{
                        backgroundColor: '#EEF9F6',
                        borderRadius: '4px',
                        paddingLeft: '5px',
                        paddingBottom: '5px',
                        marginBottom: '10px',
                        maxWidth: '600px',
                    }}
                >
                    <Row style={{ alignItems: 'center' }}>
                        <InfoOutlined style={{ color: '#4D9F8A', marginTop: '4px', marginRight: '10px' }} />
                        <BodyRegularOnboard style={{ fontWeight: 400, marginTop: '4px' }}>
                            This summary is an unpublished draft. Go to{' '}
                            <div style={{ display: 'inline-block' }}>
                                <SecondaryButton
                                    title="Drafts"
                                    onClick={() => {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Go to Drafts from Histories',
                                        });
                                        setActiveSection(DashboardSection.Drafts);
                                    }}
                                />
                            </div>{' '}
                            to edit and publish it.
                        </BodyRegularOnboard>
                    </Row>
                </Row>
            ) : null}
            {options && searchVisible ? (
                <GlobalSearch
                    meetings={options}
                    hideRequestCallback={() => {
                        setSearchVisible(false);
                    }}
                    onMeetingSelected={async (botId, section) => {
                        const selectedOption = options.find((option) => option.botId === botId);
                        if (selectedOption) {
                            setSelection(selectedOption);
                            setSearchVisible(false);
                            setActiveSection(section);
                            await fetchSelectedResultsByIds(selectedOption.seriesId, selectedOption.botId);
                        }
                    }}
                />
            ) : undefined}
            <Box display="flex" flexDirection={stackVideoLayout ? 'column' : 'row'} overflow="clip">
                {botId && selection && ('recordingUrl' in (media || {}) || 'googleDriveUrl' in (media || {})) ? (
                    <MeetingMedia
                        media={media}
                        stackVideoLayout={stackVideoLayout}
                        onDownloadVideo={onDownloadVideo}
                        meetingSelection={selection}
                        freeOptionsCodes={freeOptionsCodes}
                        videoRef={videoRef}
                        minimalTranscriptResponse={minimalTranscriptResponse}
                        transcript={results ?? undefined}
                        dashboardSection={activeSection}
                        onTimeUpdate={(timestamp) => setSecond(timestamp)}
                        botId={botId}
                        seriesId={seriesId}
                    />
                ) : (
                    <></>
                )}
                <Box
                    display="flex"
                    flexDirection="column"
                    flex={stackVideoLayout ? undefined : 6}
                    mt={stackVideoLayout ? '10px' : '0px'}
                    overflow="clip"
                >
                    <CustomScrollArea
                        horizontal
                        style={{
                            flexDirection: 'row',
                            minWidth: 'calc(60% + 40px)',
                        }}
                    >
                        {(shouldShowTicketSuggestions && summaryJson) ||
                        (selection?.isPartialCappedSummary && !user.hasProFeatures) ? (
                            <SecondaryButton
                                title="Actions"
                                buttonStyles={{ textDecoration: 'none', marginRight: '30px' }}
                                labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                                color={
                                    activeSection === DashboardSection.Actions
                                        ? lightTheme.primary.greenLight
                                        : lightTheme.primary.midnight
                                }
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Action Tab' });
                                    setActiveSection(DashboardSection.Actions);
                                }}
                                {...lockedTabButtonProps}
                            />
                        ) : (
                            <></>
                        )}
                        <SecondaryButton
                            title="Summary"
                            buttonStyles={{ textDecoration: 'none', marginRight: '30px' }}
                            labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                            color={
                                activeSection === DashboardSection.Summaries
                                    ? lightTheme.primary.greenLight
                                    : lightTheme.primary.midnight
                            }
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, { ClickedOn: 'Summary Tab' });
                                setActiveSection(DashboardSection.Summaries);
                            }}
                        />
                        {isMeetingChaptersVisibleOnDashboard &&
                        summaryJson?.meetingChapters?.length &&
                        summaryJson?.meetingChapters?.length > 0 ? (
                            <SecondaryButton
                                title="Chapters"
                                buttonStyles={{ textDecoration: 'none', marginRight: '30px' }}
                                labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                                color={
                                    activeSection === DashboardSection.Chapters
                                        ? lightTheme.primary.greenLight
                                        : lightTheme.primary.midnight
                                }
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Chapters Tab',
                                    });
                                    setActiveSection(DashboardSection.Chapters);
                                }}
                                {...lockedTabButtonProps}
                            />
                        ) : null}
                        {(!!results && !results.isTranscriptDisabled && results.transcriptLineData.length) ||
                        (selection?.isPartialCappedSummary && !user.hasProFeatures) ? (
                            <>
                                <SecondaryButton
                                    title="Transcript"
                                    buttonStyles={{ textDecoration: 'none' }}
                                    labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                                    color={
                                        activeSection === DashboardSection.Transcripts
                                            ? lightTheme.primary.greenLight
                                            : lightTheme.primary.midnight
                                    }
                                    onClick={() => {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Transcript Tab',
                                        });
                                        setActiveSection(DashboardSection.Transcripts);
                                    }}
                                />

                                {stackVideoLayout && askSpinachEnabled ? (
                                    <SecondaryButton
                                        title="Ask Spinach"
                                        buttonStyles={{ textDecoration: 'none', marginLeft: '30px' }}
                                        labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                                        color={
                                            activeSection === DashboardSection.AskSpinach
                                                ? lightTheme.primary.greenLight
                                                : lightTheme.primary.midnight
                                        }
                                        onClick={() => {
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: 'Ask Spinach Tab',
                                            });
                                            setActiveSection(DashboardSection.AskSpinach);
                                        }}
                                        {...lockedTabButtonProps}
                                    />
                                ) : (
                                    <></>
                                )}
                                {showVideoSummayLink ? (
                                    <SecondaryButton
                                        title="Video Summary"
                                        buttonStyles={{ textDecoration: 'none' }}
                                        labelStyles={{ fontSize: '18px', fontWeight: 700, marginLeft: '30px' }}
                                        color={lightTheme.primary.midnight}
                                        onClick={() => {
                                            window.location.href = `${window.location.origin}/ai/home?${WebUrlQuery.Section}=${DashboardSection.Video}&${WebUrlQuery.BotId}=${botId}&${WebUrlQuery.SeriesId}=${seriesId}`;
                                        }}
                                    />
                                ) : null}
                            </>
                        ) : null}
                        <Spacing factor={1 / 2} horizontal />
                        {botId && seriesId && usersWithHistoryAccess && isShareAiHistoryEnabled && selection ? (
                            <ShareSummaryRow
                                seriesId={seriesId}
                                meetingTitle={selection.title}
                                hostId={selection.icpId}
                                onCopyTranscript={onCopyTranscript}
                                shouldShowCopyTranscript={Boolean(
                                    results && !results.isTranscriptDisabled && results.transcriptLineData.length
                                )}
                                shouldShowDownloadVideo={Boolean(
                                    media && 'recordingUrl' in media && !!media.recordingUrl
                                )}
                                onCopySummary={onCopySummary}
                                botId={botId}
                                usersWithHistoryAccess={usersWithHistoryAccess}
                                onDownloadVideo={onDownloadVideo}
                            />
                        ) : (
                            <></>
                        )}
                        <span style={{ width: '40px' }} />
                    </CustomScrollArea>

                    <Divider style={{ marginTop: '10px' }} />
                    <Box flex={1} display="flex" flexDirection="column" overflow="clip">
                        {(!!results && Array.isArray(results) && results.length) || !!summaryHtml ? (
                            <>
                                <ScrollArea
                                    sidePadding={0}
                                    style={{
                                        paddingTop: '10px',
                                        width: '100%',
                                        overflowY: 'scroll',
                                    }}
                                >
                                    {activeSection === DashboardSection.Actions && selection ? (
                                        <ActionsSectionContainer
                                            summaryJson={summaryJson}
                                            setSummaryJson={setSummaryJson}
                                            botId={getHistorySelectionCodeComponents(selection.code).botId}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {botId && activeSection === DashboardSection.AskSpinach ? (
                                        <AskSpinach
                                            from={AskSpinachFrom.MeetingHistory}
                                            botId={botId}
                                            minimalTranscriptResponse={minimalTranscriptResponse}
                                            seriesId={seriesId}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {activeSection === DashboardSection.Summaries ? (
                                        <Column>
                                            {cleanSummaryHtml ? (
                                                <>
                                                    <div
                                                        {...withContentMasking()}
                                                        style={{ fontFamily: 'Gilroy' }}
                                                        dangerouslySetInnerHTML={{ __html: cleanSummaryHtml }}
                                                    />
                                                    {selection?.isPartialCappedSummary && !user.hasProFeatures ? (
                                                        <>
                                                            <Hairline />
                                                            <Row vCenter={true} centered={true}>
                                                                <Lock htmlColor={lightTheme.primary.midnight} />
                                                            </Row>
                                                            <Spacing factor={1 / 2} />
                                                            <BodyRegularOnboard style={{ textAlign: 'center' }}>
                                                                <b>
                                                                    This summary was processed without Spinach hours.{' '}
                                                                    <Anchor
                                                                        onClick={() => {
                                                                            navigateToUpgrade(
                                                                                'Upgrade to Pro From Partial Summary'
                                                                            );
                                                                        }}
                                                                    >
                                                                        Upgrading
                                                                    </Anchor>{' '}
                                                                    will resume processing for full meeting notes.
                                                                </b>
                                                            </BodyRegularOnboard>
                                                        </>
                                                    ) : null}
                                                </>
                                            ) : (
                                                <Row>
                                                    <BodyRegular>No summary found for this meeting.</BodyRegular>
                                                </Row>
                                            )}
                                        </Column>
                                    ) : null}
                                    {activeSection === DashboardSection.Transcripts ? (
                                        !results?.isTranscriptDisabled && results?.transcriptLineData ? (
                                            results.transcriptLineData.map((blurb, i) => {
                                                const isActive =
                                                    currentSecond >= blurb.timestamp &&
                                                    (results.transcriptLineData[i + 1] == undefined ||
                                                        currentSecond <= results.transcriptLineData[i + 1].timestamp);
                                                return (
                                                    <Column
                                                        key={i}
                                                        onClick={() => {
                                                            if (videoRef.current) {
                                                                videoRef.current.currentTime = blurb.timestamp + 0.3;
                                                                // play if not playing already
                                                                if (videoRef.current.paused) {
                                                                    videoRef.current.play();
                                                                }
                                                            }
                                                        }}
                                                        id={getBlurbId(i)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            marginBottom: '10px',
                                                            background: isActive
                                                                ? 'rgba(53, 162, 137, 0.5)'
                                                                : undefined,
                                                        }}
                                                    >
                                                        <Row>
                                                            <span style={{ color: 'gray', minWidth: '45px' }}>
                                                                {formatTime(blurb.timestamp)}
                                                            </span>
                                                            <b
                                                                style={{
                                                                    marginLeft: '5px',
                                                                    minWidth: '150px',
                                                                    marginRight: '5px',
                                                                }}
                                                                {...withContentMasking()}
                                                            >
                                                                {blurb.speaker}
                                                            </b>
                                                        </Row>
                                                        <Row
                                                            style={{ marginLeft: '50px', width: 'calc(100% -  50px)' }}
                                                            {...withContentMasking()}
                                                        >
                                                            {blurb.statement}
                                                        </Row>
                                                    </Column>
                                                );
                                            })
                                        ) : (
                                            <></>
                                        )
                                    ) : null}
                                    {activeSection === DashboardSection.Chapters ? (
                                        <MeetingChapters
                                            botId={botId}
                                            seriesId={seriesId}
                                            chapters={summaryJson?.meetingChapters}
                                            playAtSecond={(second) => {
                                                if (videoRef.current) {
                                                    videoRef.current.currentTime = second + 0.3;
                                                    // play if not playing already
                                                    if (videoRef.current.paused) {
                                                        videoRef.current.play();
                                                    }
                                                }
                                            }}
                                            currentTime={
                                                videoRef.current?.paused || videoRef.current?.ended
                                                    ? undefined
                                                    : currentSecond
                                            }
                                        />
                                    ) : null}
                                </ScrollArea>
                            </>
                        ) : !options.length && !isLoadingHistories ? (
                            <>
                                <Spacing factor={1 / 2} />
                                <Row>
                                    <BodyRegular>No summaries or transcripts available.</BodyRegular>
                                </Row>
                            </>
                        ) : isSelectedTranscriptLimited ? (
                            <>
                                <Spacing factor={1 / 2} />
                                <Row>
                                    <BodyRegular>
                                        You can access older summaries and transcripts by{' '}
                                        <Anchor
                                            onClick={() => {
                                                navigateToUpgrade('Click Upgrade to Pro (Opened Limited Transcript)');
                                            }}
                                        >
                                            Upgrading to Pro
                                        </Anchor>
                                        .
                                    </BodyRegular>
                                </Row>
                            </>
                        ) : (!results || (Array.isArray(results) && !results.length)) &&
                          !isLoadingTranscript &&
                          !!options.length &&
                          !!selection ? (
                            <>
                                <Spacing factor={1 / 2} />
                                <Row>
                                    <BodyRegular>No summary or transcript found for this meeting.</BodyRegular>
                                </Row>
                            </>
                        ) : null}
                    </Box>
                </Box>
            </Box>
            {selection ? <ConfirmDeleteMeetingModal selection={selection} /> : null}
        </Box>
    );
}
