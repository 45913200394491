import { useEffect, useState } from 'react';
import { RecoilState, useRecoilState } from 'recoil';
import { Socket } from 'socket.io-client';
import { io } from 'socket.io-client';

import { MILLIS_IN_SECONDS, SECONDS_IN_MINUTES } from '@spinach-shared/constants';
import { BaseWebSocketPayload, ClientSocketEvent, ISOString, SeriesUserMetadata } from '@spinach-shared/types';

import { useWindowInactivity } from '.';
import { SetValue } from '../types';
import { createWebsocketPayload } from '../utils/analytics';
import { getClientConfigValue } from '../utils/getClientConfigValue';
import { useGlobalAuthedUser } from './useGlobalUser';
import { useGlobalNullableStoredSeries } from './useSeriesData';

export enum SocketStatus {
    Disconnected = 'disconnected',
    Connected = 'connected',
    Reconnecting = 'reconnecting',
    Idle = 'idle',
    ManuallyClosed = 'manually-closed',
}

export type ConnectionEventMetadata<T extends BaseWebSocketPayload> = {
    event: ClientSocketEvent;
    payload: T;
    meetingId?: string;
    botId?: string;
};

export function useWebsocket<T extends BaseWebSocketPayload>(
    atom: RecoilState<Socket | null>,
    connectionEvent?: ConnectionEventMetadata<T>
): { socket: Socket | null; socketStatus: SocketStatus; resetSocketConnection: () => void } {
    const { event, payload, botId } = connectionEvent ?? {};
    const [statefulSocket, setSocket] = useRecoilState(atom);
    const [socketStatus, setSocketStatus] = useState<SocketStatus>(SocketStatus.Disconnected);
    const [socketInitTime, setSocketInitTime] = useState(new Date().toISOString());

    const { reconnect, reconnectionAttempts } = useCustomReconnection(
        statefulSocket,
        setSocketStatus,
        socketInitTime,
        connectionEvent
    );

    useEffect(() => {
        let isRetrying = false;

        // if the user changes series, we want to disconnect from the previous series connection and reconnect
        if (statefulSocket) {
            statefulSocket.disconnect();
        }

        const socket = io(getClientConfigValue('REACT_APP_WEBSOCKET_URL'), {
            withCredentials: true,
            reconnection: false,
        });

        setSocket(socket);

        socket.on('connect', () => {
            isRetrying = false;
            setSocketStatus(SocketStatus.Connected);
            if (event && payload) {
                socket.emit(
                    event,
                    createWebsocketPayload<T>({
                        ...payload,
                        reconnecting: false,
                        appVersion: getClientConfigValue('REACT_APP_APP_VERSION'),
                        botId,
                    })
                );
            }
        });

        socket.on('connect_error', () => {
            if (!isRetrying) {
                reconnect(reconnectionAttempts, socket);
                isRetrying = true;
            }
        });

        socket.on('disconnect', () => {
            if (document.hidden) {
                setSocketStatus(SocketStatus.Idle);
            } else {
                setSocketStatus(SocketStatus.Reconnecting);
            }
        });

        // If we leave the series without remounting, we want to disconnect from the series as well
        return () => {
            if (socket) {
                socket.disconnect();
                setSocketStatus(SocketStatus.Disconnected);
                setSocket(null);
            }
        };
    }, [payload?.seriesSlug, socketInitTime]);

    useSocketInactivity(statefulSocket, socketStatus, setSocketInitTime, setSocketStatus);

    return {
        socket: statefulSocket,
        socketStatus,
        resetSocketConnection: () => setSocketInitTime(new Date().toISOString()),
    };
}

function useCustomReconnection<T extends BaseWebSocketPayload>(
    statefulSocket: Socket | null,
    setSocketStatus: SetValue<SocketStatus>,
    socketInitTime: ISOString,
    connectionEvent?: ConnectionEventMetadata<T>
): { reconnect: (retries: number, s: Socket | null) => void; reconnectionAttempts: number } {
    let timeoutRef: ReturnType<typeof window.setTimeout> | undefined;
    const { event, payload, meetingId, botId } = connectionEvent ?? {};
    const [storedSeries] = useGlobalNullableStoredSeries();
    const [user] = useGlobalAuthedUser();
    const [reconnectionAttempts, setReconnectionAttempts] = useState(0);
    const reconnectionDelayOffsetProgressionInSeconds = [1, 2, 3, 5, 7, 10, 15, 25, 45, 60, 90];

    const tryReconnect = (reconnectionAttempts: number, statefulSocket: Socket | null) => {
        const updatedReconnectionAttempt = reconnectionAttempts + 1;

        const shouldBypassReconnectionIfInactive = document.hidden;
        const hasExceededReconnectionAttempts =
            updatedReconnectionAttempt >= reconnectionDelayOffsetProgressionInSeconds.length;

        if (shouldBypassReconnectionIfInactive || hasExceededReconnectionAttempts) {
            setSocketStatus(SocketStatus.Idle);
            if (timeoutRef) {
                clearTimeout(timeoutRef);
            }
            return;
        }

        setSocketStatus(SocketStatus.Reconnecting);
        setReconnectionAttempts(updatedReconnectionAttempt);

        const usersUniqueReconnectDelay = ((storedSeries?.userMetadataList ?? []) as SeriesUserMetadata[]).findIndex(
            (u) => u._id === user.spinachUserId
        );
        const reconnectionDelay = reconnectionDelayOffsetProgressionInSeconds[updatedReconnectionAttempt];
        const reconnectionOffsetCount =
            usersUniqueReconnectDelay === -1 ? reconnectionDelay : usersUniqueReconnectDelay + reconnectionDelay;
        const reconnectionOffsetMs = reconnectionOffsetCount * 1000;

        timeoutRef = setTimeout(() => {
            statefulSocket?.io.open((err) => {
                if (err) {
                    tryReconnect(updatedReconnectionAttempt, statefulSocket);
                } else {
                    setReconnectionAttempts(0);
                    if (timeoutRef) {
                        clearTimeout(timeoutRef);
                    }
                }
            });
        }, reconnectionOffsetMs);
    };

    useEffect(() => {
        setReconnectionAttempts(0);

        if (timeoutRef) {
            clearTimeout(timeoutRef);
        }
    }, [socketInitTime]);

    useEffect(() => {
        statefulSocket?.io.removeListener('close');

        statefulSocket?.io.on('close', () => {
            tryReconnect(reconnectionAttempts, statefulSocket);
        });

        // remove existing reconnect listener before creating a new one
        statefulSocket?.io.removeListener('reconnect');

        // create new reconnection listener with latest meetingId
        statefulSocket?.io.on('reconnect', () => {
            setSocketStatus(SocketStatus.Connected);
            setReconnectionAttempts(0);

            if (event && payload) {
                statefulSocket.emit(
                    event,
                    createWebsocketPayload<T>({
                        ...payload,
                        appVersion: getClientConfigValue('REACT_APP_APP_VERSION'),
                        reconnecting: true,
                        meetingId,
                        botId,
                    })
                );
            }
        });

        return () => {
            if (timeoutRef) {
                clearTimeout(timeoutRef);
            }

            if (statefulSocket) {
                statefulSocket.io.removeListener('close');
                statefulSocket.io.removeListener('reconnect');
            }
        };
    }, [statefulSocket, meetingId]);

    return { reconnect: tryReconnect, reconnectionAttempts };
}

const TIME_TO_DISCONNECT_WHEN_INACTIVE_MS = 2 * MILLIS_IN_SECONDS * SECONDS_IN_MINUTES;

function useSocketInactivity(
    statefulSocket: Socket | null,
    socketStatus: SocketStatus,
    setSocketInitTime: SetValue<string>,
    setSocketStatus: SetValue<SocketStatus>
) {
    const isInactive = useWindowInactivity();

    useEffect(() => {
        let timeoutRef: ReturnType<typeof window.setTimeout> | undefined;

        if (!isInactive && timeoutRef) {
            clearTimeout(timeoutRef);
        }

        if (
            !isInactive &&
            statefulSocket?.connected === false &&
            (socketStatus === SocketStatus.ManuallyClosed || socketStatus === SocketStatus.Idle)
        ) {
            setSocketInitTime(new Date().toISOString());
        }

        if (isInactive && !timeoutRef) {
            timeoutRef = setTimeout(() => {
                setSocketStatus(SocketStatus.ManuallyClosed);
                statefulSocket?.disconnect();
            }, TIME_TO_DISCONNECT_WHEN_INACTIVE_MS);
        }

        return () => {
            if (timeoutRef) {
                clearTimeout(timeoutRef);
            }
        };
    }, [statefulSocket, isInactive]);
}
