import { Modal, TextField } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
    ClientEventType,
    ClientSocketEvent,
    MeetingStatus,
    NewEmailSummarySubscribingRequest,
    SeriesOutputChannel,
    SeriesOutputContent,
    SeriesOutputOptions,
    StoredSeries,
} from '@spinach-shared/types';
import { StoredSpinachSeries, isDemoSeries, isEmailValid } from '@spinach-shared/utils';

import { getStoredSeries, patchSeries } from '../../../apis';
import { GlobalModal } from '../../../atoms';
import { ElementId } from '../../../constants';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalModalState,
    useGlobalNullableLiveSeries,
    useGlobalNullableStoredSeries,
    useGlobalStoredSeries,
} from '../../../hooks';
import { useGlobalNullableMeetingSocket } from '../../../hooks/useGlobalSocket';
import { useGlobalStoredSeriesList } from '../../../hooks/useGlobalStoredSeriesList';
import { BodyLarge, BodyRegular, HeaderThree } from '../../../styles';
import { ListItemValue } from '../../../types';
import { createWebsocketPayload, withAccessibleSubmitProps, withContentMasking } from '../../../utils';
import { useSpinachInputStyles } from '../../input';
import { ErrorBodySubtitle, ModalContent } from '../../series/common';
import { PrimaryButton, ScrollArea } from '../../stand-up';
import { ClientPath } from '../ClientPaths';
import { ItemList } from '../ItemList';
import { Column, Row, Spacing } from '../framing';

const Content = styled(ModalContent)`
    max-width: 450px;
`;

export function SubscribeToSeriesOutput({ autoFocusDelay = 10 }: { autoFocusDelay?: number }): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [storedSeries, setStoredSeries] = useGlobalStoredSeries();
    const [liveSeries] = useGlobalNullableLiveSeries();
    const registeredEmails = storedSeries.dailySubscribedEmails;
    const [socket] = useGlobalNullableMeetingSocket();
    const track = useExperienceTracking();
    const modalMeta = useGlobalModalState();
    const { storedSeriesListState, setStoredSeriesListState } = useGlobalStoredSeriesList();

    const [emailInput, setEmailInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const classes = useSpinachInputStyles({ value: emailInput });

    const emailsFromInput = emailInput
        .split(',')
        .map((email) => email.trim())
        .filter((email) => !!email);

    const seriesHasUserWithSameEmail = !!registeredEmails.find((email) => emailsFromInput.includes(email));

    const lengthError = emailsFromInput.length + registeredEmails.length > 50 ? 'You can add up to 50 emails' : '';

    const error = seriesHasUserWithSameEmail
        ? 'Email is already added'
        : emailsFromInput.some((newEmail) => !isEmailValid(newEmail)) && !!emailInput
        ? 'Invalid email'
        : '';

    function updateGlobalSeriesList(updatedSeries: StoredSeries) {
        const index = storedSeriesListState.storedSeriesList.findIndex((series) => series.id === updatedSeries.id);
        if (index !== -1) {
            const updatedList = [...storedSeriesListState.storedSeriesList];
            updatedList[index] = new StoredSpinachSeries(updatedSeries);
            setStoredSeriesListState({
                ...storedSeriesListState,
                storedSeriesList: updatedList,
            });
        }
    }

    const onRemoveEmailClick = async (email: string) => {
        setIsLoading(true);

        const seriesId = storedSeries.slug;
        const latestStoredSeries = await getStoredSeries(seriesId);

        if (!latestStoredSeries) {
            return;
        }

        const updatedOutputOptions = latestStoredSeries.outputOptions.filter((options) => options.to !== email);

        track(ClientEventType.RemoveSubscribedOutputClick, { RemovingEmail: email, Location: modalMeta.location });

        const updatedSeries = await patchSeries(seriesId, {
            outputOptions: updatedOutputOptions,
        });

        if (updatedSeries) {
            if (modalMeta.modal === GlobalModal.SubscribeSeriesOutput) {
                setStoredSeries(updatedSeries);
            }
            updateGlobalSeriesList(updatedSeries);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        setTimeout(() => {
            const inputElement = document.getElementById(ElementId.InviteEmailInput);
            if (inputElement) {
                inputElement.focus();
            }
        }, autoFocusDelay);
    }, []);

    const listItemValues: ListItemValue<string>[] = registeredEmails.map((email) => {
        return {
            code: email,
            label: email,
            postContent: (
                <span
                    style={{
                        cursor: 'pointer',
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    {...withAccessibleSubmitProps(() => {
                        onRemoveEmailClick(email);
                    })}
                >
                    <HighlightOffIcon htmlColor="gray" />
                </span>
            ),
        };
    });

    const onSubmitAddEmail = async () => {
        const seriesId = storedSeries.slug;

        if (isDemoSeries(seriesId)) {
            return;
        }

        setIsLoading(true);

        const freshSeries = await getStoredSeries(storedSeries.slug);

        if (!freshSeries) {
            return;
        }

        const newOutputOptions: SeriesOutputOptions[] = emailsFromInput.map((email) => ({
            to: email,
            channel: SeriesOutputChannel.Email,
            content: [SeriesOutputContent.DailySummary],
            referrer: {
                spinachUserId: user.spinachUserId,
                fullName: user.preferredName || user.email,
            },
        }));

        newOutputOptions.forEach((options) => {
            track(ClientEventType.AddSummaryOutputSubscription, {
                AddingEmail: options.to,
                Content: options.content,
                Location: modalMeta.location,
            });
        });

        const updatedOutputOptions = [...freshSeries.outputOptions, ...newOutputOptions];

        const updatedSeries = await patchSeries(seriesId, {
            outputOptions: updatedOutputOptions,
        });

        /**
         * if on summary view, emit socket event to send emails to newly added users that havent
         * already received daily emails
         */

        const isOnSummaryView = liveSeries?.currentMeeting.status === MeetingStatus.AgendaComplete;
        if (isOnSummaryView && socket) {
            const payload = createWebsocketPayload<NewEmailSummarySubscribingRequest>({
                outputOptions: newOutputOptions,
                seriesSlug: seriesId,
                meetingId: liveSeries.currentMeeting.id,
                spinachUserId: user.spinachUserId,
            });
            socket.emit(ClientSocketEvent.NewEmailSummarySubscribing, payload);
        }

        if (updatedSeries) {
            if (modalMeta.modal === GlobalModal.SubscribeSeriesOutput) {
                setStoredSeries(updatedSeries);
            }
            updateGlobalSeriesList(updatedSeries);
        }

        setEmailInput('');
        setIsLoading(false);
    };

    let subtitle = `A summary from each ${user.meetingWord.toLowerCase()} will be sent to the emails below. Add multiple emails by using a comma (,)`;
    if (storedSeries.isScribeSeries) {
        subtitle = 'A summary will be sent to the emails below. Add multiple emails by using a comma (,)';
    }

    return (
        <>
            <BodyRegular>{subtitle}</BodyRegular>

            <Spacing factor={1 / 2} />

            <Row style={{ flex: 'unset' }}>
                <TextField
                    id={ElementId.InviteEmailInput}
                    InputProps={{ classes: { root: classes.base } }}
                    {...withContentMasking(classes.root)}
                    fullWidth
                    placeholder="Add by email"
                    value={emailInput}
                    onKeyDown={async (e) => {
                        if (e.key === 'Enter') {
                            await onSubmitAddEmail();
                        }
                    }}
                    onChange={(e) => {
                        setEmailInput(e.target.value.toLowerCase());
                    }}
                />

                <PrimaryButton
                    title="Add"
                    isLoading={isLoading}
                    loadingText={''}
                    onClick={onSubmitAddEmail}
                    disabled={!!error || !!lengthError || !emailInput.trim()}
                />
            </Row>

            <ErrorBodySubtitle>{lengthError || error}</ErrorBodySubtitle>

            <Spacing factor={1 / 2} />

            <BodyLarge>Recipients</BodyLarge>

            <Spacing factor={1 / 2.5} />

            <ScrollArea style={{ height: '30vh' }} sidePadding={0}>
                <ItemList style={{ borderBottom: '1px solid lightgray' }} values={listItemValues} />
            </ScrollArea>
        </>
    );
}

export function SubscribeSeriesOutputModal({
    isOpen,
    onClose,
    series,
}: {
    isOpen: boolean;
    onClose: () => void;
    series: StoredSpinachSeries;
}): JSX.Element {
    const [, setStoredSeries] = useGlobalNullableStoredSeries();
    const [globalModal] = useGlobalModal();
    const modalMeta = useGlobalModalState();
    const track = useExperienceTracking();
    const location = useLocation();

    const onModalClose = async () => {
        track(ClientEventType.SubscribeSeriesOutputModalClosed, { Location: modalMeta.location });
        onClose();
        if (location.pathname === ClientPath.AIHome) {
            setStoredSeries(null);
        }
    };

    useEffect(() => {
        async function fetchLatestStoredSeries() {
            const latestStoredSeries = await getStoredSeries(series.slug);
            // only set global state if were still on this modal, to prevent setting global state unexpectedly if modal is closed
            if (latestStoredSeries && globalModal === GlobalModal.SubscribeSeriesOutput) {
                setStoredSeries(latestStoredSeries);
            }
        }
        if (isOpen) {
            fetchLatestStoredSeries();
        }
    }, [isOpen]);

    if (isDemoSeries(series.slug)) {
        return <></>;
    }

    let title = 'Manage email summaries';
    if (series.isScribeSeries) {
        title = 'Manage additional recipients';
    }

    return (
        <Modal
            open={isOpen}
            onClose={() => {
                onModalClose();
            }}
        >
            <Content id={ElementId.SubscribeSeriesOutputModalContent}>
                <Column>
                    <HeaderThree>{title}</HeaderThree>
                    <SubscribeToSeriesOutput />

                    <Spacing factor={1 / 3} />

                    <PrimaryButton
                        title="Done"
                        onClick={async () => {
                            onModalClose();
                        }}
                    />
                </Column>
            </Content>
        </Modal>
    );
}

export function GlobalSubscribeSeriesOutputModal(): JSX.Element {
    const [series] = useGlobalNullableStoredSeries();
    const [openModal, setOpenModal] = useGlobalModal();

    if (!series) {
        return <></>;
    }

    return (
        <SubscribeSeriesOutputModal
            isOpen={openModal === GlobalModal.SubscribeSeriesOutput}
            onClose={() => {
                setOpenModal(null);
            }}
            series={series}
        />
    );
}
