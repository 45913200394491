import {
    FeatureIntent,
    MeetingCompanyAllHandsJSON,
    MeetingCustomerSuccessCheckInJSON,
    MeetingGeneralSalesCallJSON,
    ScribeMeetingType,
    Stage,
    SummaryFeatureIntents,
} from '@spinach-shared/types';
import { isProductionStage } from '@spinach-shared/utils';

export const SLACK_HUDDLE_BOT_EMAIL_PROD = 'bot@slack.spinach.io' as const;
export const SLACK_HUDDLE_BOT_EMAIL_TEST = 'bot2@slackbot.test.spinach.io' as const;

/**
 * @NOTE If a key in MongoDB contains a "." in it, it will create a nested object in the document.
 * To avoid this, we replace the "." with this string.
 * */
export const MONGODB_DOT_REPLACEMENT_FOR_KEY_NAME = '-- dot --';

const ENTERPRISE_SCRIBE_EMAILS_PROD: string[] = [];

export const ENTERPRISE_SCRIBE_EMAIL_MAP_PROD: Record<string, typeof ENTERPRISE_SCRIBE_EMAILS_PROD>[] = [{}];

export const SCRIBE_EMAILS_PROD = [
    'invite@spinach.io',
    'get@spinach.io',
    'spinach@spinach.io',
    'ai@spinach.io',
    'scrum@spinach.io',
    'join@spinach.io',
    'meet@spinach.io',
    'go@spinach.io',
    'run@spinach.io',
    'pro@spinach.io',
    'add@spinach.io',
    'book@spinach.io',
    'sub@spinach.io',
    SLACK_HUDDLE_BOT_EMAIL_PROD,
    'sano@spinach.io',
    'agent-1@spinach.io',
] as const;
export const SCRIBE_EMAILS_TEST = [
    'scribe-test@spinach.io',
    'scribe-test-2@spinach.io',
    SLACK_HUDDLE_BOT_EMAIL_TEST,
] as const;

export const ALL_KNOWN_SCRIBE_EMAILS: string[] = [...SCRIBE_EMAILS_PROD, ...SCRIBE_EMAILS_TEST];

export function getScribeEmails(stage: Stage): readonly string[] {
    return stage === Stage.Production ? SCRIBE_EMAILS_PROD : SCRIBE_EMAILS_TEST;
}

export function getDefaultScribeEmail(stage: Stage, rootDomain?: string): string {
    // default to ai@spinach.io
    if (rootDomain && isProductionStage()) {
        const enterpriseDomainMap = ENTERPRISE_SCRIBE_EMAIL_MAP_PROD.find((enterpriseMap) => enterpriseMap[rootDomain]);
        if (enterpriseDomainMap) {
            const validScribeEmailsForEnterprise = enterpriseDomainMap[rootDomain];
            return validScribeEmailsForEnterprise[Math.floor(Math.random() * validScribeEmailsForEnterprise.length)];
        }
    }
    return isProductionStage() ? getScribeEmails(stage)[1] : getScribeEmails(stage)[0];
}

export function getSlackHuddleBotEmail(stage: Stage): string {
    return isProductionStage() ? SLACK_HUDDLE_BOT_EMAIL_PROD : SLACK_HUDDLE_BOT_EMAIL_TEST;
}

export const DefaultStandupSummaryFeatureIntents: SummaryFeatureIntents[] = [
    FeatureIntent.MeetingWorkstreams,
    FeatureIntent.MeetingHeySpinachSection,
    FeatureIntent.MeetingActionItems,
    FeatureIntent.MeetingKeyDecisions,
    FeatureIntent.MeetingBlockers,
    FeatureIntent.MeetingCelebrations,
    FeatureIntent.PerPersonUpdates,
    FeatureIntent.MeetingChapters,
];
export const DeafultRetroSummaryFeatureIntents: SummaryFeatureIntents[] = [
    FeatureIntent.MeetingHeySpinachSection,
    FeatureIntent.MeetingActionItems,
    FeatureIntent.MeetingWhatWentWell,
    FeatureIntent.MeetingWhatDidNotGoWell,
    FeatureIntent.MeetingWhatCanBeImproved,
    FeatureIntent.MeetingChapters,
];

export const DefaultGenericSummaryFeatureIntents: SummaryFeatureIntents[] = [
    FeatureIntent.MeetingHeySpinachSection,
    FeatureIntent.MeetingAgendaItems,
    FeatureIntent.MeetingActionItems,
    FeatureIntent.MeetingKeyDecisions,
    FeatureIntent.MeetingBlockers,
    FeatureIntent.MeetingCelebrations,
    FeatureIntent.MeetingChapters,
];

export const PartiallyCappedSummaryFeatureIntents: SummaryFeatureIntents[] = [
    FeatureIntent.MeetingAgendaItems,
    FeatureIntent.MeetingChapters,
];

export const ScribeMeetingTypeLabelMap: Record<ScribeMeetingType, string> = {
    [ScribeMeetingType.BacklogGrooming]: 'Backlog Refinement',
    [ScribeMeetingType.Generic]: 'General',
    [ScribeMeetingType.SprintPlanning]: 'Planning',
    [ScribeMeetingType.Retro]: 'Retrospective',
    [ScribeMeetingType.Standup]: 'Standup',
    [ScribeMeetingType.UserResearchInterview]: 'User Research',
    [ScribeMeetingType.CustomerSuccessCheckIn]: 'Customer Success Check-In',
    [ScribeMeetingType.GeneralSalesMeeting]: 'General Sales Meeting',
    [ScribeMeetingType.CompanyAllHands]: 'Company All-Hands',
};

export type MeetingCompanyAllHandsNestedFeatureIntents = keyof MeetingCompanyAllHandsJSON;
export type MeetingGeneralSalesCallNestedFeatureIntents = keyof MeetingGeneralSalesCallJSON;
export type MeetingCustomerSuccessCheckInNestedFeatureIntents = keyof MeetingCustomerSuccessCheckInJSON;

export type NestedSummaryFeatureIntentKeys =
    | MeetingCompanyAllHandsNestedFeatureIntents
    | MeetingGeneralSalesCallNestedFeatureIntents
    | MeetingCustomerSuccessCheckInNestedFeatureIntents;

export type NestedSummaryFeatureIntents = Extract<
    SummaryFeatureIntents,
    | FeatureIntent.MeetingCompanyAllHandsSummary
    | FeatureIntent.MeetingGeneralSalesCall
    | FeatureIntent.MeetingCustomerSuccessCheckIn
>;

type MeetingCompanyAllHandsLabelMap = Record<
    FeatureIntent.MeetingCompanyAllHandsSummary,
    Record<MeetingCompanyAllHandsNestedFeatureIntents, string>
>;

type MeetingGeneralSalesCallLabelMap = Record<
    FeatureIntent.MeetingGeneralSalesCall,
    Record<MeetingGeneralSalesCallNestedFeatureIntents, string>
>;

type MeetingCustomerSuccessCheckInLabelMap = Record<
    FeatureIntent.MeetingCustomerSuccessCheckIn,
    Record<MeetingCustomerSuccessCheckInNestedFeatureIntents, string>
>;

type NestedFeatureIntentLabelMap = MeetingCompanyAllHandsLabelMap &
    MeetingGeneralSalesCallLabelMap &
    MeetingCustomerSuccessCheckInLabelMap;

export const ScribeFeatureIntentLabelMap: Record<Exclude<SummaryFeatureIntents, NestedSummaryFeatureIntents>, string> &
    NestedFeatureIntentLabelMap = {
    [FeatureIntent.MeetingBlockers]: 'Blockers',
    [FeatureIntent.MeetingKeyDecisions]: 'Key Decisions',
    [FeatureIntent.MeetingActionItems]: 'Action Items',
    [FeatureIntent.MeetingAgendaItems]: 'Key Topics',
    [FeatureIntent.MeetingWhatWentWell]: 'What Went Well',
    [FeatureIntent.MeetingWhatDidNotGoWell]: "What Didn't Go Well",
    [FeatureIntent.MeetingWhatCanBeImproved]: 'Improvements',
    [FeatureIntent.MeetingCelebrations]: 'Celebrations',
    [FeatureIntent.PerPersonUpdates]: 'Updates',
    [FeatureIntent.MeetingHeySpinachSection]: 'Hey Spinach',
    [FeatureIntent.KeyResearchTakeaways]: 'Session Background',
    [FeatureIntent.ResearchInterviewQuotesBank]: 'Research Summary',
    [FeatureIntent.PreviousMeetingInsights]: '',
    [FeatureIntent.MeetingWorkstreams]: 'Meeting Workstreams',
    [FeatureIntent.MeetingGeneralSalesCall]: {
        interests: 'Interests',
        concerns: 'Concerns',
        outcomeAndTakeaway: 'Outcome and Takeaway',
        callSummary: 'Call Summary',
        agendaItems: 'Key Topics',
    },
    [FeatureIntent.MeetingCompanyAllHandsSummary]: {
        companyUpdates: 'Company Updates',
        futurePlans: 'Future Plans',
        qaSession: 'Q&A Session',
        employeeRecognition: 'Employee Recognition',
    },
    [FeatureIntent.MeetingCustomerSuccessCheckIn]: {
        callSummary: 'Call Summary',
        highlights: 'Highlights',
        concernsChallenges: 'Concerns and Challenges',
        requests: 'Requests',
        bestPractices: 'Best Practices',
        keyTakeaways: 'Key Takeaways',
    },
    [FeatureIntent.MeetingChapters]: 'Meeting Chapters',
};

export const FEATURE_INTENT_DESCRIPTION: Record<
    SummaryFeatureIntents | NestedSummaryFeatureIntentKeys,
    string | { label: string; description: string }[]
> = {
    [FeatureIntent.MeetingBlockers]: 'Issues hindering progress',
    [FeatureIntent.MeetingKeyDecisions]: 'Important decisions made during the meeting',
    [FeatureIntent.MeetingActionItems]: 'Tasks assigned to the team members with follow-up actions',
    [FeatureIntent.MeetingAgendaItems]: 'Main discussion points',
    [FeatureIntent.MeetingWhatWentWell]: 'Positive outcomes and successes',
    [FeatureIntent.MeetingWhatDidNotGoWell]: 'Issues and challenges faced',
    [FeatureIntent.MeetingWhatCanBeImproved]: 'Suggesetions for future enhancements',
    [FeatureIntent.MeetingCelebrations]: 'Achievements and milestones',
    [FeatureIntent.PerPersonUpdates]: 'Status report from each team member',
    [FeatureIntent.MeetingHeySpinachSection]: '"Hey Spinach" requests we heard in-meeting',
    [FeatureIntent.KeyResearchTakeaways]: [
        {
            label: 'Participant Details',
            description: 'Information about the interviewee',
        },
        {
            label: 'Session Details',
            description: 'Key points and observations',
        },
    ],
    [FeatureIntent.ResearchInterviewQuotesBank]: [
        {
            label: 'Positive Highlights',
            description: 'Notable positive insights',
        },
        {
            label: 'Areas for Improvement',
            description: 'Identified issues from the session',
        },
    ],
    /** @deprecated */
    [FeatureIntent.PreviousMeetingInsights]: '',
    [FeatureIntent.MeetingWorkstreams]: 'Status and progress of current projects.',
    [FeatureIntent.MeetingCompanyAllHandsSummary]: '',
    companyUpdates: 'Recent developments and important news within the company',
    employeeRecognition: 'Acknowledgement of outstanding employee achievements and contributions',
    futurePlans: 'Upcoming goals, projects, and strategic directions for the company',
    qaSession: 'Interactive segment where employees ask questions and receive answers',
    [FeatureIntent.MeetingGeneralSalesCall]: '',
    outcomeAndTakeaway: 'Results, agreements, and key insights',
    concerns: "Client's reservations and issues raised",
    interests: "Client's priorities",
    callSummary: 'Overview of meeting date, time, participants, and objectives',
    agendaItems: 'Main discussion points',
    [FeatureIntent.MeetingCustomerSuccessCheckIn]: '',
    highlights: 'Highlights',
    concernsChallenges: 'Current issues or obstacles faced by the customer',
    requests: 'Specific needs requested by the customer',
    bestPractices: 'Effective strategies and practices discussed',
    keyTakeaways: 'Important insights and conclusions from the meeting',
    [FeatureIntent.MeetingChapters]: 'Meeting Chapters',
};

export const MEETING_TYPE_FEATURE_INTENT_MAP: Record<ScribeMeetingType, SummaryFeatureIntents[]> = {
    [ScribeMeetingType.BacklogGrooming]: [],
    [ScribeMeetingType.SprintPlanning]: [],
    [ScribeMeetingType.Generic]: DefaultGenericSummaryFeatureIntents,

    [ScribeMeetingType.Retro]: DeafultRetroSummaryFeatureIntents,

    [ScribeMeetingType.Standup]: DefaultStandupSummaryFeatureIntents,

    [ScribeMeetingType.UserResearchInterview]: [
        FeatureIntent.KeyResearchTakeaways,
        FeatureIntent.ResearchInterviewQuotesBank,
        FeatureIntent.MeetingChapters,
    ],
    [ScribeMeetingType.CustomerSuccessCheckIn]: [
        FeatureIntent.MeetingActionItems,
        FeatureIntent.MeetingCustomerSuccessCheckIn,
        FeatureIntent.MeetingChapters,
    ],
    [ScribeMeetingType.GeneralSalesMeeting]: [
        FeatureIntent.MeetingActionItems,
        FeatureIntent.MeetingGeneralSalesCall,
        FeatureIntent.MeetingChapters,
    ],
    [ScribeMeetingType.CompanyAllHands]: [
        FeatureIntent.MeetingActionItems,
        FeatureIntent.MeetingAgendaItems,
        FeatureIntent.MeetingCompanyAllHandsSummary,
        FeatureIntent.MeetingChapters,
    ],
};

export const DEFAULT_HEY_SPINACH_THRESHOLD = 0.85;

// taken from https://github.com/openai/whisper/blob/main/whisper/tokenizer.py
export const ENGLISH_LANGUAGE = 'English';
const SPANISH_LANGUAGE = 'Spanish';
const FRENCH_LANGUAGE = 'French';
const GERMAN_LANGUAGE = 'German';
const ITALIAN_LANGUAGE = 'Italian';
const PORTUGUESE_LANGUAGE = 'Portuguese';
const DUTCH_LANGUAGE = 'Dutch';

export const MOST_COMMON_LANGUAGES = [
    ENGLISH_LANGUAGE,
    SPANISH_LANGUAGE,
    GERMAN_LANGUAGE,
    FRENCH_LANGUAGE,
    ITALIAN_LANGUAGE,
    PORTUGUESE_LANGUAGE,
    DUTCH_LANGUAGE,
];
export const MEETING_LANGUAGE_SEPARATOR = '-----------';
export const MEETING_LANGAUGES = [
    ...MOST_COMMON_LANGUAGES,
    MEETING_LANGUAGE_SEPARATOR,
    'Afrikaans',
    'Albanian',
    'Amharic',
    'Arabic',
    'Armenian',
    'Assamese',
    'Azerbaijani',
    'Bashkir',
    'Basque',
    'Belarusian',
    'Bengali',
    'Bosnian',
    'Breton',
    'Bulgarian',
    'Cantonese',
    'Catalan',
    'Chinese',
    'Croatian',
    'Czech',
    'Danish',
    DUTCH_LANGUAGE,
    ENGLISH_LANGUAGE,
    'Estonian',
    'Faroese',
    'Finnish',
    FRENCH_LANGUAGE,
    'Galician',
    'Georgian',
    GERMAN_LANGUAGE,
    'Greek',
    'Gujarati',
    'Haitian Creole',
    'Hausa',
    'Hawaiian',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Icelandic',
    'Indonesian',
    ITALIAN_LANGUAGE,
    'Japanese',
    'Javanese',
    'Kannada',
    'Kazakh',
    'Khmer',
    'Korean',
    'Lao',
    'Latin',
    'Latvian',
    'Lingala',
    'Lithuanian',
    'Luxembourgish',
    'Macedonian',
    'Malagasy',
    'Malay',
    'Malayalam',
    'Maltese',
    'Maori',
    'Marathi',
    'Mongolian',
    'Myanmar',
    'Nepali',
    'Norwegian',
    'Nynorsk',
    'Occitan',
    'Pashto',
    'Persian',
    'Polish',
    PORTUGUESE_LANGUAGE,
    'Punjabi',
    'Romanian',
    'Russian',
    'Sanskrit',
    'Serbian',
    'Shona',
    'Sindhi',
    'Sinhala',
    'Slovak',
    'Slovenian',
    'Somali',
    SPANISH_LANGUAGE,
    'Sundanese',
    'Swahili',
    'Swedish',
    'Tagalog',
    'Tajik',
    'Tamil',
    'Tatar',
    'Telugu',
    'Thai',
    'Tibetan',
    'Turkish',
    'Turkmen',
    'Ukrainian',
    'Urdu',
    'Uzbek',
    'Vietnamese',
    'Welsh',
    'Yiddish',
    'Yoruba',
];

export const OUTPUT_LANGUAGES = [
    ...MOST_COMMON_LANGUAGES,
    MEETING_LANGUAGE_SEPARATOR,
    'Afrikaans',
    'Albanian',
    'Amharic',
    'Arabic',
    'Armenian',
    'Azerbaijani',
    'Basque',
    'Belarusian',
    'Bengali',
    'Bosnian',
    'British English',
    'Bulgarian',
    'Burmese',
    'Catalan',
    'Chinese',
    'Croatian',
    'Czech',
    'Danish',
    DUTCH_LANGUAGE,
    ENGLISH_LANGUAGE,
    'Esperanto',
    'Estonian',
    'Filipino',
    'Finnish',
    FRENCH_LANGUAGE,
    'Galician',
    'Georgian',
    GERMAN_LANGUAGE,
    'Greek',
    'Gujarati',
    'Hausa',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Icelandic',
    'Igbo',
    'Indonesian',
    'Irish',
    ITALIAN_LANGUAGE,
    'Japanese',
    'Kannada',
    'Kazakh',
    'Khmer',
    'Kinyarwanda',
    'Korean',
    'Kurdish',
    'Kyrgyz',
    'Lao',
    'Latin',
    'Latvian',
    'Lithuanian',
    'Macedonian',
    'Malay',
    'Malayalam',
    'Maltese',
    'Marathi',
    'Mongolian',
    'Nepali',
    'Norwegian',
    'Pashto',
    'Persian',
    'Polish',
    PORTUGUESE_LANGUAGE,
    'Punjabi',
    'Romanian',
    'Russian',
    'Scottish Gaelic',
    'Serbian',
    'Sindhi',
    'Sinhala',
    'Slovak',
    'Slovenian',
    'Somali',
    SPANISH_LANGUAGE,
    'Swahili',
    'Swedish',
    'Tajik',
    'Tamil',
    'Telugu',
    'Thai',
    'Tibetan',
    'Turkish',
    'Turkmen',
    'Ukrainian',
    'Urdu',
    'Uzbek',
    'Vietnamese',
    'Welsh',
    'Yoruba',
    'Zulu',
];

export const pauseCommands = ['pause', 'stop'];
export const resumeCommands = ['resume', 'unpause', 'play'];
