import { useGlobalAuthedUser } from '../../../..';
import { starterGuidePacket } from './slides';
import { ShowcasePacket } from './types';

export function useShowcaseGuidePacket(): ShowcasePacket | null {
    const [user] = useGlobalAuthedUser();

    if (!user.isEnabledForStarterGuide) {
        return null;
    }

    return starterGuidePacket;
}
