import { SeriesSchedule } from '@spinach-shared/utils';

import { ClientPlatform, DeepPartial, NotificationCode, SpinachComponents, UserIntegrationSettings } from '.';
import { DetailedTicket, StatusName, Ticket, TicketsByStatus } from './Jira';
import { TicketSource } from './Tickets';
import { ISOString, TimeInSeconds } from './Time';
import {
    FeatureFlagSet,
    FeatureFlagValue,
    FeatureToggle,
    SeriesIntegrationSettings,
    SummaryFeatureIntents,
    UserMetadata,
} from './User';
import { ZoomParticipantRole } from './Zoom';

export enum Day {
    Sunday = 'Sunday',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
}

export const STANDUP_SERIES_TYPE = 'standup';

export const ORDERED_DAYS = [
    Day.Sunday,
    Day.Monday,
    Day.Tuesday,
    Day.Wednesday,
    Day.Thursday,
    Day.Friday,
    Day.Saturday,
];

export const ORDERED_DAYS_MAP = ORDERED_DAYS.reduce(
    (acc, curr, index) => ({ ...acc, [curr]: index }),
    {} as Record<Day, number>
);

export interface CreateSeriesRequest {
    name: string;
    metadata?: SeriesMetadata;
    createdBy: UUID;
    isDemo?: boolean;
    type?: string;
}

export interface PatchSeriesRequest {
    name?: string;
    metadata?: DeepPartial<SeriesMetadata>;
    components?: SpinachComponents[];
    outputOptions?: SeriesOutputOptions[];
}

export type PatchAllSeriesSettingsRequest = {
    isEmailingIcpOnly?: boolean;
    emailDistributionConfig?: EmailDistributionConfig; // default to all
    isHostEditing?: boolean;
    outputLanguage?: string;
};

export interface RenameSeriesRequest {
    name: string;
}

export interface AddUsersToSeriesRequest {
    emails: string[];
    platform: ClientPlatform;
}

export type InternalRemoveUsersRequest = {
    emails?: string[];
    domainOpts?: {
        domain: string;
        expectedUserCount: number;
    };
};

export interface Series extends InactiveSeries {
    currentMeeting: Meeting;
}

export type StoredMeetingHistoryJSON = MeetingHistoryJSON & {
    createdAt: string;
};

export type MeetingHistoryJSON = {
    userIds: UUID[];
    meetingId: UUID;
    seriesId: string;
    meeting: MeetingProps;
    components: SpinachComponents[];
    dateTimes?: DateTimeMetadata[];
    linkedAiHistoryId?: string;
};

export type YTBHistoryJSON = {
    userId: UUID;
    seriesId: UUID;
    archives: YTBArchiveJSON[];
};

export type YTBArchiveJSON = YTBArchiveMeetingMetadata & {
    deliveryDate: ISOString;
    updates: TypedUpdate[];
};

export type YTBArchiveMeetingMetadata = {
    meetingId: string;
    seriesSlug: string;
};

export type ReminderSettings = {
    isReminderEnabled: boolean;
    durationBeforeMeetingInMilliseconds: number;
};

export enum ScribeType {
    Standup = 'standup',
}

export enum ScribeMeetingType {
    Standup = 'standup',
    Retro = 'retro',
    SprintPlanning = 'sprint-planning',
    BacklogGrooming = 'backlog-grooming',
    Generic = 'generic',
    UserResearchInterview = 'user-research-interview',
    CustomerSuccessCheckIn = 'customer-success-check-in',
    GeneralSalesMeeting = 'general-sales-meeting',
    CompanyAllHands = 'company-all-hands',
}

export enum ScribeEventStatus {
    Active = 'active',
    Inactive = 'inactive',
}

export type ScribeMetadata = {
    scribeType: ScribeType;
    /** @NOTE - this is the current calendar user ID, which can change. Do not use this field to fetch past meetings. */
    calendarUserId: string;
    /** @NOTE - this is really firstBotId */
    createdBotId: string | null;
    lastKnownBotId?: string;
    isRecallV2Series?: boolean;
    meetingId?: string;
    zoomMeetingId?: string;
    teamsMeetingId?: string;
    googleMeetingId?: string;
    seriesTitle?: string;
    meetingType?: ScribeMeetingType;
    hasMeetingTypeBeenModifiedByUser?: boolean;
    iCalUid: string;
    platform?: string;
    meetingPlatform?: string;
    enabledSummarySections?: SummaryFeatureIntents[];
    calendarPlatform?: string;
    isRecurring: boolean;
    forceVideoAgentExperience?: boolean;
    inviterId?: string;
    isScribeAdded?: boolean;
    eventStatus?: ScribeEventStatus;
    isEmailingIcpOnly?: boolean;
    emailDistributionConfig?: EmailDistributionConfig; // default to all
    invitationsReceived?: number;
    isHostEditing?: boolean;
    /** @description a user can manually exclude scribe from being added to a meeting
     * even if "add to all meetings" is enabled
     * */
    manualScribeExclude?: boolean;
    organizerUserId?: string;
    outputLanguage?: string;

    iCalUidPrefix?: string;

    additionalEditorEmails?: string[];
};

export type SeriesMetadata = {
    dateTimes?: DateTimeMetadata[];
    reminderSettings?: ReminderSettings;
    scribeMetadata?: ScribeMetadata;
    creationSource?: SeriesCreationSource;
    inMeetingEnabled?: boolean;
    forcedVideoAgentTopics?: string[];
};

export enum SeriesCreationSource {
    AiCalendarInvite = 'ai-calendar-invite',
    RecallMeetingEvent = 'recall-meeting-event',
    AiInAppInvite = 'ai-in-app-invite',
    LegacyOnboarding = 'legacy-onboarding',
    LegacyDashboard = 'legacy-dashboard',
    AiPreMeetingNotificationSetup = 'ai-pre-meeting-notification-setup',
    RecordingUpload = 'recording-upload',
    HypercontextOnboardingOneOnOne = 'hypercontext-onboarding-one-on-one',
    HypercontextOnboardingGeneral = 'hypercontext-onboarding-general',
}

export type StoredSpinachSeriesMetadata = Omit<SeriesMetadata, 'dateTimes'> & {
    dateTimes?: SeriesSchedule;
};

// `09:30:00 GMT-0700 (Pacific Daylight Time)`
export type TimeString = string;

// `hh:mm` formatted string
export type HHMMTimeString = `${string}:${string}`;

export type HHMMAMPMTimeString = `${string}:${string} ${'am' | 'pm'}`;
export type HHMMAMPMLocalTimeString = `${string}:${string} ${'am' | 'pm'} ${string}`;

export enum MeetingFormat {
    Live = 'live',
    Async = 'async',
}

export type DateTimeMetadata = {
    day: Day;
    startTime: HHMMTimeString;
    endTime: HHMMTimeString;
    meetingFormat: MeetingFormat | null;
    isChangedForThisWeekOnly?: boolean;
    oneOffDateTimeMetadata?: Omit<DateTimeMetadata, 'oneOffDateTimeMetadata' | 'isChangedForThisWeekOnly'>;
    timezoneRegion: string;
    timezoneOffset: number;
    dayOfYearToSkipAfterSameDayAdHoc?: number;
};

export type SeriesUserMetadata = {
    preferredName: string;
    _id: string;
    email: string;
    isCreator: boolean;
};

export type SpinachCode = `SPS${HexString}`;
type HexString = string;

export enum SeriesOutputChannel {
    Email = 'email',
}

export enum SeriesOutputContent {
    DailySummary = 'daily-summary',
    WeeklySummary = 'weekly-summary',
}

export enum SeriesCounters {
    InVideoMeetingSlackConnectMessage = 'in-video-meeting-slack-connect-message',
}

export type OutputOptionReferrer = {
    spinachUserId: UUID;
    fullName: string;
};

export type RecipientOptions = {
    unsubscribeUrl: string;
    email: string;
    referrerFullName: string;
};

export type SeriesOutputOptions = {
    to: string;
    channel: SeriesOutputChannel;
    content: SeriesOutputContent[];
    referrer: OutputOptionReferrer;
};

export interface RootSeries {
    id: string;
    slug: SpinachCode;
    name: string;
    metadata?: SeriesMetadata;
    integrationSettings?: SeriesIntegrationSettings;
    components: SpinachComponents[];
    outputOptions: SeriesOutputOptions[];
    counters?: Record<SeriesCounters, number>;
    icpUserId?: string;
}

export type UserSeriesMetadata = Pick<RootSeries, 'id' | 'name'> & {
    createdBy: string;
    hasAcceptedInvite?: boolean;
};

export interface StoredSeries extends RootSeries {
    userMetadataList: SeriesUserMetadata[] | string[];
    createdBy: UUID;
    featureToggles?: Record<FeatureToggle, FeatureFlagValue>;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface InactiveSeries extends RootSeries {
    currentMeeting?: Meeting;
    featureFlags?: FeatureFlagSet<FeatureToggle>;
}

export interface Meeting {
    id: UUID;
    startedAt?: ISOString;
    endedAt?: ISOString;
    status: MeetingStatus;
    userMoods?: UserMood[];
    participants: Participant[];
    modules: Module[];
    agenda: Agenda;
    createdAt: ISOString;
    updatedAt: ISOString;
    participantsOnline: number;
    seriesId: string;
    participantsReady: number;
    outputCode?: NotificationCode;
    dateTimeMetadata?: DateTimeMetadata;
    outputResults?: SeriesOutputOptions[];
    components?: SpinachComponents[];
}

export enum GoalStatus {
    Unset = 'unset',
    InProgress = 'in-progress',
    Complete = 'completed',
    Paused = 'paused',
    Blocked = 'blocked',
}

export enum GoalKind {
    User = 'user',
    Team = 'team',
}

/**
 * 0-100 representing a percentage
 */
export type GoalProgress = number;

export type GoalMetadata = {
    creatorId: UUID;
    kind: GoalKind;
    status: GoalStatus;

    /** @NOTE - unused thus far for issue-based experiment-1 */
    sharedInSeries: string[];
    progress: GoalProgress;

    isArchived: boolean;
    isDeleted: boolean;

    /** @NOTE - added by db */
    createdAt: Date;

    /** @NOTE - added by db */
    updatedAt: Date;
};
export type Goal = Omit<TypedUpdate, 'isRolledOver' | 'updateType'> & GoalMetadata;

export enum Sentiment {
    Good = 'Good',
    Ok = 'Ok',
    Bad = 'Bad',
}

export type UserMood = {
    sentiment?: Sentiment;
    details?: string;
    spinachUserId: string;
    displayName: string;
};

export type TicketData = {
    sentiment?: Sentiment;
    ticket: Ticket;
    source: TicketSource;
};

export type ResolverMetadata = {
    spinachUserId: string;
    email: string;
    preferredName: string;
    details?: string;
    createdOn?: ISOString;
    updatedOn?: ISOString;
};

export type TypedUpdate = {
    id: UUID;
    text: string;
    updateType: SpinachUpdateType;
    isRolledOver?: boolean;
    jiraData?: Ticket;
    asanaData?: Ticket;
    creatorId: string;
    subItems?: TypedUpdate[];
    // TODO: These will no longer be optional
    // some time after release 10/14/22
    createdOn?: ISOString;
    updatedOn?: ISOString;
    ticketData?: TicketData;
    // I wonder if reactions should be stored like so
    // { id: ReactionsStored, id: ReactionsStored}
    reactions?: StoredReaction[];
    customListId?: string;
    sentiment?: Sentiment;
    resolvers?: ResolverMetadata[];
    goalMetadata?: GoalMetadata & { goalId: UUID };
    sentimentDetails?: string;
};

// This is a wrapper class that provides some convenience utilities and can help with
// iterating on the data object seamlessly. We should consider using this class instead of the type where possible
export class TypedUpdateWrapper {
    typedUpdate: TypedUpdate;

    constructor(typedUpdate: TypedUpdate) {
        this.typedUpdate = typedUpdate;
    }

    // this is used with transition of lifting sentiment to top leve to support sentiments for non jira items
    public get sentiment() {
        return this.typedUpdate.sentiment || this.typedUpdate.ticketData?.sentiment;
    }

    public get sentimentDetails() {
        return this.typedUpdate.sentimentDetails;
    }

    public get ticket() {
        return this.typedUpdate.ticketData?.ticket || this.typedUpdate.jiraData || this.typedUpdate.asanaData;
    }

    public get author() {
        return 'author' in this.typedUpdate ? ((this.typedUpdate as any)['author'] as string) : null;
    }

    public get hasBlockerSentiment() {
        return this.sentiment && (this.sentiment === Sentiment.Bad || this.sentiment === Sentiment.Ok);
    }

    public get resolvers() {
        return this.typedUpdate.resolvers ?? [];
    }

    public isUserResolverForItem(spinachUserId: string) {
        return this.resolvers?.length && this.resolvers.find((resolver) => resolver.spinachUserId === spinachUserId);
    }

    public get asGoal(): Goal | undefined {
        if (!this.typedUpdate.goalMetadata) {
            return;
        }

        const { goalId, ...restOfGoalData } = this.typedUpdate.goalMetadata;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, goalMetadata, ...typedUpdate } = this.typedUpdate;

        return {
            ...restOfGoalData,
            ...typedUpdate,
            id: goalId,
        };
    }
}

export type StoredReaction = {
    emojiId: string;
    userId: string;
    reactedUserIds: string[];
};

export type AuthoredUpdate = TypedUpdate & {
    author: string;
};

export enum SpinachUpdateType {
    Yesterday = 'YESTERDAY',
    Today = 'TODAY',
    Challenge = 'CHALLENGES',
    // TODO: rename to team topics as desired
    ParkingLot = 'PARKING_LOT',
    Topic = 'TOPIC',
    Notes = 'NOTES',
    ActionItems = 'ACTION_ITEMS',
    Custom = 'CUSTOM',
    Icebreaker = 'ICEBREAKER',
}

export type SpinachUpdateTypeYTB = Extract<
    SpinachUpdateType,
    SpinachUpdateType.Yesterday | SpinachUpdateType.Today | SpinachUpdateType.Challenge
>;

export type UpdatesByReservedType = {
    [SpinachUpdateType.Yesterday]: TypedUpdate[];
    [SpinachUpdateType.Today]: TypedUpdate[];
    [SpinachUpdateType.Challenge]: TypedUpdate[];
    [SpinachUpdateType.ParkingLot]: TypedUpdate[];
    [SpinachUpdateType.Notes]: TypedUpdate[];
    [SpinachUpdateType.Topic]: TypedUpdate[];
    [SpinachUpdateType.ActionItems]: TypedUpdate[];
    [SpinachUpdateType.Custom]: TypedUpdate[];
    [SpinachUpdateType.Icebreaker]: TypedUpdate[];
};

export enum StandUpUpdatePhase {
    Presenting = 'presenting',
    QuestionsAndAnswers = 'questions-and-answers',
}

export enum ReservedAgendaTitle {
    TeamTopics = 'Team Topics',
}

export type IYTBUpdateProps = {
    id?: UUID;
    spinachUserId?: UUID;
    updates?: TypedUpdate[];
};

export type StandUpUpdate = IYTBUpdateProps & {
    id: UUID;
    spinachUserId?: UUID;
    updates: TypedUpdate[];
};

export type MeetingProps = Omit<Meeting, 'participantsOnline' | 'participantsReady'> | Meeting;

export type JoiningSpinachParticipant = Participant & { spinachUserId: string; email: string };

export enum StandUpStatus {
    Preparing = 'Preparing',
    Ready = 'Ready',
    NoApp = 'No App',
    Submitted = 'Submitted',
    Async = 'Async',
}

export interface ISpinachParticipantProps extends JoiningSpinachParticipant {
    connectionIds: string[];
}

export type ILegacyAgendaItemProps = AgendaItem & {
    intendedDuration?: TimeInSeconds;
    status: AgendaItemStatus;
    isMutable: boolean;
};

export interface Participant {
    id: string;
    spinachUserId: UUID;
    /** @TODO this can be made non-optional after some time */
    appVersion?: string;
    displayName: string;
    email?: string;
    phone?: string;
    connectionIds?: string[]; // not all participants are truly connected, they may just be represented.
    participation: Participation[];
    totalTimeInMeeting?: TimeInSeconds;
    standUpStatus?: StandUpStatus;
    role?: ZoomParticipantRole;
    integrationSettings?: UserIntegrationSettings;
    featureFlagMap: FeatureFlagSet<FeatureToggle>;
    platform: ClientPlatform;
    metadata?: UserMetadata;
    shouldRefreshApp?: boolean;
    isAttendingMeeting?: boolean;
}

export type JoiningParticipant = Omit<Participant, 'connectionIds'>;

export interface Participation {
    startedAt: ISOString;
    endedAt?: ISOString;
}

interface BaseModule {
    type: ModuleType;
}

export interface IcebreakerModule extends BaseModule {
    type: ModuleType.Icebreaker;
    question: string;
    hasStarted: boolean;
    usedQuestions: number[];
    isLockedForCheckIn?: boolean;
}

export interface YTBStandUpModule extends BaseModule {
    type: ModuleType.YTBStandUp;
}

export interface MeditationModule extends BaseModule {
    type: ModuleType.Meditation;
    startedAt?: ISOString;
    completedAt?: ISOString;
    file: string;
    duration?: number;
    maxDuration: number;
}

export type Module = YTBStandUpModule | IcebreakerModule | MeditationModule;

export enum ModuleType {
    Roundtable = 'roundtable',
    Icebreaker = 'icebreaker',
    YTBStandUp = 'YTBStandUp',
    Meditation = 'Meditation',
}

export interface Agenda {
    currentIndex?: number;
    startedAt?: ISOString;
    items: AgendaItem[];
}

export type ProjectManagement = {
    tickets?: DetailedTicket[];
    ticketsByStatus?: TicketsByStatus;
    orderedStatuses?: StatusName[];
};

export type IYTBAgendaItemProps = AgendaItem & {
    standUpUpdate: StandUpUpdate;
    userGoals?: Goal[];
};

export interface AgendaItem {
    id: UUID;
    title: string;
    totalTime: TimeInSeconds;
    talkTimes: TalkTime[];
    intendedDuration?: TimeInSeconds;
    isMutable?: boolean;
    createdAt: ISOString;
    isParticipantAgendaItem: boolean;
    updatedAt: ISOString;
    source: AgendaItemSource;
    status?: AgendaItemStatus;
    standUpUpdate?: StandUpUpdate;
    projectManagement?: ProjectManagement;
    userGoals?: Goal[];
}

export interface MeditationItem extends AgendaItem {
    title: 'Meditation';
    source: AgendaItemSource.Meditation;
    isParticipantAgendaItem: false;
    audioStartedAt?: ISOString;
}

export interface YTBStandUpAgendaItem extends AgendaItem {
    standUpUpdate: StandUpUpdate;
}

export type TalkTime = StartStop;

export interface StartStop {
    startedAt: ISOString;
    endedAt?: ISOString;
}

export enum OutputPlatform {
    Slack = 'slack',
    Confluence = 'confluence',
    GoogleDrive = 'google-drive',
    Notion = 'notion',
}

export type OutputDestination = {
    userId: UUID;
    destinationId?: string;
    destinationName?: string;
};

export type OutputMetadata = {
    platform: OutputPlatform;
    destinations: OutputDestination[];
};

export enum AgendaItemSource {
    Roundtable = 'roundtable',
    NewTopic = 'new-topic',
    YTBStandUpUpdate = 'ytb-stand-up-update',
    // TODO: update to Team Topic when possible
    ParkingLot = 'parking-lot',
    Meditation = 'meditation',
}

export enum AgendaItemStatus {
    Incomplete = 'incomplete',
    Active = 'active',
    Complete = 'complete',
}

export enum MeetingStatus {
    Initialized = 'MEETING_INITIALIZED',
    AgendaStarted = 'AGENDA_STARTED',
    AgendaComplete = 'AGENDA_COMPLETE',
    MeetingComplete = 'MEETING_COMPLETE',
}

export type AsyncConfig = {
    isEnabled?: boolean;
};

export enum EmailDistributionConfig {
    InternalOnly = 'internal-only',
    All = 'all',
}

export type UUID = string;
