import axios, { AxiosError } from 'axios';

import { IClientUser, SpinachAPIPath } from '@spinach-shared/types';
import { getDefaultRequestHeaders } from '@spinach-shared/utils';

import { isChromeExtensionPlatform, isWebPlatform } from '../utils';
import { getHeaderOpts } from '../utils/api';
import { getClientConfigValue } from '../utils/getClientConfigValue';

type GetUserResponse = { user: IClientUser | null; code: number };

export async function getUser(): Promise<GetUserResponse> {
    try {
        const response = await axios.get<{ user: IClientUser | null }>(
            `${getClientConfigValue('REACT_APP_AUTH_URL')}${SpinachAPIPath.User}`,
            {
                headers: {
                    ...getDefaultRequestHeaders(getHeaderOpts()),
                    'Cache-Control': 'no-store',
                },
                withCredentials: isWebPlatform() || isChromeExtensionPlatform(),
                params: { ts: new Date().getTime() },
            }
        );

        return { user: response.data?.user || null, code: response.status };
    } catch (error) {
        const err = error as AxiosError;
        return { code: err.response?.status || 500, user: null };
    }
}
