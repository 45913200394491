import 'moment-timezone';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { ActiveAgendaProps, SpinachMeetingProps, YTBAgendaItemProps } from '@spinach-shared/models';
import { AgendaItemSource, MeetingStatus } from '@spinach-shared/types';

import { useGlobalDemoState, usePersonaDemo, useSeriesReality, useTeamTopics2Enablement } from '../../hooks';
import { useButtonCooldowns } from '../../hooks/useButtonCooldowns';
import { SpinachStandUpProps } from '../../types/StandUp';
import { IntercomCustom, SpinachContainer, SpinachPadding } from '../common';
import { FeatureCalloutTooltipContainer } from '../common/feature-discovery/FeatureTooltipCalloutContainer';
import { DemoSpinachStandupOverlay, MacOSButtons } from '../demo';
import LobbyView from './LobbyView';
import { NavView } from './NavView';
import { ProgressRow } from './ProgressRow';
import { SpinachStandUpViewContainer } from './Shared';
import StandUpUpdateView from './StandUpUpdateView';
import SummaryView from './SummaryView';
import { TopicView } from './TopicView';
import { SCROLL_VIEW_ID } from './constants';

const Padding = styled(SpinachPadding)`
    padding: 20px 30px;
`;

function useScrollUpOnViewChange(meeting: SpinachMeetingProps) {
    useEffect(() => {
        const scrollview = document.getElementById(SCROLL_VIEW_ID);
        if (scrollview) {
            scrollview.scrollTo({ top: 0 });
        }
    }, [meeting.currentAgendaItem?.id, meeting.status]);
}

export function SpinachStandUp({ series, socket, history, seriesHistories }: SpinachStandUpProps): JSX.Element {
    useScrollUpOnViewChange(series.currentMeeting);
    const { isAsyncMeeting } = series.currentMeeting;
    const cooldownProps = useButtonCooldowns(series.currentAgendaItem?.id);
    const isTeamTopics2Enabled = useTeamTopics2Enablement();
    const spinachContainerRef = useRef(null);
    const hasNavBar = !series.currentMeeting.isMeetingEnding;

    const { isDemoSeries } = useSeriesReality();
    const isBannerVisible = isDemoSeries;
    const { spinachWindowOverlay } = useGlobalDemoState();
    const isPersonaDemo = usePersonaDemo();

    // emit event that standup is loaded
    useEffect(() => {
        window.parent.postMessage({ type: 'standup-loaded' }, 'https://meet.google.com');
    }, []);

    return (
        <SpinachContainer ref={spinachContainerRef} isOnDemo={isDemoSeries}>
            <MacOSButtons />
            {spinachWindowOverlay && isPersonaDemo ? (
                <DemoSpinachStandupOverlay spinachWindowOverlay={spinachWindowOverlay} hasNavBar={hasNavBar} />
            ) : (
                <>
                    <Padding>
                        <ProgressRow series={series} socket={socket} />

                        <SpinachStandUpViewContainer navBar={hasNavBar} withDemoBanner={isBannerVisible}>
                            {(() => {
                                if (series.currentMeeting.isMeetingEnding) {
                                    return (
                                        <SummaryView
                                            series={series}
                                            currentMeeting={series.currentMeeting}
                                            seriesHistories={seriesHistories}
                                            socket={socket}
                                            cooldownProps={cooldownProps}
                                        />
                                    );
                                } else if (series.currentMeeting.status === MeetingStatus.Initialized) {
                                    return (
                                        <LobbyView
                                            series={series}
                                            isAsyncMeeting={isAsyncMeeting}
                                            seriesHistories={seriesHistories}
                                            socket={socket}
                                            cooldownProps={cooldownProps}
                                        />
                                    );
                                } else if (series.agenda instanceof ActiveAgendaProps) {
                                    if (series.agenda.currentItem instanceof YTBAgendaItemProps) {
                                        if (series.agenda.currentItem.isParticipantAgendaItem !== false) {
                                            return (
                                                <StandUpUpdateView
                                                    seriesSlug={series.slug}
                                                    agenda={series.agenda}
                                                    agendaOwner={series.participants.participants.find(
                                                        (p) =>
                                                            p.spinachUserId ===
                                                            (series.agenda as ActiveAgendaProps).currentItem.id
                                                    )}
                                                    currentItem={series.agenda.currentItem}
                                                    spinachParticipant={series.spinachParticipant}
                                                    socket={socket}
                                                    cooldownProps={cooldownProps}
                                                />
                                            );
                                        } else {
                                            return (
                                                <TopicView
                                                    seriesSlug={series.slug}
                                                    agenda={series.agenda}
                                                    currentItem={series.agenda.currentItem}
                                                    spinachParticipant={series.spinachParticipant}
                                                    socket={socket}
                                                    cooldownProps={cooldownProps}
                                                />
                                            );
                                        }
                                    } else {
                                        return null;
                                    }
                                }
                            })()}
                        </SpinachStandUpViewContainer>
                    </Padding>
                    {/* This has to be outside the padding, otherwise it won't span the entire SpinachContainer */}
                    {hasNavBar ? (
                        <NavView
                            history={history}
                            seriesHistories={seriesHistories}
                            series={series}
                            socket={socket}
                            containerRef={spinachContainerRef}
                        />
                    ) : undefined}
                </>
            )}

            <IntercomCustom />
            <FeatureCalloutTooltipContainer />
        </SpinachContainer>
    );
}
