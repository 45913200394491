import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';

import { ClientUser } from '@spinach-shared/models';
import { isLocalStage } from '@spinach-shared/utils';

import { AnonymousUserTracker, clarity, initFullstory } from '../utils';
import { RunningContext } from '../zoom';
import { useAnonymousUserId } from './useAnonymousUserId';

type FullStoryIdentifyOpts = {
    meetingID?: string;
    where?: RunningContext;
};

export function useUserIdentification(user: ClientUser, options: FullStoryIdentifyOpts = {}) {
    const [hasIdentified, setHasIdentified] = useState(false);
    useEffect(() => {
        if (!isLocalStage() && !hasIdentified) {
            try {
                if (
                    user.isEnabledForFullStorySessionRecording ||
                    (user.isHypercontextLegacyUser && !user.metadata.isOnboarded)
                ) {
                    initFullstory();
                }
                FullStory.identify(user.spinachUserId, {
                    email: user.email,
                    displayName: user.preferredName,
                    ...options,
                });
            } catch (error) {}

            try {
                clarity.identify(user.spinachUserId, {
                    email: user.email,
                    displayName: user.preferredName,
                    ...options,
                });
                clarity.setTag('email', user.email);
                clarity.setTag('displayName', user.preferredName);
                clarity.setTag('spinachUserId', user.spinachUserId);
                for (const [key, value] of Object.entries(options)) {
                    clarity.setTag(key, value);
                }
            } catch (e) {}

            try {
                AnonymousUserTracker.trackUser(user.spinachUserId);
            } catch (e) {}
            setHasIdentified(true);
        }
    }, [user]);
}

export function useAnonymousUserIdentification() {
    const [hasIdentified, setHasIdentified] = useState(false);

    const anonymousUserId = useAnonymousUserId();
    useEffect(() => {
        if (!isLocalStage() && !hasIdentified) {
            try {
                Sentry.setTag('anonymousUserId', anonymousUserId);
            } catch (error) {}

            try {
                clarity.setTag('anonymousUserId', anonymousUserId);
            } catch (error) {}

            setHasIdentified(true);
        }
    }, [anonymousUserId]);
}
