import { Tooltip, makeStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { CSSProperties, useState } from 'react';
import styled from 'styled-components';

import { ClientEventType } from '@spinach-shared/types';
import { getSharedLink } from '@spinach-shared/utils';

import { Column } from '..';
import { copyTextToClipboard, lightTheme } from '../..';
import { useExperienceTracking, useGlobalAuthedUser, useInviteViaSlackEnablement } from '../../hooks';
import { Notification } from './Notification';
import SecondaryButton from './SecondaryButton';

const useTooltipStyles = makeStyles(() => ({
    tooltip: {
        width: '100px',
    },
    tooltipPlacementTop: {
        marginBottom: 5,
    },
}));

const MeetingSecretContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    align-items: center;
`;

export function CopySpinachLinkButton({
    seriesSlug,
    containerStyle,
    notificationContainerStyle,
    copyTextOnElementId,
}: {
    seriesSlug: string;
    containerStyle?: CSSProperties;
    notificationContainerStyle?: CSSProperties;
    copyTextOnElementId?: string;
}): JSX.Element {
    const track = useExperienceTracking();
    const tooltipClasses = useTooltipStyles();
    const [user] = useGlobalAuthedUser();
    const isInviteViaSlackEnabled = useInviteViaSlackEnablement();

    const [isToastOpen, setIsToastOpen] = useState(false);

    return (
        <Column style={containerStyle}>
            <MeetingSecretContainer>
                <SecondaryButton
                    title={`Copy ${user.meetingWord} Link`}
                    onClick={() => {
                        const encodedMeetingId = encodeURIComponent(seriesSlug);
                        const url = getSharedLink(encodedMeetingId);

                        if (user.spinachUserId) {
                            track(ClientEventType.CopyStandupLinkClicked);
                        }

                        const textToCopy = isInviteViaSlackEnabled
                            ? `Excited to try this simple but powerful tool called Spinach that can help us collaborate more effectively during our check-ins. ${url}`
                            : url;
                        setIsToastOpen(true);
                        copyTextToClipboard(textToCopy, copyTextOnElementId);
                    }}
                />

                <Tooltip
                    classes={tooltipClasses}
                    title="This link can be used to add Teammates to the meeting."
                    arrow={true}
                    placement="top"
                >
                    <HelpOutlineIcon
                        style={{ marginLeft: '10px' }}
                        fontSize="small"
                        htmlColor={lightTheme.primary.green}
                    />
                </Tooltip>
            </MeetingSecretContainer>

            <Notification
                isOpen={isToastOpen}
                containerStyle={{ bottom: '55px', position: 'relative', height: '0px', ...notificationContainerStyle }}
                onClose={() => setIsToastOpen(false)}
                message="Link copied! Hand it over!"
                icon={
                    <CheckCircleIcon
                        style={{ color: lightTheme.neutrals.white }}
                        htmlColor={lightTheme.neutrals.white}
                    />
                }
            />
        </Column>
    );
}
