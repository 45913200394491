export enum ElementId {
    TeamTopicButton = 'team-topics-nav-button',
    ShareSeriesModalContent = 'share-series-modal-content',
    SubscribeSeriesOutputModalContent = 'subscribe-series-output-modal-content',
    SettingsLinkShare = 'settings-link-share',
    TeamNameInput = 'team-name-input',
    LobbyInviteCTA = 'lobby-invite-cta',
    InviteCTA = 'invite-cta',
    InviteEmailInput = 'invite-email-input',
    SeriesDashboardContainer = 'series-dashboard-container',
    NextPresenter = 'next-presenter',
    SummaryView = 'summary-view',

    SendOptions = 'send-options',
    SummariesDrawer = 'summaries-drawer',
    StandupAppSummaryOptions = 'standup-app-summary-options',

    CurrentCheckinContainer = 'current-checkin-container',
    TodaySection = 'today-section',
    IcebreakerSection = 'icebreaker-section',
    PreviousCheckinContainer = 'previous-checkin-container',
    TeamTopicsDrawerContainer = 'team-topics-drawer-container',
    SummaryContainer = 'summary-container',

    AddATopicBeforeStartingStandup = 'add-a-topic-before-starting',

    EndMeetingFromTeamTopics = 'end-meeting-from-team-topics',
    BackToLobby = 'back-to-lobby',
    DemoBanner = 'demo-banner',
    CopySummaryOnSummaryView = 'copy-summary-on-summary-view',

    StartStandup = 'start-standup',

    Scrollview = 'scrollview', // TODO: migrate from constant
    ScrollviewNav = `scrollview-NAV`,
    OmniboxListItem = 'omnibox-list-item',
    CustomPlusButton = 'custom-plus-button',
    EndMeditation = 'end-meditation',

    SlackConnectButton = 'slack-connect-button',

    AISettingsTicketingSection = 'ai-settings-ticketing-section',
    AISettingsSlackHuddleConfigureButton = 'ai-settings-slack-huddle-configure-button',
    SlackChannelSelection = 'slack-channel-id-selection',
    AddEditorEmailInput = 'add-editor-email-input',
    ManageAdditionalEditorsModalContent = 'manage-additional-editors-modal-content',
}

export enum ElementClass {
    SentimentPicker = 'sentiment-picker',
    EventId = 'eid',
}
