import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { DashboardSection } from '@spinach-shared/constants';

import { useGlobalAiDashboard } from './useGlobalAiDashboard';

export function useHandleSpinachToggleFromUrl() {
    const location = useLocation();
    const { setState } = useGlobalAiDashboard();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const action = params.get('action');
        const iCalUid = params.get('iCalUid');

        if (action === 'add-spinach' && iCalUid) {
            // Navigate to meetings section
            setState((prev) => ({
                ...prev,
                activeSection: DashboardSection.Meetings,
                // Store the iCalUid to be handled by the MeetingSwitchComponent
                pendingSpinachToggleICalUid: iCalUid,
            }));
        }
    }, [location.search, setState]);
}
