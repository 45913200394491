import { ApolloMappedOrganization } from './Apollo';
import { Stage } from './Environment';
import { UUID } from './Series';
import { UTMTracking } from './User';

export enum AccountTier {
    Limited = 'limited',
    Trial = 'trial',
    Pro = 'pro',
    Enterprise = 'enterprise',
}

export type HostAccountRef = {
    id: string;
};

export enum ApolloOrgDataBackfillVersion {
    November2024 = '2024-11',
}

export const LATEST_APOLLO_ORG_DATA_BACKFILL_VERSION = ApolloOrgDataBackfillVersion.November2024;

export type AccountInputJSON = {
    /** @NOTE - assigned upon creation */
    _id: UUID;
    latestOrgDataBackfillVersion?: ApolloOrgDataBackfillVersion;
    // TODO use EmailDomain type when merged in
    rootDomain: string;
    startedOn: Date;

    stage?: Stage;

    /** @NOTE if we migrate a root domain into another, these will get populated */
    rootDomainMigratedTo?: string;
    migratedOn?: Date;

    isPreExistingAccount?: boolean;
    wasBackfilledWithEventsAndMetrics?: true;

    totalUsers: number;

    /** @NOTE stripe payment information */
    mostRecentCustomerId?: string;
    hasPaidAccess?: boolean;
    initialPaidAccessOn?: number;

    tier?: AccountTier;

    /** @NOTE - set when this account invites spinach to a meeting for the first time */
    accountFirstInviterId?: UUID;
    accountFirstInvitedOn?: Date;
    totalAiSessionsBeforeFirstInvite?: number;

    /** @NOTE - handled upon first invite detected for account */
    initialRole: InitialMeetingRole;

    /** @NOTE - these are done upon every invite */
    totalInvites: number;
    totalRecurringInvites: number;
    totalNonRecurringInvites: number;
    totalManualInvites: number;
    totalInAppInvites: number;

    totalHostInvites: number;
    totalHostRecurringInvites: number;
    totalHostNonRecurringInvites: number;
    totalHostManualInvites: number;
    totalHostInAppInvites: number;

    totalGuestInvites: number;
    totalGuestRecurringInvites: number;
    totalGuestNonRecurringInvites: number;
    totalGuestManualInvites: number;
    totalGuestInAppInvites: number;

    /** @NOTE these totals only get updated in one place, on meeting completion */
    totalAiSessions: number;
    totalStandupAiSessions: number;
    totalGenericAiSessions: number;
    totalPlanningAiSessions: number;
    totalRetroAiSessions: number;
    totalUserResearchInterviewAiSessions: number;
    totalHostAiSessions: number;
    totalHostStandupAiSessions: number;
    totalHostGenericAiSessions: number;
    totalHostPlanningAiSessions: number;
    totalHostRetroAiSessions: number;
    totalHostUserResearchInterviewAiSessions: number;
    totalHostCustomerSuccessCheckInAiSessions: number;
    totalHostGeneralSalesMeetingAiSessions: number;

    totalGuestAiSessions: number;
    totalGuestStandupAiSessions: number;
    totalGuestGenericAiSessions: number;
    totalGuestPlanningAiSessions: number;
    totalGuestRetroAiSessions: number;
    totalGuestUserResearchInterviewAiSessions: number;
    totalGuestCustomerSuccessCheckInAiSessions: number;
    totalGuestGeneralSalesMeetingAiSessions: number;

    totalSummaryHostRecipients: number;
    totalSummaryRecipients: number;
    totalSummaryGuestRecipients: number;

    hasIntegratedWithSlack: boolean;
    hasIntegratedWithJira: boolean;
    hasIntegratedWithAsana: boolean;
    hasIntegratedWithGoogleCalendar: boolean;
    hasIntegratedWithGoogleDrive: boolean;
    hasIntegratedWithMicrosoft: boolean;
    hasIntegratedWithMicrosoftCalendar: boolean;
    hasIntegratedWithTrello: boolean;
    hasIntegratedWithClickUp: boolean;
    hasIntegratedWithNotion: boolean;
    hasIntegratedWithLinear: boolean;
    hasIntegratedWithConfluence: boolean;
    hasAccountLevelZoomIntegration: boolean;

    totalMeetingsOnGoogle: number;
    totalMeetingsOnZoom: number;
    totalMeetingsOnTeams: number;
    totalMeetingsOnWebex: number;
    totalHostMeetingsOnGoogle: number;
    totalHostMeetingsOnZoom: number;
    totalHostMeetingsOnTeams: number;
    totalHostMeetingsOnWebex: number;
    totalGuestMeetingsOnGoogle: number;
    totalGuestMeetingsOnZoom: number;
    totalGuestMeetingsOnTeams: number;
    totalGuestMeetingsOnWebex: number;

    /** @NOTE - used to indicate what version of was run backfill for pre-existing accounts */
    version?: number;

    /** @NOTE - indicates when the first host started the reverse trial for this account, if eligible and enabled */
    reverseTrialStartDate?: Date;
    isEligibleForIndieBizTrials?: boolean;
    /** Needed to indicate that the account was created newly during the reverse trial experimentation window */
    isEligibleForReverseTrial?: boolean;

    source?: string | null;
    otherSource?: string | null;

    celloAffiliateCode?: string;
    wasAffiliateReferred: boolean;

    recallZoomAuthCredentialId?: string;

    apolloData?: ApolloMappedOrganization | null;

    brandedImageId?: string | null;

    firstUserUtms?: UTMTracking;

    isEligibleForVirality?: boolean;
    /**
     * represents the ordered chain of host accounts that led to this account entering our system.
     * eg Spinach invites Uber who then invites Lyft who then invites Instacart
     * for Spinach, this would be empty, no one invited spinach
     * For Instacart, it would be [spinachsAccountId, UbersAccountId, LyftsAccountId]
     * then when Instacart hosts their first meeting, we fire a `AccountLedToGuestToHostConversion` event
     * associated with each account in this list, starting with Lyft's at Depth = 0, up to Spinach with Depth = 2
     * so they each get attributed for Instacart entering our system and becoming a host
     */
    hostAccountRefs?: HostAccountRef[];
    /**
     * while we might be able to infer this from totalHostAiSessions === 1 or totalHostInvite === 1,
     * we've learned that the invite flow still has some issues
     * and just because an invite happens doesnt mean the session happens, even though we like to use session events
     * such as bot joining as a fallback for triggers that we prefer invite to handle.
     * This field then will let us tap into both invite and then bot event as a fallback to help us ensure we only fire these events once
     */
    hasTriggeredGuestToHostConversion?: boolean;
    /** Tracks when the account itself was invited as a virality tracking mechanism */
    firstSessionCompletedAsGuestDate?: Date;
    firstSessionCompletedAsHostDate?: Date;

    // used for last 30 days, not true cycle
    usageForCycleInSeconds?: number;

    // used for actual cycle if subscription exists for account
    usageHoursForCurrentLimitedCycle?: number;
    currentHoursLastSetIsoDate?: string;
    lastLimitedCycleStartIsoDate?: string;

    isEligibleForAgentFromOnboarding?: boolean;
};

export type AccountJSON = AccountInputJSON & {
    createdAt: Date;
    updatedAt: Date;
};

export enum InitialMeetingRole {
    Guest = 'guest',
    Host = 'host',
    Unknown = 'unknown',
}

// TODO - for these subsets of fields, just create smaller types and combine them into AccountInputJSON

export type FirstTimeAccountMetadata = Pick<
    AccountInputJSON,
    'accountFirstInviterId' | 'accountFirstInvitedOn' | 'totalAiSessionsBeforeFirstInvite'
>;

export type AccountInvitedMetadata = Pick<
    AccountInputJSON,
    | 'totalInvites'
    | 'totalRecurringInvites'
    | 'totalNonRecurringInvites'
    | 'totalManualInvites'
    | 'totalInAppInvites'
    | 'totalHostInvites'
    | 'totalHostRecurringInvites'
    | 'totalHostNonRecurringInvites'
    | 'totalHostManualInvites'
    | 'totalHostInAppInvites'
    | 'totalGuestInvites'
    | 'totalGuestRecurringInvites'
    | 'totalGuestNonRecurringInvites'
    | 'totalGuestManualInvites'
    | 'totalGuestInAppInvites'
>;

export type AccountCompletedMeetingIncrements = Pick<
    AccountInputJSON,
    | 'totalSummaryHostRecipients'
    | 'totalSummaryRecipients'
    | 'totalSummaryGuestRecipients'
    | 'totalAiSessions'
    | 'totalStandupAiSessions'
    | 'totalGenericAiSessions'
    | 'totalPlanningAiSessions'
    | 'totalRetroAiSessions'
    | 'totalUserResearchInterviewAiSessions'
    | 'totalHostAiSessions'
    | 'totalHostStandupAiSessions'
    | 'totalHostGenericAiSessions'
    | 'totalHostPlanningAiSessions'
    | 'totalHostRetroAiSessions'
    | 'totalHostUserResearchInterviewAiSessions'
    | 'totalHostCustomerSuccessCheckInAiSessions'
    | 'totalHostGeneralSalesMeetingAiSessions'
    | 'totalGuestAiSessions'
    | 'totalGuestStandupAiSessions'
    | 'totalGuestGenericAiSessions'
    | 'totalGuestPlanningAiSessions'
    | 'totalGuestRetroAiSessions'
    | 'totalGuestUserResearchInterviewAiSessions'
    | 'totalGuestCustomerSuccessCheckInAiSessions'
    | 'totalGuestGeneralSalesMeetingAiSessions'
    | 'totalMeetingsOnGoogle'
    | 'totalMeetingsOnZoom'
    | 'totalMeetingsOnTeams'
    | 'totalHostMeetingsOnGoogle'
    | 'totalHostMeetingsOnZoom'
    | 'totalHostMeetingsOnTeams'
    | 'totalGuestMeetingsOnGoogle'
    | 'totalGuestMeetingsOnZoom'
    | 'totalGuestMeetingsOnTeams'
    | 'totalMeetingsOnWebex'
    | 'totalHostMeetingsOnWebex'
    | 'totalGuestMeetingsOnWebex'
>;

export type AccountCompletedMeetingFlags = Pick<
    AccountInputJSON,
    | 'hasIntegratedWithSlack'
    | 'hasIntegratedWithJira'
    | 'hasIntegratedWithAsana'
    | 'hasIntegratedWithGoogleCalendar'
    | 'hasIntegratedWithGoogleDrive'
    | 'hasIntegratedWithTrello'
    | 'hasIntegratedWithClickUp'
    | 'hasIntegratedWithNotion'
    | 'hasIntegratedWithLinear'
    | 'hasIntegratedWithConfluence'
    | 'hasIntegratedWithMicrosoft'
    | 'hasIntegratedWithMicrosoftCalendar'
    | 'hasAccountLevelZoomIntegration'
>;
