import { CircularProgress, Drawer, IconButton, Popover, makeStyles } from '@material-ui/core';
import { AccountCircle, Close, Menu } from '@material-ui/icons';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import { useState } from 'react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { DashboardSection } from '@spinach-shared/constants';
import { ClientEventType, WebUrlQuery } from '@spinach-shared/types';
import { getWebAppHost } from '@spinach-shared/utils';

import { postLogOut } from '../../../apis';
import { ReactComponent as SpinachLogo } from '../../../assets/logo-spinachio.svg';
import { GlobalModal } from '../../../atoms';
import {
    useEditNameEnablement,
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalRouting,
    useProFeatures,
    useWindowSize,
} from '../../../hooks';
import { useGlobalAiDashboard } from '../../../hooks/useGlobalAiDashboard';
import { useGlobalStoredSeriesList } from '../../../hooks/useGlobalStoredSeriesList';
import { BodyRegular, BodyRegularOnboard, lightTheme } from '../../../styles';
import { isZoomPlatform } from '../../../utils';
import { Anchor, CircleBullet, ClientPath, Column, Row } from '../../common';
import { useNavigateToUpgrade } from '../hooks/useNavigateToUpgrade';
import { Sidebar } from './Sidebar';
import { StandupAppSidebar } from './StandupAppSidebar';

const Email = styled(BodyRegularOnboard)`
    font-size: 12px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ProText = styled(BodyRegularOnboard)`
    color: ${(props) => props.theme.tertiary.greenDark};
    font-weight: 600;
    user-select: none;
`;

const MenuButton = styled.button`
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 90%;
    border-radius: 8px;
    align-items: center;
    cursor: pointer;
    display: flex;
    border: none;
    background-color: transparent;

    &:hover {
        background-color: ${(props) => props.theme.neutrals.gray};
    }
`;

const usePopoverStyles = makeStyles(() => ({
    paper: {
        borderRadius: '10px',
        backgroundColor: lightTheme.neutrals.grayLight,
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 5px 5px 0px 1px rgba(0, 0, 0, 0.15)',
    },
}));

function ProfileComponent() {
    const [user] = useGlobalAuthedUser();
    const email = user.email;
    const hasProFeatures = useProFeatures();
    const track = useExperienceTracking();
    const navigateToUpgrade = useNavigateToUpgrade();
    const daysLeftInTrial = user.reverseTrialDaysLeft;
    const dayOrDays = daysLeftInTrial > 1 ? 'days' : 'day';
    const [isUserOptionsOpen, setIsUserOptionsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const trialDaysText = user.isOnManuallyManagedTrial ? '' : `- ${daysLeftInTrial} ${dayOrDays} left`;

    const popoverStyles = usePopoverStyles();
    const onOptionsClick = async (e: React.MouseEvent | React.KeyboardEvent) => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Profile Menu',
        });
        e.stopPropagation();
        setIsUserOptionsOpen(!isUserOptionsOpen);
        setAnchorEl(document.getElementById(`account-settings`));
    };
    const [isLoadingLogout, setIsLoadingLogout] = useState(false);
    const isEditNameEnabled = useEditNameEnablement();
    const [_, setGlobalModal] = useGlobalModal();
    const { width } = useWindowSize();
    const isDesktopView = width > 800;

    return (
        <Row style={{ maxWidth: isDesktopView ? '250px' : '60px', justifyContent: 'end' }}>
            {isDesktopView ? (
                <Column vCentered style={{ alignItems: 'flex-end', marginRight: '10px' }}>
                    <Email>{email}</Email>
                    {user.isOnLiveReverseTrial ? (
                        <Column>
                            <ProText>Pro Trial {trialDaysText}</ProText>
                            <Anchor
                                style={{ color: lightTheme.primary.midnight }}
                                onClick={() => {
                                    navigateToUpgrade('Click Upgrade to Pro from Trial (Navbar)');
                                }}
                            >
                                Subscribe to Pro
                            </Anchor>
                        </Column>
                    ) : hasProFeatures ? (
                        <ProText>Pro Plan</ProText>
                    ) : (
                        <Anchor
                            onClick={() => {
                                navigateToUpgrade('Click Upgrade to Pro (Navbar)');
                            }}
                        >
                            Upgrade to Pro
                        </Anchor>
                    )}
                </Column>
            ) : null}

            <Column style={{ width: 'unset', justifyContent: 'center' }}>
                <CircleBullet
                    id="account-settings"
                    style={{
                        backgroundColor: hasProFeatures ? lightTheme.tertiary.greenDark : lightTheme.primary.orangeDark,
                        cursor: 'pointer',
                    }}
                    onClick={onOptionsClick}
                >
                    {email.charAt(0)}
                </CircleBullet>
                {isUserOptionsOpen ? (
                    <Popover
                        style={{
                            padding: '10px',
                            zIndex: 2600,
                        }}
                        classes={popoverStyles}
                        id={'popover'}
                        open={isUserOptionsOpen}
                        anchorEl={anchorEl}
                        onClose={() => {
                            setIsUserOptionsOpen(false);
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Column style={{ width: '150px', alignItems: 'center' }}>
                            {isEditNameEnabled && (
                                <MenuButton
                                    disabled={isLoadingLogout}
                                    onClick={async () => {
                                        track(ClientEventType.UserClickedNameChange);
                                        setGlobalModal(GlobalModal.UserAccount);
                                    }}
                                >
                                    <AccountCircle style={{ marginRight: '5px' }} />
                                    <b>Edit Profile</b>
                                </MenuButton>
                            )}
                            {!isZoomPlatform() && (
                                <MenuButton
                                    disabled={isLoadingLogout}
                                    onClick={async () => {
                                        track(ClientEventType.UserClickedLogOut);

                                        setIsLoadingLogout(true);
                                        const response = await postLogOut();
                                        if (response.success) {
                                            window.location.href = getWebAppHost();
                                        }
                                        setIsLoadingLogout(false);
                                    }}
                                >
                                    <LogoutIcon style={{ marginRight: '5px' }} />
                                    <b>Logout</b>
                                    {isLoadingLogout ? (
                                        <CircularProgress
                                            size={'20px'}
                                            style={{
                                                marginLeft: '5px',
                                                color: lightTheme.primary.orangeDark,
                                            }}
                                        />
                                    ) : null}
                                </MenuButton>
                            )}
                        </Column>
                    </Popover>
                ) : null}
            </Column>
        </Row>
    );
}

function AppButtons() {
    const { routeToAIDashboard, routeToDashboard } = useGlobalRouting();
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();
    return (
        <>
            <Column style={{ width: 'unset' }} vCentered>
                <BodyRegular
                    style={{
                        fontWeight: 700,
                        marginRight: '20px',
                        cursor: 'pointer',
                        fontSize: '16px',
                        color:
                            location.pathname === ClientPath.Root
                                ? lightTheme.primary.greenLight
                                : lightTheme.primary.midnight,
                        borderBottom: location.pathname === ClientPath.Root ? '3px solid' : 'unset',
                    }}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Open Standup App Dashboard from Nav Bar',
                        });

                        if (location.pathname === ClientPath.Root) {
                            return;
                        }

                        routeToDashboard(
                            undefined,
                            new URLSearchParams({
                                [WebUrlQuery.Section]: DashboardSection.Meetings,
                            })
                        );
                    }}
                >
                    {user.meetingWord}s
                </BodyRegular>
            </Column>
            <Column style={{ width: 'unset' }} vCentered>
                <BodyRegular
                    style={{
                        fontWeight: 700,
                        marginRight: '20px',
                        cursor: 'pointer',
                        fontSize: '16px',
                        color:
                            location.pathname === ClientPath.AIHome
                                ? lightTheme.primary.greenLight
                                : lightTheme.primary.midnight,
                        borderBottom: location.pathname === ClientPath.AIHome ? '3px solid' : 'unset',
                    }}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Open Spinach AI Dashboard from Nav Bar',
                        });

                        if (location.pathname === ClientPath.AIHome) {
                            return;
                        }

                        routeToAIDashboard(
                            undefined,
                            new URLSearchParams({
                                [WebUrlQuery.Section]: DashboardSection.Summaries,
                            })
                        );
                    }}
                >
                    Spinach AI
                </BodyRegular>
            </Column>
        </>
    );
}

export function DashboardNavBar(): JSX.Element {
    const track = useExperienceTracking();
    const location = useLocation();
    const [user] = useGlobalAuthedUser();

    const {
        storedSeriesListState: { storedSeriesList },
    } = useGlobalStoredSeriesList();

    const hasLegacySeries = storedSeriesList.filter((s) => !s.isDemoSeries).some((s) => !s.isScribeSeries);

    const userShouldSeeBothApps =
        (hasLegacySeries || user.isForcedLegacyOnboarding) &&
        !user.isHidingAiDashboard &&
        !user.isHidingStandupAppDashboard;

    const [isOpen, setIsOpen] = useState(false);

    const { width } = useWindowSize();
    const isDesktopView = width > 800;

    const { activeSection, setActiveSection } = useGlobalAiDashboard();

    return (
        <Row
            style={{
                height: '60px',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                justifyContent: isDesktopView ? 'flex-start' : 'space-between',
                zIndex: 1600,
            }}
        >
            {!isDesktopView ? (
                <Column style={{ width: 'unset', justifyContent: 'center', marginLeft: '10px' }}>
                    <IconButton
                        style={{ zIndex: 2400 }}
                        size={'small'}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Mobile Nav Bar Menu',
                                Action: isOpen ? 'closing' : 'opening',
                            });
                            setIsOpen(!isOpen);
                        }}
                    >
                        {isOpen ? (
                            <Close fontSize={'small'} htmlColor={lightTheme.primary.greenLight} />
                        ) : (
                            <Menu fontSize={'small'} htmlColor={lightTheme.primary.greenLight} />
                        )}
                    </IconButton>
                </Column>
            ) : null}
            <Column
                style={{
                    maxWidth: isDesktopView ? '245px' : '100%',
                    justifyContent: 'center',
                    alignItems: isDesktopView ? 'start' : 'center',
                }}
            >
                <SpinachLogo
                    style={{
                        height: isDesktopView ? '35px' : '25px',
                        cursor: 'pointer',
                        marginLeft: '15px',
                    }}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Nav Bar Logo' });
                        setActiveSection(DashboardSection.Meetings);
                    }}
                />
            </Column>
            {userShouldSeeBothApps && isDesktopView ? <AppButtons /> : null}
            {isDesktopView ? <span style={{ display: 'flex', flexGrow: 1 }} /> : null}
            <ProfileComponent />
            <Drawer
                anchor={'top'}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                BackdropProps={{ style: { top: '60px' } }}
                PaperProps={{ style: { top: '60px' } }}
                style={{ zIndex: `1000` }}
            >
                <Column onClick={() => setIsOpen(!isOpen)}>
                    {userShouldSeeBothApps ? (
                        <Row style={{ height: '60px' }}>
                            <span style={{ width: '20px ' }} />
                            <AppButtons />
                        </Row>
                    ) : null}
                    <Row style={{ backgroundColor: `${lightTheme.neutrals.grayLight}` }}>
                        {location.pathname === ClientPath.Root ? (
                            <StandupAppSidebar activeSection={activeSection} setActiveSection={setActiveSection} />
                        ) : (
                            <Sidebar
                                activeSection={activeSection}
                                setActiveSection={setActiveSection}
                                setIsAddingAdditionalMeetings={() => null}
                                shouldShowShowcaseGuide={false}
                            />
                        )}
                    </Row>
                </Column>
            </Drawer>
        </Row>
    );
}
