import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { fetchSpinachWorkflows } from '../../apis/workflows/fetchSpinachWorkflows';
import { atomSpinachWorkflows } from '../../atoms/workflows/atomSpinachWorkflows';
import { useGlobalUser } from '../useGlobalUser';

export const useGlobalSpinachWorkflows = () => {
    return useRecoilState(atomSpinachWorkflows);
};

export const useSpinachWorkflows = () => {
    const [user] = useGlobalUser();
    const [globalSpinachWorkflows, setGlobalSpinachWorkflows] = useGlobalSpinachWorkflows();
    useEffect(() => {
        const getSpinachWorkflows = async () => {
            const spinachWorkflows = await fetchSpinachWorkflows();
            if (spinachWorkflows) {
                setGlobalSpinachWorkflows(spinachWorkflows);
            }
        };
        if (user?.isEnabledForMorningBriefing || user?.isEnabledForSpinachWorkflows) {
            getSpinachWorkflows();
        }
    }, [user?.isEnabledForMorningBriefing, user?.isEnabledForSpinachWorkflows, setGlobalSpinachWorkflows]);

    return [globalSpinachWorkflows, setGlobalSpinachWorkflows];
};
