import axios from 'axios';

import { SpinachAPIPath } from '@spinach-shared/types';
import { getDefaultRequestHeaders, isLocalStage } from '@spinach-shared/utils';

import { getMockZoomQueryParams } from '..';
import { ClientLogger, isChromeExtensionPlatform, isWebPlatform } from '../utils';
import { getHeaderOpts } from '../utils/api';
import { getClientConfigValue } from '../utils/getClientConfigValue';

export async function deleteSpinachAPI<T>(
    path: SpinachAPIPath | string,
    params?: Record<string, any>,
    throwOnError = false
): Promise<T | undefined> {
    try {
        const stubbedParams = isLocalStage() ? { ...getMockZoomQueryParams(), ...params } : params;

        const response = await axios.delete<T>(`${getClientConfigValue('REACT_APP_AUTH_URL')}${path}`, {
            headers: {
                ...getDefaultRequestHeaders(getHeaderOpts()),
                'Cache-Control': 'no-store',
            },
            withCredentials: isWebPlatform() || isChromeExtensionPlatform(),
            params: stubbedParams,
        });

        return response.data ?? undefined;
    } catch (error: any) {
        ClientLogger.error('Error in deleteSpinachAPI', {
            message: error.message,
            path,
        });
        if (throwOnError) {
            throw error;
        }
        return undefined;
    }
}
