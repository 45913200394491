import {
    SpinachAPIPath,
    SpinachIntegration,
    TICKET_SOURCE_MAP,
    TicketSource,
    WebUrlQuery,
} from '@spinach-shared/types';

import { URLUtil } from './URLUtil';
import { getClientConfigValue } from './getClientConfigValue';

const ticketSourcePathMap = {
    [TICKET_SOURCE_MAP.Jira]: SpinachAPIPath.JiraAuth,
    [TICKET_SOURCE_MAP.Asana]: SpinachAPIPath.AsanaAuth,
    [TICKET_SOURCE_MAP.Linear]: SpinachAPIPath.LinearAuth,
    [TICKET_SOURCE_MAP.ClickUp]: SpinachAPIPath.ClickUpAuth,
    [TICKET_SOURCE_MAP.Trello]: SpinachAPIPath.IntegrationAuth.replace(':oauthProvider', SpinachIntegration.Trello),
    [TICKET_SOURCE_MAP.MondayDotCom]: SpinachAPIPath.IntegrationAuth.replace(
        ':oauthProvider',
        SpinachIntegration.MondayDotCom
    ),
};

export function openTicketSourceAuth(ticketSource: TicketSource, spinachUserId: string) {
    URLUtil.openURL(
        `${getClientConfigValue('REACT_APP_AUTH_URL')}${ticketSourcePathMap[ticketSource]}/?${
            WebUrlQuery.Suid
        }=${spinachUserId}&${WebUrlQuery.AI}=true`
    );
}
