import { Chip, Tooltip } from '@material-ui/core';
import { ForumOutlined } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { BaseMeetingProps, SeriesHistoriesProps } from '@spinach-shared/models';
import { ClientEventType, SpinachUpdateTypeYTB, UUID } from '@spinach-shared/types';
import { formatSummaryHTML, getAbbreviatedDateElements } from '@spinach-shared/utils';

import { ReactComponent as CopyContentIcon } from '../../assets/copy-btn.svg';
import { ElementId } from '../../constants';
import { useExperienceTracking, useFreeTierLimitations, useGlobalAuthedUser, useGlobalLiveSeries } from '../../hooks';
import { BodyRegular, lightTheme, responsiveness, withSelectionPrevention } from '../../styles';
import { ListItemValue, SetValue } from '../../types';
import { ClientLogger, copyTextToClipboard } from '../../utils';
import { Column, DropDown, FreeTierLimitationMessage, LimitationIntent, Row } from '../common';
import { SendOptions } from '../common/SendOptions';
import { Notification } from '../stand-up';
import { OpenedSummary } from './OpenedSummary';
import { SummaryFilterDropdown } from './SummaryFilterDropdown';
import { SummaryFilterView } from './SummaryFilterView';

function useResetToLatestSummary(
    openedSummary: number | null,
    seriesHistories: BaseMeetingProps[],
    setOpenedSummary: SetValue<number | null>
) {
    useEffect(() => {
        if (openedSummary === null && seriesHistories.length) {
            setOpenedSummary(0);
        }
    }, [seriesHistories.length]);
}

const ResponsiveRow = styled(Row)`
    justify-content: space-between;

    @media ${responsiveness.thinnerThanMD} {
        flex-direction: column;

        > * {
            margin-bottom: 1rem;
        }
    }
`;

const ResponsiveColumn = styled(Column)`
    margin-left: 1rem;

    @media ${responsiveness.thinnerThanMD} {
        margin-left: 0;
    }
`;

const SummariesContainer = styled(Column)`
    ${withSelectionPrevention()};
    height: 100%;
`;

export function Summaries({
    seriesHistories,
    openedSummary,
    setOpenedSummary,
}: {
    seriesHistories: BaseMeetingProps[];
    openedSummary: number | null;
    setOpenedSummary: SetValue<number | null>;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const [liveSeries] = useGlobalLiveSeries();
    const [copyButtonBackgroundColor, setCopyButtonBackgroundColor] = useState('unset');
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [participantFilter, setParticipantFilter] = useState<UUID[]>([]);
    const [categoryFilter, setCategoryFilter] = useState<SpinachUpdateTypeYTB[]>([]);
    const summaries = [liveSeries.currentMeeting, ...seriesHistories];
    const hasOpenedSummary = openedSummary !== null;
    const hasFilters = !!(participantFilter.length || categoryFilter.length);
    const isFreeTierLimited = useFreeTierLimitations();

    useResetToLatestSummary(openedSummary, summaries, setOpenedSummary);

    useEffect(() => {
        setOpenedSummary(summaries.length ? 0 : null);
    }, []);

    function getPostContent(meeting: BaseMeetingProps): JSX.Element {
        return meeting.isAsyncMeeting ? (
            <Chip
                icon={<ForumOutlined style={{ fontSize: 'medium' }} />}
                label="Async"
                color="secondary"
                variant="outlined"
            />
        ) : (
            <></>
        );
    }
    let dateWithMissingYear: string | undefined = undefined;
    let dropDownValues: ListItemValue<number>[] = seriesHistories
        .map((meeting, i) => {
            // should always evaluate to true
            if (meeting.endedAt || meeting.startedAt) {
                const datetime = (
                    meeting.isAsyncMeeting ? meeting.endedAt ?? meeting.startedAt : meeting.startedAt ?? meeting.endedAt
                )!;
                const { weekday, dayOfMonth, year = '' } = getAbbreviatedDateElements(datetime);

                if (!year && !dateWithMissingYear) {
                    dateWithMissingYear = datetime;
                }
                const isSummaryFreeTierLimited = isFreeTierLimited && i > 0;
                const listItem: ListItemValue<number> = {
                    code: i + 1,
                    label: `${weekday}, ${dayOfMonth} ${year}`,
                    postContent: getPostContent(meeting),
                    disabled: isSummaryFreeTierLimited,
                    tooltip: isSummaryFreeTierLimited ? (
                        <FreeTierLimitationMessage intent={LimitationIntent.History} style={{ fontSize: '14px' }} />
                    ) : (
                        ''
                    ),
                    isTooltipInteractive: isSummaryFreeTierLimited,
                };
                return listItem;
            }
        })
        .filter((m) => !!m) as ListItemValue<number>[];

    if (dateWithMissingYear) {
        ClientLogger.error('Meeting date parsing did not yield year', {
            seriesId: liveSeries.id,
            userId: user.spinachUserId,
            date: dateWithMissingYear,
        });
    }

    dropDownValues.unshift({
        code: 0,
        label: 'Live Summary ⚡️',
        postContent: getPostContent(liveSeries.currentMeeting),
    });

    if (participantFilter.length && dropDownValues[0].code !== -1) {
        dropDownValues.unshift({
            code: -1,
            label: 'All',
        });
    }

    if (!participantFilter.length) {
        dropDownValues = dropDownValues.filter(({ code }) => code !== -1);
    }

    const onCopyFilteredSummary = async () => {
        let richSummary = '';

        if (!openedSummary) {
            return;
        }

        if (openedSummary === -1) {
            summaries.forEach((meeting, index) => {
                const filteredMeeting = SeriesHistoriesProps.applyFiltersToMeeting(meeting, participantFilter);

                richSummary += formatSummaryHTML(filteredMeeting, liveSeries, {
                    withFooter: index === summaries.length - 1,
                });
            });
        } else {
            const selectedMeeting = summaries[openedSummary];
            const filteredMeeting = SeriesHistoriesProps.applyFiltersToMeeting(selectedMeeting, participantFilter);

            richSummary = formatSummaryHTML(filteredMeeting, liveSeries);
        }

        copyTextToClipboard(richSummary);
        setIsToastOpen(true);

        const participantsInSeries = SeriesHistoriesProps.findAllParticipantsInSeries(summaries);

        track(ClientEventType.CopyFilteredSummaryClick, {
            SelectedParticipants: participantFilter.map((id) => participantsInSeries[id].displayName).join(', '),
            SelectedDate: openedSummary === -1 ? 'All' : dropDownValues[openedSummary + 1].label,
        });
    };

    const onCopySummary = async (meeting: BaseMeetingProps) => {
        if (typeof openedSummary !== 'number') {
            return;
        }

        const richSummary = formatSummaryHTML(meeting, liveSeries);
        copyTextToClipboard(richSummary);
        setIsToastOpen(true);

        track(ClientEventType.CopySummaryClick, {
            SelectedDate: openedSummary === -1 ? 'All' : dropDownValues[openedSummary + 1].label,
        });
    };

    const getSelectedDropdownLabel = () => {
        if (!openedSummary || openedSummary === -1) {
            return dropDownValues[0].label;
        }

        if (hasFilters) {
            return dropDownValues[openedSummary + 1].label;
        }

        return dropDownValues[openedSummary].label;
    };

    return (
        <SummariesContainer preventSelection={isFreeTierLimited}>
            <ResponsiveRow>
                {dropDownValues[0] ? (
                    <Column style={{ minWidth: '225px' }}>
                        <DropDown
                            title={dropDownValues[0].label}
                            values={dropDownValues}
                            handleSelection={(code: string | number) => {
                                setOpenedSummary(code as number);
                                track(ClientEventType.SummaryFilterDateClick, {
                                    SelectedDate: dropDownValues[code as number].label,
                                });
                            }}
                            defaultValue={dropDownValues[0].label}
                            selected={getSelectedDropdownLabel()}
                        />
                    </Column>
                ) : (
                    <BodyRegular>
                        No summaries yet! Check back after you've finished your first {user.meetingWord.toLowerCase()}.
                    </BodyRegular>
                )}
                {!liveSeries.isDemo && (
                    <ResponsiveColumn>
                        <SummaryFilterDropdown
                            setParticipantFilter={setParticipantFilter}
                            participantFilter={participantFilter}
                            setCategoryFilter={setCategoryFilter}
                            categoryFilter={categoryFilter}
                            setOpenedSummary={setOpenedSummary}
                            selectedDate={getSelectedDropdownLabel()}
                            user={user}
                            histories={summaries}
                        />
                    </ResponsiveColumn>
                )}
                {liveSeries.isDemo && openedSummary !== null ? (
                    <Column style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Tooltip title="Copy Summary" placement="top">
                            <CopyContentIcon
                                aria-label="copy summary button"
                                onMouseEnter={() => setCopyButtonBackgroundColor(lightTheme.neutrals.grayLight)}
                                onMouseLeave={() => setCopyButtonBackgroundColor('unset')}
                                onMouseDown={() => setCopyButtonBackgroundColor(lightTheme.neutrals.grayDark)}
                                onMouseUp={() => setCopyButtonBackgroundColor(lightTheme.neutrals.grayLight)}
                                style={{
                                    cursor: 'pointer',
                                    marginLeft: '80px',
                                    backgroundColor: copyButtonBackgroundColor,
                                    borderRadius: '8px',
                                }}
                                onClick={() => onCopySummary(summaries[openedSummary])}
                            />
                        </Tooltip>
                    </Column>
                ) : null}
                {!liveSeries.isDemo && openedSummary !== null && !hasFilters ? (
                    <ResponsiveColumn>
                        <SendOptions
                            parentId={ElementId.SummariesDrawer}
                            onCopyClick={() => onCopySummary(summaries[openedSummary])}
                        />
                    </ResponsiveColumn>
                ) : null}
            </ResponsiveRow>
            {hasOpenedSummary && hasFilters && (
                <SummaryFilterView
                    participantFilter={participantFilter}
                    categoryFilter={categoryFilter}
                    openedSummary={openedSummary}
                    onCopyClick={onCopyFilteredSummary}
                    summaries={summaries}
                />
            )}

            {hasOpenedSummary && !hasFilters && <OpenedSummary currentMeeting={summaries[openedSummary]} />}
            <Notification
                isOpen={isToastOpen}
                containerStyle={{ bottom: '20px' }}
                onClose={() => setIsToastOpen(false)}
                message="Summary copied!"
                icon={
                    <CheckCircleIcon
                        style={{ color: lightTheme.neutrals.white }}
                        htmlColor={lightTheme.neutrals.white}
                    />
                }
            />
        </SummariesContainer>
    );
}
