import { ArrowDropDown } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { DashboardSection } from '@spinach-shared/constants';
import { ClientEventType } from '@spinach-shared/types';

import { patchUser, useExperienceTracking, useGlobalAiDashboard, useGlobalAuthedUser } from '../../../..';
import { PillButton } from '../../../stand-up';
import { ShowcaseGuideCtaTarget, type ShowcaseGuideProps } from './types';
import { useShowcaseGuidePacket } from './useShowcaseGuidePacket';

const GuideContainer = styled.div`
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 0 3px;
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
`;

const Title = styled.h2`
    margin: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
`;

const MinimizeButton = styled.button`
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    padding: 4px 8px;
    color: black;
    &:hover {
        color: #333;
    }
`;

const ContentContainer = styled.div`
    padding: 16px;
    background: #f1f7f6;
    border-radius: 2px;
`;

const SlideTitle = styled.h3`
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 115%;
    margin: 0 0 8px 0;
    color: ${({ theme }) => theme.primary.green};
`;

const SlideDescription = styled.p`
    font-size: 14px;
    color: #666;
    margin: 0 0 16px 0;
    line-height: 1.5;
`;

const CtaButton = styled(PillButton)`
    padding: 5px 12px;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.primary.green};
    color: ${({ theme }) => theme.primary.green};
`;

const NavigationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-top: 1px solid #eee;
`;

const SlideCounter = styled.span`
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 115%;
    color: ${({ theme }) => theme.primary.green};
`;

const MinimizedContainer = styled(HeaderContainer)`
    border: none;
    cursor: pointer;
`;

const NavigationArrow = styled(ArrowDropDown)`
    cursor: pointer;
    color: ${({ theme }) => theme.primary.green};
`;

function ShowcaseHeader({ title, onMinimize }: { title: string; onMinimize: () => void }) {
    return (
        <HeaderContainer onClick={onMinimize}>
            <Title>{title}</Title>
            <MinimizeButton onClick={onMinimize}>−</MinimizeButton>
        </HeaderContainer>
    );
}

function ShowcaseMinimized({ title, onMaximize }: { title: string; onMaximize: () => void }) {
    return (
        <MinimizedContainer onClick={onMaximize}>
            <Title>{title}</Title>
            <MinimizeButton>+</MinimizeButton>
        </MinimizedContainer>
    );
}

export function ShowcaseGuide() {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [user, setUser] = useGlobalAuthedUser();
    const packet = useShowcaseGuidePacket();
    const track = useExperienceTracking();
    const [hoveredSlides, setHoveredSlides] = useState<string[]>([]);
    const { setActiveSection } = useGlobalAiDashboard();

    const allSlidesViewed =
        !!packet &&
        !!user.metadata.showcaseGuideSlideIdsViewed?.length &&
        packet.slides.every((slide) => user.metadata.showcaseGuideSlideIdsViewed!.includes(slide.id));
    const [isMinimized, setIsMinimized] = useState(allSlidesViewed);
    useEffect(() => {
        if (!packet || currentSlideIndex < 0 || currentSlideIndex >= packet.slides.length) {
            return;
        }
        const currentSlide = packet.slides[currentSlideIndex];
        if (user.metadata.showcaseGuideSlideIdsViewed?.includes(currentSlide.id)) {
            return;
        }

        async function markAsViewed() {
            const { user: updatedUser } = await patchUser({
                metadata: {
                    showcaseGuideSlideIdsViewed: [
                        ...(user.metadata.showcaseGuideSlideIdsViewed || []),
                        currentSlide.id,
                    ],
                },
            });
            if (updatedUser) {
                setUser(updatedUser);
            }
        }

        markAsViewed();
    }, [currentSlideIndex, packet, user.metadata.showcaseGuideSlideIdsViewed, setUser]);

    if (!user.isEnabledForStarterGuide || !packet) {
        return null;
    }
    const currentSlide = packet.slides[currentSlideIndex];

    if (isMinimized) {
        return (
            <GuideContainer>
                <ShowcaseMinimized
                    title={packet.title}
                    onMaximize={() => {
                        track(ClientEventType.AIDashboardClick, {
                            Section: 'ShowcaseGuide',
                            ClickedOn: 'MaximizeShowcaseGuide',
                        });
                        setIsMinimized(false);
                    }}
                />
            </GuideContainer>
        );
    }

    return (
        <GuideContainer
            onMouseEnter={() => {
                // only fire once per slide
                if (hoveredSlides.includes(currentSlide.id)) {
                    return;
                }
                setHoveredSlides([...hoveredSlides, currentSlide.id]);
                track(ClientEventType.AIDashboardActivity, {
                    Section: 'ShowcaseGuide',
                    Activity: 'ShowcaseGuideHover',
                    SlideTitle: currentSlide.title,
                    SlideId: currentSlide.id,
                    NumberOfSlides: packet.slides.length,
                    CurrentSlideIndex: currentSlideIndex,
                });
            }}
        >
            <ShowcaseHeader
                title={packet.title}
                onMinimize={() => {
                    track(ClientEventType.AIDashboardClick, {
                        Section: 'ShowcaseGuide',
                        ClickedOn: 'MinimizeShowcaseGuide',
                    });
                    setIsMinimized(true);
                }}
            />
            <ContentContainer>
                <SlideTitle>{currentSlide.title}</SlideTitle>
                <SlideDescription>{currentSlide.description}</SlideDescription>
                <CtaButton
                    onClick={(e) => {
                        track(ClientEventType.AIDashboardClick, {
                            Section: 'ShowcaseGuide',
                            ClickedOn: 'ShowcaseGuideCtaButton',
                            SlideTitle: currentSlide.title,
                            SlideId: currentSlide.id,
                            NumberOfSlides: packet.slides.length,
                            CurrentSlideIndex: currentSlideIndex,
                        });
                        (e.target as HTMLElement).blur();
                        switch (currentSlide.ctaTarget) {
                            case ShowcaseGuideCtaTarget.Meetings:
                                setActiveSection(DashboardSection.Meetings);
                                break;
                            case ShowcaseGuideCtaTarget.Settings:
                                setActiveSection(DashboardSection.Integrations);
                                break;
                        }

                        if (currentSlideIndex === packet.slides.length - 1) {
                            setIsMinimized(true);
                        } else {
                            setCurrentSlideIndex(currentSlideIndex + 1);
                        }
                    }}
                >
                    {currentSlide.ctaLabel}
                </CtaButton>
            </ContentContainer>
            <NavigationContainer>
                <NavigationArrow
                    style={{
                        transform: 'rotate(90deg)',
                        visibility: currentSlideIndex > 0 ? 'visible' : 'hidden',
                    }}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            Section: 'ShowcaseGuide',
                            ClickedOn: 'ShowcaseGuidePreviousSlide',
                            SlideTitle: currentSlide.title,
                            SlideId: currentSlide.id,
                            NumberOfSlides: packet.slides.length,
                            CurrentSlideIndex: currentSlideIndex,
                        });
                        setCurrentSlideIndex(currentSlideIndex - 1);
                    }}
                />
                <SlideCounter>
                    {currentSlideIndex + 1} of {packet.slides.length}
                </SlideCounter>
                <NavigationArrow
                    style={{
                        transform: 'rotate(-90deg)',
                        visibility: currentSlideIndex < packet.slides.length - 1 ? 'visible' : 'hidden',
                    }}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            Section: 'ShowcaseGuide',
                            ClickedOn: 'ShowcaseGuideNextSlide',
                            SlideTitle: currentSlide.title,
                            SlideId: currentSlide.id,
                            NumberOfSlides: packet.slides.length,
                            CurrentSlideIndex: currentSlideIndex,
                        });
                        setCurrentSlideIndex(currentSlideIndex + 1);
                    }}
                />
            </NavigationContainer>
        </GuideContainer>
    );
}

export type { ShowcasePacket, ShowcaseSlide, ShowcaseGuideProps } from './types';
