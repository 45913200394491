import { CallMade } from '@material-ui/icons';
import styled from 'styled-components';

import { DashboardSection, INTERCOM_SUPPORT_URL_STANDUP_APP } from '@spinach-shared/constants';
import { ClientEventType } from '@spinach-shared/types';

import { useExperienceTracking, useGlobalAuthedUser, useGlobalRouting } from '../../../hooks';
import { lightTheme } from '../../../styles';
import { URLUtil } from '../../../utils';
import { Column, Spacing } from '../../common';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { DashboardComponent } from './types';

const SidebarColumn = styled(Column)`
    padding: 20px;
    height: 100%;
    width: calc(100% - 40px);
`;

export function StandupAppSidebar({ activeSection, setActiveSection }: DashboardComponent): JSX.Element {
    const track = useExperienceTracking();
    const { routeToDemoExperience } = useGlobalRouting();
    const [user] = useGlobalAuthedUser();
    return (
        <Column
            style={{
                height: '100%',
                width: '240px',
                backgroundColor: `${lightTheme.neutrals.grayLight}`,
                zIndex: 1,
                marginRight: '20px',
            }}
        >
            <SidebarColumn>
                <SecondaryButton
                    title={`Your ${user.meetingWord}s`}
                    buttonStyles={{ textDecoration: 'none' }}
                    labelStyles={{
                        fontSize: '18px',
                        fontWeight: 700,
                        display: 'flex',
                        width: '100%',
                        textAlign: 'left',
                    }}
                    color={
                        activeSection === DashboardSection.Meetings
                            ? lightTheme.primary.greenLight
                            : lightTheme.primary.midnight
                    }
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Standups Tab' });
                        setActiveSection(DashboardSection.Meetings);
                    }}
                />
                <Spacing factor={1 / 2} />
                <SecondaryButton
                    title="Summaries"
                    buttonStyles={{ textDecoration: 'none' }}
                    labelStyles={{
                        fontSize: '18px',
                        fontWeight: 700,
                        display: 'flex',
                        width: '100%',
                        textAlign: 'left',
                    }}
                    color={
                        activeSection === DashboardSection.Summaries
                            ? lightTheme.primary.greenLight
                            : lightTheme.primary.midnight
                    }
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Summaries Tab' });
                        setActiveSection(DashboardSection.Summaries);
                    }}
                />
                <Spacing factor={1 / 2} />
                <SecondaryButton
                    title="Integrations"
                    buttonStyles={{ textDecoration: 'none' }}
                    labelStyles={{
                        fontSize: '18px',
                        fontWeight: 700,
                        display: 'flex',
                        width: '100%',
                        textAlign: 'left',
                    }}
                    color={
                        activeSection === DashboardSection.Integrations
                            ? lightTheme.primary.greenLight
                            : lightTheme.primary.midnight
                    }
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Integrations Tab' });
                        setActiveSection(DashboardSection.Integrations);
                    }}
                />
                <Spacing factor={1 / 2} />
                <SecondaryButton
                    title="Practice Round"
                    buttonStyles={{ textDecoration: 'none' }}
                    labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                    color={lightTheme.primary.midnight}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Practice Round Tab' });
                        routeToDemoExperience();
                    }}
                    endIcon={
                        <CallMade
                            style={{ fontSize: '16px', marginLeft: '5px', marginBottom: '-2px' }}
                            htmlColor={lightTheme.primary.greenLight}
                        />
                    }
                />

                <Spacing factor={1 / 2} />
                <SecondaryButton
                    title="Help Center"
                    buttonStyles={{ textDecoration: 'none' }}
                    labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                    color={lightTheme.primary.midnight}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Help Center Link' });
                        URLUtil.openURL(INTERCOM_SUPPORT_URL_STANDUP_APP);
                    }}
                    endIcon={
                        <CallMade
                            style={{ fontSize: '16px', marginLeft: '5px', marginBottom: '-2px' }}
                            htmlColor={lightTheme.primary.greenLight}
                        />
                    }
                />
            </SidebarColumn>
        </Column>
    );
}
