import { Box, Tooltip, makeStyles } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { CSSProperties, ReactElement } from 'react';

import { useProFeatures } from '../../hooks';
import { lightTheme } from '../../styles';
import { useNavigateToUpgrade } from '../spinach-ai/hooks/useNavigateToUpgrade';

const useTooltipStyles = makeStyles(() => ({
    tooltip: {
        background: 'black',
        fontSize: '15px',
        padding: '10px',
    },
    arrow: {
        color: 'black',
    },
}));

const UpgradeToolTipWrapper = ({ analyticsPrefix, children }: { children: ReactElement; analyticsPrefix: string }) => {
    const navigateToUpgrade = useNavigateToUpgrade();
    const hasProFeatures = useProFeatures();
    const tooltipClasses = useTooltipStyles();
    return (
        <Tooltip
            classes={tooltipClasses}
            interactive
            arrow
            title={
                hasProFeatures ? (
                    ''
                ) : (
                    <Box display="flex" flexDirection="row" onClick={() => {}}>
                        <span>
                            {'Upgrade to Pro on your '}
                            <a
                                style={{ color: '#267764', cursor: 'pointer', textDecoration: 'underline' }}
                                onClick={() => navigateToUpgrade(`${analyticsPrefix} click upgrade to pro`)}
                            >
                                {'Account page'}
                            </a>
                            {' to unlock advanced features and integrations.'}
                        </span>
                    </Box>
                )
            }
            placement="top"
            style={{ flexDirection: 'column' }}
        >
            {children}
        </Tooltip>
    );
};

export const LockedIcon = () => (
    <Box
        style={{
            background: '#0D674A',
            width: '24px',
            height: '24px',
            marginRight: '15px',
            borderRadius: '3px',
        }}
    >
        <Box
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#0D674A',
                width: '24px',
                height: '24px',
                marginRight: '15px',
                borderRadius: '3px',
            }}
        >
            <Lock style={{ color: 'white', width: '18px', height: '18px' }} />
        </Box>
    </Box>
);

export const UpgradeLockIcon = ({ analyticsPrefix }: { analyticsPrefix: string }) => {
    return (
        <UpgradeToolTipWrapper analyticsPrefix={analyticsPrefix}>
            <Box>
                <LockedIcon />
            </Box>
        </UpgradeToolTipWrapper>
    );
};

export const Badge = ({ title, containerStyle }: { title: string; containerStyle?: CSSProperties }) => (
    <Box
        alignSelf={'center'}
        display="flex"
        py="2px"
        px="8px"
        ml="15px"
        style={{
            background: '#0D674A',
            color: 'white',
            fontWeight: 'bold',
            borderRadius: '2px',
            fontSize: '14px',
            ...containerStyle,
        }}
    >
        <Box justifySelf="center" style={{ display: 'flex', alignItems: 'center', height: 'auto' }}>
            {title}
        </Box>
    </Box>
);

export const BetaFeatureTag = () => {
    return (
        <Box
            style={{
                background: lightTheme.primary.green,
                color: lightTheme.neutrals.white,
                padding: '3px',
                fontWeight: 'bold',
                fontSize: '12px',
                marginLeft: '10px',
                borderRadius: '3px',
                width: '28px',
                height: '15px',
            }}
        >
            {'Beta'}
        </Box>
    );
};

export const ProBadge = ({ containerStyle: containerStyleOverrides }: { containerStyle?: CSSProperties }) => {
    const hasProFeatures = useProFeatures();
    return (
        <UpgradeToolTipWrapper analyticsPrefix="header tooltip">
            <Badge containerStyle={containerStyleOverrides} title={hasProFeatures ? 'Pro Feature' : 'Upgrade to Pro'} />
        </UpgradeToolTipWrapper>
    );
};
