import { ClientUser } from '@spinach-shared/models';
import { ClientEventType, SpinachAPIPath, WebUrlQuery } from '@spinach-shared/types';

import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useIntegrationDetection,
    useLandingAnalytic,
} from '../../../hooks';
import { HeaderThreeOnboard } from '../../../styles';
import { URLUtil } from '../../../utils';
import { getClientConfigValue } from '../../../utils/getClientConfigValue';
import { FYI, FYIState, Row, SlidingColumn, Spacing } from '../../common';
import { GoogleLoginComponent } from '../../common/GoogleLoginComponent';
import { MicrosoftLoginComponent } from '../../common/MicrosoftLoginComponent';
import { ScrollView } from '../../series';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { BackButtonRow, DoItManually, Stationary, StepProps } from './common';

export type ManualInviteProps = StepProps & {
    onBack: () => void;
    onSkip: () => void;
    onProgressToAddEventToMeeting: (user: ClientUser) => void;
};

export function ManualInvite({
    onBack,
    direction,
    onSkip,
    onProgressToAddEventToMeeting,
    loadingMessage,
}: ManualInviteProps): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const startDetection = useIntegrationDetection(onProgressToAddEventToMeeting);
    useLandingAnalytic(ClientEventType.UserViewedManualSpinachSetupStep);
    const track = useExperienceTracking();

    if (loadingMessage) {
        return <FYI state={FYIState.Loading} header={loadingMessage} />;
    }

    return (
        <Stationary>
            <BackButtonRow onBack={onBack} />
            <HeaderThreeOnboard>Unable to connect your calendar?</HeaderThreeOnboard>
            <ScrollView sidePadding={0}>
                <SlidingColumn centered direction={direction}>
                    <Spacing />

                    <DoItManually />
                </SlidingColumn>
            </ScrollView>
            <Spacing factor={1 / 2} />
            <Row centered>
                <GoogleLoginComponent
                    text="Connect Google Calendar"
                    onClick={() => {
                        track(ClientEventType.UserClickedIntegrateWithGoogleCalendar);
                        startDetection();
                        URLUtil.openURL(
                            `${getClientConfigValue('REACT_APP_AUTH_URL')}${SpinachAPIPath.GoogleCalendarAuth}/?${
                                WebUrlQuery.Suid
                            }=${user.spinachUserId}`
                        );
                    }}
                />
                {/* only show for users that did not auth with google */}
                {!user.isAuthedForGoogle ? (
                    <MicrosoftLoginComponent
                        text="Connect Microsoft Calendar"
                        style={{
                            width: '222px',
                            marginLeft: '10px',
                        }}
                        textStyle={{
                            fontSize: '14px',
                        }}
                        onClick={() => {
                            track(ClientEventType.UserClickedIntegrateWithMicrosoftCalendar);
                            startDetection();
                            URLUtil.openURL(
                                `${getClientConfigValue('REACT_APP_AUTH_URL')}${SpinachAPIPath.MicrosoftAuth}/?${
                                    WebUrlQuery.Suid
                                }=${user.spinachUserId}`
                            );
                        }}
                    />
                ) : null}
            </Row>
            <Spacing />
            <Row centered>
                <SecondaryButton title={'Continue without calendar'} onClick={onSkip} />
            </Row>
        </Stationary>
    );
}
