import { TextField } from '@material-ui/core';
import { Titles } from '@spinach-clients/constants';
import { useEffect } from 'react';
import styled from 'styled-components';

import { ClientEventType } from '@spinach-shared/types';

import { ElementId } from '../../../constants';
import { useLandingAnalytic } from '../../../hooks';
import { BodyBigOnboard, BodySmallOnboard, HeaderThreeOnboard, responsiveness } from '../../../styles';
import { withContentMasking } from '../../../utils';
import { Row, SlidingColumn, Spacing } from '../../common';
import { useSpinachInputStyles } from '../../input';
import { PrimaryButton } from '../../stand-up';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { ScrollView } from '../common';
import { SLIDING_DURATION_MS, SLogo, Stationary } from './common';
import { StandupNameProps } from './types';

const CreateStationary = styled(Stationary)`
    min-height: 40%;
    @media ${responsiveness.lg} {
        width: 500px;
    }
`;

export function StandupName({
    standupName,
    setStandupName,
    onSubmit,
    onBack,
    isLoading,
    direction,
    user,
}: StandupNameProps): JSX.Element {
    const classes = useSpinachInputStyles({ value: standupName });
    useLandingAnalytic(ClientEventType.UserLandedOnNamingFirstStandupView, user.toUserIdentityPayload());

    // this is needed because using the autofocus prop on TextField breaks transition sliding
    useEffect(() => {
        setTimeout(() => {
            const inputElement = document.getElementById(ElementId.TeamNameInput);
            if (inputElement) {
                inputElement.focus();
            }
        }, SLIDING_DURATION_MS);
    }, []);

    return (
        <CreateStationary id={ElementId.SeriesDashboardContainer}>
            <SLogo style={{ alignSelf: 'flex-start' }} />
            <ScrollView sidePadding={0}>
                <SlidingColumn centered direction={direction} style={{ width: 'unset' }}>
                    <HeaderThreeOnboard>Create your space</HeaderThreeOnboard>

                    <Spacing />
                    <BodyBigOnboard style={{ fontWeight: 500 }}>
                        What should we call your {user.meetingWord.toLowerCase()}?
                    </BodyBigOnboard>
                    <Spacing factor={1 / 3} />

                    <Row centered>
                        <TextField
                            id={ElementId.TeamNameInput}
                            style={{ width: '70%', paddingTop: '2%' }}
                            InputProps={{ classes: { root: classes.base } }}
                            {...withContentMasking(classes.root)}
                            placeholder="Dream Team"
                            value={standupName}
                            onChange={(e) => {
                                setStandupName(e.target.value);
                            }}
                            onKeyDown={async (e) => {
                                if (!!standupName && e.key === 'Enter') {
                                    await onSubmit();
                                }
                            }}
                        />
                    </Row>
                    <Spacing factor={1 / 5} />
                    <Row centered>
                        <BodySmallOnboard>(Don’t worry you can change this later)</BodySmallOnboard>
                    </Row>
                    <Spacing />

                    <PrimaryButton
                        title={Titles.Create}
                        onClick={onSubmit}
                        disabled={!standupName.trim()}
                        isLoading={isLoading}
                    />

                    <Spacing />

                    <SecondaryButton title={'Are you joining a team?'} onClick={onBack} />
                </SlidingColumn>
            </ScrollView>
        </CreateStationary>
    );
}
