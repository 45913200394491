import { ClientEventType, MeetingSettingsSubview, SpinachAPIPath, WebUrlQuery } from '@spinach-shared/types';

import { ReactComponent as LinearLogo } from '../../../assets/linear-logo.svg';
import { useExperienceTracking, useGlobalAuthedUser, useIntegrationDetection } from '../../../hooks';
import { useGlobalMeetingSettings } from '../../../hooks/useGlobalMeetingSettings';
import { URLUtil } from '../../../utils';
import { getClientConfigValue } from '../../../utils/getClientConfigValue';
import { AppIntegrationRow, IntegrationButtonText } from './AppIntegrationRow';
import { IntegrationRowProps, UseIntegrationRow } from './common';

function useLinearIntegrationRow(): UseIntegrationRow {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const { setSubview } = useGlobalMeetingSettings();

    const startDetection = useIntegrationDetection();

    const isConnectFlow = !user.isAuthedForLinear;

    async function onClick() {
        if (isConnectFlow) {
            track(ClientEventType.UserAuthorizeLinearClick);
            startDetection();
            URLUtil.openURL(
                `${getClientConfigValue('REACT_APP_AUTH_URL')}${SpinachAPIPath.LinearAuth}/?${WebUrlQuery.Suid}=${
                    user.spinachUserId
                }`,
                { redirectThroughBackendWhenOnZoom: true }
            );
        } else {
            setSubview(MeetingSettingsSubview.LinearSettings);
            track(ClientEventType.OpenLinearSettingsSubviewClick);
        }
    }

    return {
        subtext: 'Link Linear issues in your summaries.',
        onClick,
        isConnected: user.isAuthedForLinear,
        buttonText: user.isAuthedForLinear ? IntegrationButtonText.Configure : IntegrationButtonText.Connect,
        isButtonDisabled: false,
    };
}

export function LinearIntegrationRow({ isConnectViewOnly }: IntegrationRowProps): JSX.Element {
    const { onClick, subtext, isConnected, buttonText, isButtonDisabled } = useLinearIntegrationRow();

    return (
        <AppIntegrationRow
            onCTAClick={onClick}
            subtext={subtext}
            label={'Linear'}
            icon={<LinearLogo style={{ width: '24px', height: '24px' }} />}
            isConnected={isConnected}
            buttonText={buttonText}
            isButtonDisabled={isButtonDisabled}
            isConnectViewOnly={isConnectViewOnly}
        />
    );
}
