import axios from 'axios';

import { SpinachAPIPath } from '@spinach-shared/types';
import { getDefaultRequestHeaders } from '@spinach-shared/utils';

import { SpinachAPIOptions } from '../types';
import { isChromeExtensionPlatform, isWebPlatform } from '../utils';
import { getHeaderOpts } from '../utils/api';
import { getClientConfigValue } from '../utils/getClientConfigValue';

export async function patchSpinachAPI<T, U extends Record<string, any> = Record<string, any>>(
    path: SpinachAPIPath | string,
    request: any,
    opts: SpinachAPIOptions<U> = {}
): Promise<T> {
    const { params, headers = {} } = opts;
    const response = await axios.patch<T>(`${getClientConfigValue('REACT_APP_AUTH_URL')}${path}`, request, {
        headers: {
            ...headers,
            ...getDefaultRequestHeaders(getHeaderOpts()),
            'Content-Type': 'application/json',
            'Cache-Control': 'no-store',
        },
        params,
        withCredentials: isWebPlatform() || isChromeExtensionPlatform(),
    });

    return response.data;
}
