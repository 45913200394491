import { Box, CircularProgress, InputAdornment, TextField, TextareaAutosize } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import moment from 'moment-timezone';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { demoBotId } from '@spinach-shared/constants';
import { ClientAiHistory, ClientEventType } from '@spinach-shared/types';
import { getDemoSelection } from '@spinach-shared/utils';

import { getAiHistories } from '../../../../apis/getAiHistories';
import { ReactComponent as Star } from '../../../../assets/Star.svg';
import { useAskSpinachQuickAction, useExperienceTracking, useGlobalAuthedUser } from '../../../../hooks';
import { BodyRegular, BodyRegularOnboard, ButtonSize, HeaderThreeOnboard, lightTheme } from '../../../../styles';
import { Badge, BetaFeatureTag, CollapsableAutocomplete, Row, Spacing } from '../../../common';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import {
    ASK_SPINACH_FEATURE_REQUEST_ID,
    AskSpinachButtonRow,
    AskSpinachFrom,
    AskSpinachOptions,
    AskSpinachResponse,
    AskSpinachResponseSection,
    AskSpinachUserQuerySection,
    getLocalStorageKeyForFreeForm,
    useAskSpinachOptions,
} from './AskSpinach';
import { getHistorySelectionCode } from './TranscriptsSection';

const Container = styled(Box)`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    max-width: 860px;
`;

const ResponseContainer = styled.div`
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 16px;
`;

const ControlsContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white;
    padding: 24px;
    border-top: 1px solid #e0e0e0;

    > div {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const FreeFormTextArea = styled(TextareaAutosize)`
    min-height: 80px;
    font-size: 17px;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid ${lightTheme.neutrals.midnight};
    outline: none;
    resize: vertical;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 16px;
`;

const MeetingSelector = styled(Row)`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;

    > div {
        flex: 1;

        /* Make both autocomplete components the same height */
        .MuiOutlinedInput-root {
            height: 56px; /* Standard Material-UI height for outlined inputs */
        }

        /* Ensure the input fields within are properly centered */
        .MuiInputBase-input {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            height: 24px !important; /* Standard height for text input */
            line-height: 24px !important;
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;

        > div {
            width: 100%;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;

const filter = createFilterOptions();

const QuickActionsRow = styled(Row)`
    justify-content: flex-start;
    margin-bottom: 24px;
    padding: 0 0px;

    @media (max-width: 768px) {
        justify-content: flex-start;
        flex-wrap: wrap;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;

    /* Target the direct child div that wraps the button */
    > div {
        width: auto;
        flex: 0 0 auto;
    }

    /* Target the Row component inside AskSpinachButtonRow */
    .MuiBox-root {
        width: auto;
    }
`;

const LoadingOption = styled(Row)`
    padding: 8px 16px;
    color: ${lightTheme.neutrals.midnight};
`;

type MeetingOption = {
    botId: string;
    seriesId: string;
    code: string;
    label: string;
    createdAt: Date;
};

export function AskSpinachPageSection(): JSX.Element {
    const track = useExperienceTracking();
    const [selection, setSelection] = useState<MeetingOption | null>(null);
    const [user] = useGlobalAuthedUser();
    const [options, setOptions] = useState<MeetingOption[]>([]);
    const responseContainerRef = useRef<HTMLDivElement>(null);
    const [hasLoadedAllHistories, setHasLoadedAllHistories] = useState(false);

    // Data fetching logic copied from TranscriptsSection
    useEffect(() => {
        async function fetchHistories() {
            const mapHistoryToSelection = (h: ClientAiHistory): MeetingOption => ({
                botId: h.botId,
                seriesId: h.seriesId,
                code: getHistorySelectionCode(h.seriesId, h.botId),
                label: `${h.meetingTitle}`,
                createdAt: new Date(h.createdAt),
            });

            const singleHistoryArray = await getAiHistories({ limit: 1 });
            if (singleHistoryArray && singleHistoryArray.length > 0) {
                const mapped = singleHistoryArray.map(mapHistoryToSelection);
                setOptions(mapped);
                setSelection(mapped[0]);

                const histories = await getAiHistories();
                if (histories) {
                    const mapped: MeetingOption[] = histories.map(mapHistoryToSelection);
                    setOptions(mapped);
                    setSelection(mapped[0]);
                }
            } else {
                const mockOptions = [getDemoSelection({ icpId: user._id })];
                setOptions(mockOptions);
                setSelection(mockOptions[0]);
            }
            setHasLoadedAllHistories(true);
        }
        fetchHistories();
    }, []);

    const [askSpinachQuery, setAskSpinachQuery] = useState<string | undefined>(undefined);
    const [askSpinachFreeform, setAskSpinachFreeform] = useState<string | undefined>(undefined);

    const askSpinachOptions = useAskSpinachOptions({ isCurrentSeriesRecurring: true });

    const defaultAskSpinachOption = askSpinachOptions.find((option) => option.default);
    const selectedAskSpinachOption = askSpinachOptions.find((option) => option.id === askSpinachQuery);
    const isFreeForm = askSpinachOptions && selectedAskSpinachOption?.freeform === true;

    const onAskSpinachFeatureRequestSelection = (_: ChangeEvent<{}>, selection: AskSpinachOptions | null) => {
        if (selection && 'fetureRequest' in selection) {
            setAskSpinachQuery(ASK_SPINACH_FEATURE_REQUEST_ID);
            setAskSpinachFreeform(selection.inputValue);
        } else if (selection?.default && defaultAskSpinachOption) {
            setAskSpinachQuery(defaultAskSpinachOption.id);
            setAskSpinachFreeform(selection.inputValue);
            track(ClientEventType.AIDashboardClick, {
                ClickedOn: 'Ask Spinach - selected query from dropdown',
                query: defaultAskSpinachOption.id,
            });
        } else {
            setAskSpinachQuery(selection?.id);
            if (selection?.storeFreeformInLocalStorage) {
                setAskSpinachFreeform(localStorage.getItem(getLocalStorageKeyForFreeForm(selection.id)) ?? '');
            } else {
                setAskSpinachFreeform('');
            }
            track(ClientEventType.AIDashboardClick, {
                ClickedOn: 'Ask Spinach - selected query from dropdown',
                query: selection?.id,
            });
        }
    };

    const [timeline, setTimeline] = useState<
        {
            requestId: string;
            botId: string;
            queryId: string;
            freeform?: string;
            createdAt: Date;
            response?: AskSpinachResponse;
        }[]
    >([]);

    const setAskSpinachResponse = (response: AskSpinachResponse) => {
        setTimeline((timeline) =>
            timeline.map((item) => (item.requestId === response.requestId ? { ...item, response } : item))
        );

        setTimeout(() => {
            if (responseContainerRef.current) {
                responseContainerRef.current.scrollTo({
                    top: responseContainerRef.current.scrollHeight,
                    behavior: 'smooth',
                });
            }
        }, 100);
    };

    const onQuerySubmitted = ({
        requestId,
        botId,
        queryId,
        freeform,
    }: {
        requestId: string;
        botId: string;
        queryId: string;
        freeform?: string;
    }) => {
        setTimeline((timeline) => [
            ...timeline,
            { requestId, botId, queryId, freeform, createdAt: new Date(), response: undefined },
        ]);
    };

    const quickActions = useAskSpinachQuickAction().filter((action) =>
        askSpinachOptions.some((option) => option.id === action.queryId)
    );

    const disableQuickActions =
        (timeline.length > 0 && timeline[timeline.length - 1]?.response === undefined) || !selection;

    const onQuickActionClick = (action: { queryId: string; freeform?: string }) => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Ask Spinach - quick action',
            query: action.queryId,
        });
        setAskSpinachQuery(action.queryId);
        setAskSpinachFreeform(action.freeform ?? '');
        setTimeout(() => {
            document.getElementById(`ask-spinach-button`)?.click();
        }, 100);
    };

    if (options.length === 0) {
        return (
            <Container>
                <ResponseContainer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress style={{ color: lightTheme.primary.green, marginBottom: '16px' }} />
                        <BodyRegular>Loading meetings...</BodyRegular>
                    </div>
                </ResponseContainer>
            </Container>
        );
    }

    return (
        <Container>
            <Spacing factor={1 / 2} />
            <Row vCenter>
                <HeaderThreeOnboard style={{ textAlign: 'left' }}>Ask Spinach</HeaderThreeOnboard>
            </Row>
            <ResponseContainer ref={responseContainerRef}>
                <Row key="welcome-message" style={{ justifyContent: 'flex-start', marginBottom: '8px' }}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        position="relative"
                        style={{
                            background: '#EEEEEE',
                            borderRadius: '5px',
                            padding: '10px',
                        }}
                    >
                        Hey, Ask Spinach anything you&apos;d like below
                    </Box>
                </Row>
                {timeline.map((item) => (
                    <>
                        <Row key={`${item.requestId}-query`} style={{ justifyContent: 'flex-end' }}>
                            <AskSpinachUserQuerySection
                                queryName={
                                    askSpinachOptions.find((option) => option.id === item.queryId)?.name ?? item.queryId
                                }
                                freeform={item.freeform}
                                meetingName={selection?.label}
                            />
                        </Row>
                        <Row
                            key={`${item.requestId}-response`}
                            style={{ justifyContent: 'flex-start', marginBottom: '8px' }}
                        >
                            <AskSpinachResponseSection
                                askSpinachResponse={item.response}
                                askSpinachQuery={item.queryId}
                            />
                        </Row>
                    </>
                ))}
            </ResponseContainer>

            <ControlsContainer>
                <QuickActionsRow>
                    {quickActions.map((action, i) => (
                        <OutlinedButton
                            key={i}
                            onClick={() => onQuickActionClick(action)}
                            title={action.label}
                            style={{
                                margin: '4px',
                                flex: window.innerWidth <= 768 ? '1 1 auto' : '0 0 auto',
                            }}
                            disabled={disableQuickActions}
                            size={ButtonSize.Small}
                        />
                    ))}
                </QuickActionsRow>
                <MeetingSelector vCenter>
                    <CollapsableAutocomplete<AskSpinachOptions, false, false, false>
                        isCollapsed={false}
                        options={askSpinachOptions}
                        getOptionLabel={(option: AskSpinachOptions) => option.name}
                        value={askSpinachOptions.find((option) => option.id === askSpinachQuery) ?? null}
                        size="small"
                        autoComplete
                        autoHighlight
                        onChange={(event: ChangeEvent<{}>, value: AskSpinachOptions | null) => {
                            onAskSpinachFeatureRequestSelection(event, value);
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params as any);

                            if (params.inputValue !== '' && defaultAskSpinachOption) {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    name: `Ask spinach "${params.inputValue}"`,
                                    default: true,
                                });
                                filtered.push({
                                    inputValue: params.inputValue,
                                    name: `Request a prompt that "${params.inputValue}"`,
                                    fetureRequest: true,
                                });
                            }
                            return filtered as any;
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Ask Spinach about this meeting:"
                                placeholder="What can we do to help?"
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Star />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    />
                    <Autocomplete
                        id="summaries-and-transcripts-id-selection"
                        options={
                            hasLoadedAllHistories
                                ? options
                                : [
                                      ...options,
                                      {
                                          botId: 'loading',
                                          label: 'Loading more meetings...',
                                          createdAt: new Date(),
                                          code: 'loading',
                                          seriesId: 'loading',
                                      },
                                  ]
                        }
                        getOptionDisabled={(option) => option.botId === 'loading'}
                        groupBy={(option) => moment(option.createdAt).format('YYYY/MM/DD')}
                        getOptionLabel={(option: MeetingOption) => option.label || ''}
                        renderOption={(option) =>
                            option.botId === 'loading' ? (
                                <LoadingOption vCenter>
                                    <CircularProgress size={16} style={{ marginRight: 8 }} />
                                    {option.label}
                                </LoadingOption>
                            ) : (
                                <Row vCenter style={{ width: 'calc(100% - 30px)' }}>
                                    <BodyRegularOnboard style={{ marginLeft: '10px' }}>
                                        {option.label}
                                    </BodyRegularOnboard>
                                    {option.botId === demoBotId ? (
                                        <span style={{ marginLeft: '10px' }}>
                                            <Badge title="Sample" />
                                        </span>
                                    ) : null}
                                </Row>
                            )
                        }
                        renderGroup={(params) => (
                            <div>
                                <BodyRegular
                                    style={{
                                        fontWeight: 700,
                                        fontSize: '12px',
                                        marginLeft: '15px',
                                    }}
                                >
                                    {moment(params.group).format('dddd, MMMM D').toUpperCase()}
                                </BodyRegular>
                                {params.children}
                            </div>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="For"
                                variant="outlined"
                                placeholder="Choose a meeting"
                                inputProps={{
                                    ...params.inputProps,
                                    style: {
                                        fontWeight: 500,
                                    },
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    style: {
                                        fontWeight: 500,
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                    },
                                }}
                            />
                        )}
                        onChange={(event: any, newValue: MeetingOption | null) => {
                            setSelection(newValue);
                        }}
                        value={selection}
                        size="small"
                    />
                </MeetingSelector>

                {isFreeForm ? (
                    <FreeFormTextArea
                        placeholder={askSpinachOptions.find((option) => option.id === askSpinachQuery)?.hint}
                        maxLength={askSpinachOptions.find((option) => option.id === askSpinachQuery)?.freeformLimit}
                        value={askSpinachFreeform}
                        onChange={(e) => {
                            const value = e.target.value;
                            setAskSpinachFreeform(value);
                            if (askSpinachQuery && selectedAskSpinachOption?.storeFreeformInLocalStorage) {
                                localStorage.setItem(getLocalStorageKeyForFreeForm(askSpinachQuery), value);
                            }
                        }}
                    />
                ) : null}

                <ButtonContainer>
                    <AskSpinachButtonRow
                        from={AskSpinachFrom.PageSection}
                        shouldShowRealTimeAskSpinachComponents={false}
                        askSpinachOptions={askSpinachOptions}
                        askSpinachQuery={askSpinachQuery}
                        askSpinachResponse={timeline[timeline.length - 1]?.response}
                        askSpinachFreeform={askSpinachFreeform}
                        isFreeForm={!!isFreeForm}
                        botId={selection?.botId}
                        setAskSpinachResponse={setAskSpinachResponse}
                        isTalkWithSpinachSelected={false}
                        setIsTalkWithSpinachSelected={() => {}}
                        onQuerySubmitted={onQuerySubmitted}
                    />
                </ButtonContainer>
            </ControlsContainer>
        </Container>
    );
}
