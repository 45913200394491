import { SpinachAPIPath, WebUrlQuery } from '@spinach-shared/types';

import { URLUtil } from '../../utils';
import { getClientConfigValue } from '../../utils/getClientConfigValue';

export function jiraAuth(spinachUserId: string, isAi: boolean) {
    URLUtil.openURL(
        `${getClientConfigValue('REACT_APP_AUTH_URL')}${SpinachAPIPath.JiraAuth}/?${WebUrlQuery.Suid}=${spinachUserId}${
            isAi ? `&${WebUrlQuery.AI}=true` : ''
        }`
    );
}
